import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ThemeProvider,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useContext } from 'react';
import { DIALOG_ACTIONS_STYLE } from '../../ui/uiStyles';
import { AppUIContext, SCREENS } from '../../utils/appUIContext';

const DEFAULT_LIST_ITEM_PROPS = { disableGutters: true };

const INLINE_TEXT_ICON_STYLE = { fontSize: 16, marginBottom: -3 };
const INFO_SECTION_ACCORDION_STYLE = { flexGrow: 1 };
const INFO_SECTION_LIST_STYLE = { paddingTop: 0, marginTop: -20 };

function SectionHeader({
  children,
  title,
}: {
  children?: TypographyTypeMap['props']['children'];
  title: string;
}) {
  return (
    <Box>
      <Typography fontWeight="bold" marginBottom={0.5} variant="body1">
        {title}
      </Typography>
      {children && <Typography variant="body2">{children}</Typography>}
    </Box>
  );
}

function ListItemText({ children }: { children?: TypographyTypeMap['props']['children'] }) {
  return (
    <ListItem
      style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'row', paddingBottom: 0 }}
    >
      <ListItemIcon style={{ marginLeft: -15, marginRight: -30, marginTop: -1.25 }}>
        <ArrowRightOutlinedIcon fontSize="medium" />
      </ListItemIcon>
      <Typography variant="subtitle2">{children}</Typography>
    </ListItem>
  );
}

export type MoreInformationDialogSection =
  | 'COLOR_PALETTE'
  | 'SHADE_VARIATIONS'
  | 'PALETTE_EDITOR'
  | 'COLOR_AND_HARMONY_TOOLS'
  | 'EXPORT'
  | 'TIPS';

interface Props {
  defaultExpandedSection?: MoreInformationDialogSection;
  isOpen: boolean;
  onClose: () => void;
}

export default function MoreInformationDialog(props: Props) {
  const { defaultExpandedSection, isOpen, onClose } = props;
  const {
    responsiveBreakpoints: { uiSize },
    uiColorPalette,
    uiTheme,
  } = useContext(AppUIContext);

  const isMobileView = uiSize === 'smallMobile';

  const ColorToolIcon = MUIIcon[SCREENS.Color.icon];
  const HarmonyToolIcon = MUIIcon[SCREENS.Harmony.icon];

  const highlightedListItemProps = {
    style: {
      backgroundColor:
        uiColorPalette.semantic.info[uiColorPalette.variationValues.mainColor.lighter],
      borderRadius: 4,
    },
  };

  return (
    <ThemeProvider theme={uiTheme}>
      <Dialog maxWidth="xl" open={isOpen} onClose={onClose}>
        <DialogTitle>More information</DialogTitle>
        <DialogContent>
          <Typography marginBottom={1}>Click on a section to expand and see more.</Typography>
          <List subheader={<li />}>
            <ListItem
              {...(defaultExpandedSection === 'COLOR_PALETTE'
                ? highlightedListItemProps
                : DEFAULT_LIST_ITEM_PROPS)}
            >
              <Accordion
                defaultExpanded={defaultExpandedSection === 'COLOR_PALETTE'}
                style={INFO_SECTION_ACCORDION_STYLE}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionHeader title="Your color palette">
                    Your color palette is derived from a single main color and a color harmony rule.
                    A palette consists of a primary accent (action) color, an optional secondary
                    color, neutrals, and semantic colors. These colors work well with each other and
                    should be the only colors visible in your UI (excluding media or special cases).
                  </SectionHeader>
                </AccordionSummary>
                <AccordionDetails style={INFO_SECTION_LIST_STYLE}>
                  <List>
                    <ListItemText>
                      You just need to choose the <u>main (brand) color</u> and a{' '}
                      <u>harmony rule</u>. All of the other palette colors are generated for you by
                      AppSchemer.
                    </ListItemText>
                    <ListItemText>
                      The <u>accent color</u>, or action color, is the primary and most
                      commonly-used color of your UI. It should be used for a majority of your
                      app&apos;s call-to-actions, and is typically applied to most interactive
                      components such as buttons, links, dropdowns, etc. It is common to use the
                      main (brand) color for the accent color, but any of the harmony colors can be
                      used.
                    </ListItemText>
                    <ListItemText>
                      The <u>secondary color</u> can be used for less common call-to-actions or to
                      draw the user&apos;s attention towards very important parts of the UI. It can
                      serve as the &quot;10%&quot; color for less common but important actions, such
                      as a &quot;buy now&quot; button or to highlight critical information.
                    </ListItemText>
                    <ListItemText>
                      All of the <u>other harmony colors</u> can also be used sparingly in your UI.
                      Using multiple harmony colors is typically only recommended if one of the
                      colors is dominant and the rest are used more rarely.
                    </ListItemText>
                    <ListItemText>
                      <u>Neutrals</u> are shades of gray ranging from near-white to near-black, and
                      are typically used for text, backgrounds, dividers, or disabled components.
                      Depending on your chosen settings, neutrals can also have a subtle tint
                      towards one of your harmony colors. Note that it is usually not recommended to
                      use pure black or pure white in your UI.
                    </ListItemText>
                    <ListItemText>
                      <u>Semantic colors</u> are always based on the same hue: green for
                      success/positive, red for failure/negative, orange or yellow for
                      warnings/alerts, blue for info, and purple for special actions. These are
                      industry standard colors used to communicate specific semantic information to
                      the user, such as showing red text when there&apos;s an error. Your
                      pallet&apos;s semantic colors are <b>automatically adjusted</b> from the
                      default pure color (e.g. <code>#0000FF</code> for info blue) to have similar
                      saturation and brightness levels as your main color so they blend well with
                      the rest of your palette. If a semantic color is too similar to one of the
                      harmony colors (e.g. if your accent color is pure green), AppSchemer will
                      automatically offset the hue slightly to make the semantic color more distinct
                      from the harmony color. You can further adjust the hue or use a different
                      shade to create stronger contrasts between the harmony and semantic colors of
                      your UI.
                    </ListItemText>
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem
              {...(defaultExpandedSection === 'SHADE_VARIATIONS'
                ? highlightedListItemProps
                : DEFAULT_LIST_ITEM_PROPS)}
            >
              <Accordion
                defaultExpanded={defaultExpandedSection === 'SHADE_VARIATIONS'}
                style={INFO_SECTION_ACCORDION_STYLE}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionHeader title="Color shade variations">
                    Every palette color consists of 9 shades of varying brightness: <code>100</code>
                    , <code>200</code>, <code>300</code>, <code>400</code>,<code>500</code>,{' '}
                    <code>600</code>, <code>700</code>, <code>800</code>, and <code>900</code> (
                    <code>100</code> being the brightest and <code>900</code> the darkest).{' '}
                    <code>500</code> is the middle shade that matches the brightness of your
                    selected main color. In your designs,{' '}
                    <b>you can use any of the shades of a color</b> depending on the context and
                    desired contrast.
                  </SectionHeader>
                </AccordionSummary>
                <AccordionDetails style={INFO_SECTION_LIST_STYLE}>
                  <List>
                    <ListItemText>
                      For example, you might use the base shade of <code>accent[500]</code> for a
                      call-to-action button against a white background, but against a darker
                      background, a brighter shade like <code>accent[300]</code> might look better.
                    </ListItemText>
                    <ListItemText>
                      The brightness and saturation levels of each variation should always be
                      consistent across all of your palette&apos;s colors. For example,{' '}
                      <code>accent[400]</code> should have the same brightness and saturation as{' '}
                      <code>secondary[400]</code>. AppSchemer does this for you automatically when
                      generating the shades for all of your palette colors.
                    </ListItemText>
                    <ListItemText>
                      Most text and background neutrals will use the brightest or darkest shades of
                      their respective colors. For example, you&apos;ll probably use{' '}
                      <code>background[100]</code> for a near-white app background, but you might
                      use <code>background[200]</code> for a slightly darker popup or dialog to make
                      it stand out more.
                    </ListItemText>
                    <ListItemText>
                      Each color in your palette comes with a set of 3 or more <u>key variations</u>{' '}
                      (such as &quot;main&quot;, &quot;darker&quot;, and &quot;lighter&quot;) that
                      are assigned to one of the color&apos;s shades (<code>100</code> to{' '}
                      <code>900</code>
                      ). These variations can help inform which version of a color to use in your UI
                      and help to keep your UI consistent by utilizing the same shades across your
                      app. Key variation values can be adjusted by moving the{' '}
                      <b>brightness and contrast sliders</b> in the theme options menu.
                    </ListItemText>
                    <ListItemText>
                      The <u>key variation</u> values are: <u>main</u> (<code>500</code> by
                      default), <u>lighter</u> (default <code>300</code>), and <u>darker</u>{' '}
                      (default <code>700</code>). Neutrals additionally have 4 more: <u>light 1</u>{' '}
                      (default <code>100</code>, near-white), <u>light 2</u> (default{' '}
                      <code>200</code>), <u>dark 1</u> (default <code>900</code>, near-black), and{' '}
                      <u>dark 2</u> (default <code>800</code>). The variation of the original base
                      color is always <code>500</code>, and the rest of the shades are generated by
                      darkening or brightening the base color.
                    </ListItemText>
                    <ListItemText>
                      Adjusting a <u>brightness slider</u> in the theme options menu will shift all
                      of the key variations for those colors up or down. For example, changing the
                      main color brightness from <code>500</code> to <code>700</code> means, for all
                      harmony colors, the <u>main</u> variation will be set to <code>700</code>, the{' '}
                      <u>lighter</u> variation to <code>500</code>, and the <u>darker</u> variation
                      to <code>900</code>. It can be useful to use different brightness levels for
                      dark mode vs. light mode UIs.
                    </ListItemText>
                    <ListItemText>
                      Adjusting a <u>contrast slider</u> in the theme options menu will widen the
                      distance between steps in the key variation values. For example, if we keep
                      the main color brightness at <code>500</code>, the key variation values for{' '}
                      <u>lighter</u>/<u>main</u>/<u>darker</u> will be as follows for each contrast
                      level: &quot;medium&quot; = <code>300</code>/<code>500</code>/<code>700</code>
                      , &quot;low&quot; = <code>400</code>/<code>500</code>/<code>600</code>, and
                      &quot;high&quot; contrast = <code>200</code>/<code>500</code>/<code>800</code>
                      .
                    </ListItemText>
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem
              {...(defaultExpandedSection === 'PALETTE_EDITOR'
                ? highlightedListItemProps
                : DEFAULT_LIST_ITEM_PROPS)}
            >
              <Accordion
                defaultExpanded={defaultExpandedSection === 'PALETTE_EDITOR'}
                style={INFO_SECTION_ACCORDION_STYLE}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionHeader title={SCREENS.Palette.title}>
                    The <i>{SCREENS.Palette.title}</i> lets you configure your palette&apos;s main
                    color and color harmony options from the <i>palette color settings</i> menu,
                    make adjustments to how the palette is applied in a UI theme from the{' '}
                    <i>theme options</i> menu, and preview it all in real time.
                  </SectionHeader>
                </AccordionSummary>
                <AccordionDetails style={INFO_SECTION_LIST_STYLE}>
                  <List>
                    <ListItemText>
                      The <i>palette color settings</i> menu lets you quickly adjust your main color
                      and change your palette&apos;s color harmony. You can then choose which colors
                      from the harmony to include in your final palette. For more advanced and
                      guided flows, you check out the <i>{SCREENS.Color.title}</i>{' '}
                      <ColorToolIcon color="secondary" style={INLINE_TEXT_ICON_STYLE} /> and the{' '}
                      <i>{SCREENS.Harmony.title}</i>{' '}
                      <HarmonyToolIcon color="secondary" style={INLINE_TEXT_ICON_STYLE} /> instead.
                      Note that in your own UI designs, you can use any of the harmony colors as you
                      wish.
                    </ListItemText>
                    <ListItemText>
                      The <i>theme options</i> menu primarily controls your palette&apos;s{' '}
                      <u>key variation</u> settings (see <i>color shade variations</i> above), and a
                      few other options such as enabling dark mode. These options control how the
                      real-time visual previews on this page will appear and will affect the
                      variation values when you download your palette as a file.
                    </ListItemText>
                    <ListItemText>
                      The <i>example applications of your theme</i> at the bottom of the{' '}
                      <i>{SCREENS.Palette.title}</i> page are visual example app UIs to show what
                      your palette might look like in practice. You can change the appearance of
                      these demo apps in real time by adjusting the <i>palette color settings</i>{' '}
                      and <i>theme options</i>. Turn on the <i>real-time theme preview</i> option to
                      visualize your palette in real time in the full AppSchemer UI.
                    </ListItemText>
                    <ListItemText>
                      <b>Experiment for yourself!</b> Turn on <i>real-time theme preview</i> and
                      play around with the different menu options. If you need inspiration, check
                      out the <i>randomize palette</i>{' '}
                      <CasinoOutlinedIcon color="primary" style={INLINE_TEXT_ICON_STYLE} /> feature{' '}
                      {isMobileView ? (
                        <span>
                          from the more menu{' '}
                          <MoreVertIcon color="primary" style={INLINE_TEXT_ICON_STYLE} /> above
                        </span>
                      ) : (
                        'above'
                      )}
                      .
                    </ListItemText>
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem
              {...(defaultExpandedSection === 'COLOR_AND_HARMONY_TOOLS'
                ? highlightedListItemProps
                : DEFAULT_LIST_ITEM_PROPS)}
            >
              <Accordion
                defaultExpanded={defaultExpandedSection === 'COLOR_AND_HARMONY_TOOLS'}
                style={INFO_SECTION_ACCORDION_STYLE}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionHeader title="Color and harmony selection tools">
                    The <i>{SCREENS.Color.title}</i>{' '}
                    <ColorToolIcon color="secondary" style={INLINE_TEXT_ICON_STYLE} /> and{' '}
                    <i>{SCREENS.Harmony.title}</i>{' '}
                    <HarmonyToolIcon color="secondary" style={INLINE_TEXT_ICON_STYLE} /> can help
                    you choose the best brand color and color harmony option for your UI. These
                    tools provide advanced options, visualizations, and reference images to help you
                    design the best UI possible.
                  </SectionHeader>
                </AccordionSummary>
                <AccordionDetails style={INFO_SECTION_LIST_STYLE}>
                  <List>
                    <ListItemText>
                      The <i>{SCREENS.Color.title}</i>{' '}
                      <ColorToolIcon color="secondary" style={INLINE_TEXT_ICON_STYLE} /> gives you
                      multiple ways to fine-tune your main brand color. It will also show you the
                      emotional connotation of every color and example brands that use the same
                      color.
                    </ListItemText>
                    <ListItemText>
                      The <i>{SCREENS.Harmony.title}</i>{' '}
                      <HarmonyToolIcon color="secondary" style={INLINE_TEXT_ICON_STYLE} /> can help
                      you choose the best color harmony for your UI. For each color harmony, you can
                      visualize the colors on a color wheel and see examples in brands and artwork.
                    </ListItemText>
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem
              {...(defaultExpandedSection === 'EXPORT'
                ? highlightedListItemProps
                : DEFAULT_LIST_ITEM_PROPS)}
            >
              <Accordion
                defaultExpanded={defaultExpandedSection === 'EXPORT'}
                style={INFO_SECTION_ACCORDION_STYLE}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionHeader title="Export and share your palette">
                    When you&apos;re happy with your color palette, you can share the URL or
                    download it as a file. Get started from the <i>save and share palette</i>{' '}
                    <CloudDownloadOutlinedIcon color="primary" style={INLINE_TEXT_ICON_STYLE} />{' '}
                    button{' '}
                    {isMobileView ? (
                      <span>
                        from the more menu{' '}
                        <MoreVertIcon color="primary" style={INLINE_TEXT_ICON_STYLE} /> above
                      </span>
                    ) : (
                      'above'
                    )}
                    .
                  </SectionHeader>
                </AccordionSummary>
                <AccordionDetails style={INFO_SECTION_LIST_STYLE}>
                  <List>
                    <ListItemText>
                      <u>Copy URL:</u> Copies the URL to your clipboard so you can save it or share
                      it in any way you want. The URL encodes your main color and all of the
                      settings used to derive your final palette design. You can use a saved URL to
                      view, edit, and download your palette later.
                    </ListItemText>
                    <ListItemText>
                      <u>
                        Save as <code>.JSON</code>:
                      </u>{' '}
                      Download your palette as a <code>.JSON</code> file. This JSON file contains
                      the entire data structure that AppSchemer uses under the hood and contains
                      every color and variation of the palette. The <code>variationValues</code>{' '}
                      section contains information on which variations (shades) should typically be
                      used in your UI based on the options you selected. The{' '}
                      <code>referencePalette</code> section also contains the same set of all
                      palette colors and their variations, but without any information about how
                      these colors should be applied.
                    </ListItemText>
                    <ListItemText>
                      <u>
                        Save as <code>.CSV</code>:
                      </u>{' '}
                      Download your palette as a <code>.CSV</code> spreadsheet. Each row represent a
                      base color (e.g. accent, secondary, text, etc.). The first 9 columns represent
                      the color code of that shade, starting from the left with the brightest (
                      <code>100</code>) to the darkest (<code>900</code>). The remaining columns
                      show which shade to typically apply in your UI based on the palette options
                      you selected.
                    </ListItemText>
                    <ListItemText>
                      <u>
                        Save as <code>.CSS</code>:
                      </u>{' '}
                      Download your palette as a <code>.CSS</code> file. This file contains CSS
                      classes for each color variation your UI might use, based on the options you
                      selected. Each class will consist of the appropriate <code>color</code> and/or{' '}
                      <code>background-color</code> property for that palette color. This file does
                      not include all colors and variations of your full palette.
                    </ListItemText>
                    <ListItemText>
                      <u>Save as MUI theme:</u> Download your palette as an MUI theme options object
                      (<code>.JSON</code> file). You can pass this object into MUI&apos;s{' '}
                      <code>createTheme()</code> function to generate a <code>Theme</code> object
                      and apply it to your UI with MUI&apos;s <code>ThemeProvider</code> API.{' '}
                      <Link
                        href="https://mui.com/material-ui/customization/theming/"
                        target="_blank"
                      >
                        Learn more about MUI themes
                      </Link>
                    </ListItemText>
                    <ListItemText>
                      <u>Sharing to social media:</u> Click on any of the social media (or email)
                      icons to share a link to your palette. The shared URL encodes your main color
                      and all of the settings used to derive your final palette design.
                    </ListItemText>
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem
              {...(defaultExpandedSection === 'TIPS'
                ? highlightedListItemProps
                : DEFAULT_LIST_ITEM_PROPS)}
            >
              <Accordion
                defaultExpanded={defaultExpandedSection === 'TIPS'}
                style={INFO_SECTION_ACCORDION_STYLE}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionHeader title="Additional tips">
                    Expand to view additional tips on getting the most out of your color palette.
                  </SectionHeader>
                </AccordionSummary>
                <AccordionDetails style={INFO_SECTION_LIST_STYLE}>
                  <List>
                    <ListItemText>
                      <b>Use color sparingly.</b> Neutrals should dominate your UI.
                    </ListItemText>
                    <ListItemText>
                      <b>Don&apos;t use too many colors at once</b> in your UI, as this can appear
                      chaotic and incoherent. Using one or two dominant harmony colors usually works
                      best.
                    </ListItemText>
                    <ListItemText>
                      <b>Avoid pure white or pure black.</b> Instead, you can use your pallet&apos;s
                      neutral variations for near-white (e.g. <code>background[100]</code>) or
                      near-black (e.g. <code>background[900]</code>).
                    </ListItemText>
                    <ListItemText>
                      <b>You can be way more flexible with colors</b> in your own designs. You can
                      mix and match colors from different harmonies and make additional adjustments
                      to each color in the palette. Just make sure to keep the colors roughly within
                      the color harmony so they don&apos;t clash.
                    </ListItemText>
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions style={DIALOG_ACTIONS_STYLE}>
          <Button autoFocus onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
