import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { isColorDark } from '../../utils/colors/colorUtils';
import { AppPreviewContext } from '../appPreviewContext';

export default function PhoneLogin() {
  const { colorPalette, getScaledValue, isDarkMode, isMobileView } = useContext(AppPreviewContext);

  const {
    mainColor: mainColorVariations,
    neutrals: neutralsVariations,
    semantic: semanticVariations,
  } = colorPalette.variationValues;

  const colors = useMemo(() => {
    const background =
      colorPalette.background[isDarkMode ? neutralsVariations.dark2 : neutralsVariations.light1];
    return {
      background,
      border:
        colorPalette.divider[isDarkMode ? neutralsVariations.lighter : neutralsVariations.darker],
      link: colorPalette.semantic.info[
        isColorDark(background) ? semanticVariations.lighter : semanticVariations.main
      ],
      logo1: colorPalette.referencePalette.main[mainColorVariations.main],
      logo2: colorPalette.referencePalette.main[mainColorVariations.lighter],
    };
  }, [colorPalette, isDarkMode, mainColorVariations, neutralsVariations, semanticVariations]);

  return (
    <Box
      bgcolor={colors.background}
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" padding={getScaledValue(3)} position="relative">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          position="absolute"
          right={0}
          top={0}
        >
          <Box
            bgcolor={colors.logo1}
            width={getScaledValue(15)}
            height={getScaledValue(15)}
            borderRadius="50%"
          />
          <Box
            bgcolor={colors.logo2}
            width={getScaledValue(25)}
            height={getScaledValue(25)}
            borderRadius="50%"
          />
          <Box
            bgcolor={colors.logo1}
            width={getScaledValue(40)}
            height={getScaledValue(40)}
            borderRadius="50%"
          />
          <Box
            bgcolor={colors.logo2}
            width={getScaledValue(60)}
            height={getScaledValue(60)}
            borderRadius="50%"
          />
          <Box
            bgcolor={colors.logo1}
            width={getScaledValue(85)}
            height={getScaledValue(85)}
            borderRadius="50%"
          />
        </Box>
        <Typography
          fontWeight="bold"
          marginBottom={getScaledValue(3)}
          marginTop={getScaledValue(3)}
          marginRight={getScaledValue(4)}
          variant="h4"
          zIndex={1}
        >
          Welcome back!
        </Typography>
        <Typography marginBottom={getScaledValue(1)} variant="subtitle1" zIndex={1}>
          Sign in with your email
        </Typography>
        <Box marginBottom={getScaledValue(1)}>
          <TextField aria-label="Example email input with your theme" fullWidth label="Email" />
        </Box>
        <Box marginBottom={getScaledValue(1.5)}>
          <TextField
            aria-label="Example password input with your theme"
            fullWidth
            label="Password"
            type="password"
          />
        </Box>
        <Link
          aria-label="Example forgot password link with your theme"
          alignSelf="self-start"
          marginBottom={getScaledValue(1.5)}
          style={{ cursor: 'pointer' }}
        >
          <Typography color={colors.link}>Forgot password?</Typography>
        </Link>
        <Button
          aria-label="Example sign in button with your theme"
          color="secondary"
          size={isMobileView ? 'small' : 'medium'}
          variant="contained"
        >
          Sign in
        </Button>
        <Link
          aria-label="Example create new account link with your theme"
          marginTop={getScaledValue(3.5)}
          style={{ cursor: 'pointer' }}
        >
          <Typography color={colors.link} fontWeight="bold">
            Create a new account
          </Typography>
        </Link>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        paddingBottom={getScaledValue(6)}
        paddingX={getScaledValue(3)}
      >
        <Typography>Or, sign in with:</Typography>
        <Box
          alignItems="center"
          bgcolor="#FFFFFF"
          border={getScaledValue(1)}
          borderRadius={getScaledValue(1.5)}
          display="flex"
          height={getScaledValue(35)}
          marginTop={getScaledValue(1)}
          position="relative"
          width="100%"
          style={{ cursor: 'pointer' }}
        >
          <Box
            alignItems="center"
            color="#000000"
            display="flex"
            marginLeft={getScaledValue(1)}
            position="absolute"
          >
            <GoogleIcon color="inherit" fontSize="large" />
          </Box>
          <Typography
            color="#000000"
            fontWeight="bold"
            marginLeft={getScaledValue(2)}
            variant={isMobileView ? 'body1' : 'h6'}
            width="100%"
          >
            Continue with Google
          </Typography>
        </Box>
        <Box
          alignItems="center"
          bgcolor="#1778F2"
          border={getScaledValue(1)}
          borderRadius={getScaledValue(1.5)}
          display="flex"
          height={getScaledValue(35)}
          marginTop={getScaledValue(1)}
          position="relative"
          width="100%"
          style={{ cursor: 'pointer' }}
        >
          <Box
            alignItems="center"
            color="#FFFFFF"
            display="flex"
            marginLeft={getScaledValue(1)}
            position="absolute"
          >
            <FacebookIcon color="inherit" fontSize="large" />
          </Box>
          <Typography
            color="#FFFFFF"
            fontWeight="bold"
            marginLeft={getScaledValue(2)}
            variant={isMobileView ? 'body1' : 'h6'}
            width="100%"
          >
            Continue with Facebook
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
