import { Box, Button, Checkbox, FormControlLabel, ThemeProvider, Typography } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import PhoneLogin from './phonePreviewApps/PhoneLogin';
import PhoneCrypto from './phonePreviewApps/PhoneCrypto';
import PhoneGoals from './phonePreviewApps/PhoneGoals';
import { TYPOGRAPHY_GLOBAL_FONT_SIZES, getMUIThemeFromColorPalette } from '../utils/muiTheme';
import { UserPaletteContext } from '../utils/userPaletteContext';
import { ColorPalette } from '../utils/colors/colorPalette';
import { ColorSchemeState } from '../utils/colors/colorSchemeState';
import SocialMediaWebsite from './websitePreviewApps/SocialMediaWebsite';
import CityBoostWebsite from './websitePreviewApps/CityBoostWebsite';
import { CONTAINER_USER_THEME } from '../ui/uiStyles';
import MUIDemo from './websitePreviewApps/MUIDemo';
import { AppPreviewContext } from './appPreviewContext';
import { AppUIContext } from '../utils/appUIContext';

const MOBILE_SCREEN_PREVIEW_SCALE_VALUE = 0.5;

function AppPreviewContainer({
  children,
  colorPalette,
  colorSchemeState,
  getScaledValue,
  type,
}: {
  children: React.ReactNode;
  colorPalette: ColorPalette;
  colorSchemeState: ColorSchemeState;
  getScaledValue: (value: number) => number;
  type: 'PHONE' | 'WEB';
}) {
  const muiTheme = useMemo(() => {
    const typographyGlobalFontSize =
      type === 'PHONE'
        ? TYPOGRAPHY_GLOBAL_FONT_SIZES.APP_PREVIEW_PHONE
        : TYPOGRAPHY_GLOBAL_FONT_SIZES.APP_PREVIEW_WEB;
    return getMUIThemeFromColorPalette(colorPalette, colorSchemeState.options, {
      components: {
        MuiTextField: {
          defaultProps: {
            size: 'small',
          },
        },
      },
      typographyGlobalFontSize: getScaledValue(typographyGlobalFontSize),
    });
  }, [colorPalette, colorSchemeState.options, getScaledValue, type]);

  const containerWidth = getScaledValue(type === 'PHONE' ? 300 : 900);
  return (
    <Box
      border={2}
      borderRadius={getScaledValue(8)}
      display="flex"
      flexDirection="column"
      height={getScaledValue(600)}
      margin={2}
      minWidth={containerWidth}
      overflow="hidden"
      width={containerWidth}
    >
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </Box>
  );
}

interface Props {
  scale: 'DESKTOP' | 'MOBILE';
}

export default function AppPreviews(props: Props) {
  const { scale } = props;

  const { colorPalette, colorSchemeState } = useContext(UserPaletteContext);
  const { settings, onSettingsChanged } = useContext(AppUIContext);

  const [showExamplePreviews, setShowExamplePreviews] = useState(true);

  const getScaledValue = useCallback(
    (value: number) => {
      if (scale === 'DESKTOP' || !settings.scaleDownPreviewsOnMobile) {
        return value;
      }
      return Math.round(MOBILE_SCREEN_PREVIEW_SCALE_VALUE * value * 10) / 10; // round to nearest one decimal
    },
    [scale, settings.scaleDownPreviewsOnMobile],
  );

  const handleFullThemePreviewCheckboxChanged = useCallback(
    (isChecked: boolean) => {
      onSettingsChanged({ theme: isChecked ? 'USER' : 'DEFAULT' });
    },
    [onSettingsChanged],
  );

  const { darkMode: isDarkMode } = colorSchemeState.options;

  return (
    <AppPreviewContext.Provider
      value={{
        colorPalette,
        isDarkMode,
        isMobileView: scale === 'MOBILE',
        getScaledValue,
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        gap={5}
        justifyContent="center"
        marginBottom={1}
      >
        <Button onClick={() => setShowExamplePreviews((prev) => !prev)}>
          {showExamplePreviews ? 'Hide examples' : 'Show examples'}
        </Button>
        <FormControlLabel
          aria-label="Apply and preview your theme in the whole UI"
          control={
            <Checkbox
              checked={settings.theme === 'USER'}
              onChange={(_event, checked) => handleFullThemePreviewCheckboxChanged(checked)}
            />
          }
          label="Real-time theme preview"
        />
      </Box>
      {showExamplePreviews && (
        <Box {...CONTAINER_USER_THEME}>
          <Typography marginBottom={2} variant="h6">
            Example applications of your theme:
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="flex-start" overflow="scroll">
            <AppPreviewContainer
              colorPalette={colorPalette}
              colorSchemeState={colorSchemeState}
              getScaledValue={getScaledValue}
              type="WEB"
            >
              <MUIDemo />
            </AppPreviewContainer>
            <AppPreviewContainer
              colorPalette={colorPalette}
              colorSchemeState={colorSchemeState}
              getScaledValue={getScaledValue}
              type="PHONE"
            >
              <PhoneLogin />
            </AppPreviewContainer>
            <AppPreviewContainer
              colorPalette={colorPalette}
              colorSchemeState={colorSchemeState}
              getScaledValue={getScaledValue}
              type="PHONE"
            >
              <PhoneCrypto />
            </AppPreviewContainer>
            <AppPreviewContainer
              colorPalette={colorPalette}
              colorSchemeState={colorSchemeState}
              getScaledValue={getScaledValue}
              type="PHONE"
            >
              <PhoneGoals />
            </AppPreviewContainer>
            <AppPreviewContainer
              colorPalette={colorPalette}
              colorSchemeState={colorSchemeState}
              getScaledValue={getScaledValue}
              type="WEB"
            >
              <SocialMediaWebsite />
            </AppPreviewContainer>
            <AppPreviewContainer
              colorPalette={colorPalette}
              colorSchemeState={colorSchemeState}
              getScaledValue={getScaledValue}
              type="WEB"
            >
              <CityBoostWebsite />
            </AppPreviewContainer>
          </Box>
        </Box>
      )}
    </AppPreviewContext.Provider>
  );
}
