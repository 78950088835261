import { ComponentProps, useContext, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import ScreenContainer from '../components/ScreenContainer';
import ContentSection from '../components/home/ContentSection';
import ColorBubbleList from '../components/home/ColorBubbleList';
import FeatureList from '../components/home/FeatureList';
import ContentFooter from '../components/home/ContentFooter';
import { AppUIContext, SCREENS } from '../utils/appUIContext';
import RouteLink from '../components/shared/RouteLink';

export default function WelcomeScreen() {
  const { onGoToScreen } = useContext(AppUIContext);

  const featureList: ComponentProps<typeof FeatureList>['features'] = useMemo(() => {
    return [
      { icon: SCREENS.Color.icon, isSpecial: true, label: SCREENS.Color.title },
      { icon: 'BuildOutlined', label: 'Precision color tuning' },
      { icon: 'ShuffleOutlined', label: 'Randomize color' },
      { icon: 'WaterDropOutlined', label: 'Color sampler' },
      { icon: 'RestoreOutlined', label: 'Undo/redo history' },
      { icon: 'TonalityOutlined', label: 'Base hue detection' },
      { icon: 'MoodOutlined', isHighlighted: true, label: 'Predicted color emotions' },
      { icon: 'LocalMallOutlined', label: 'Brand color examples' },
      { icon: 'WebOutlined', label: 'Brand UI examples' },
      { icon: SCREENS.Harmony.icon, isSpecial: true, label: SCREENS.Harmony.title },
      { icon: 'DataUsageOutlined', isHighlighted: true, label: 'Interactive color wheel' }, // also: DonutSmall, DonutSmallOutlined
      { icon: 'TryOutlined', label: 'Color harmony visualizations' },
      { icon: 'DescriptionOutlined', label: 'Color harmony descriptions' },
      { icon: 'BrandingWatermarkOutlined', label: 'Brand color harmony examples' },
      { icon: 'ImageOutlined', label: 'Example photos and artwork' },
      { icon: 'PaletteOutlined', isHighlighted: true, label: 'Automatic color palette creation' },
      { icon: 'AppsOutlined', label: 'Full palette visualizer' },
      { icon: 'TuneOutlined', isHighlighted: true, label: 'Palette customization' },
      { icon: 'OpacityOutlined', label: 'Apply harmony colors' },
      {
        icon: 'ReportProblemOutlined',
        isHighlighted: true,
        label: 'Automatic semantic color tuning',
      },
      { icon: 'ContentCopyOutlined', label: 'Copy color hex values' },
      { icon: 'AppShortcutOutlined', isHighlighted: true, label: 'Example apps and websites' },
      {
        icon: 'ScreenshotMonitorOutlined',
        isHighlighted: true,
        label: 'Real-time UI palette preview',
      },
      { icon: 'CasinoOutlined', label: 'Randomize palette' },
      { icon: 'LightModeOutlined', label: 'Palette brightness control' },
      { icon: 'ContrastOutlined', label: 'Palette contrast control' },
      { icon: 'SettingsOutlined', label: 'Theme settings controls' },
      { icon: 'CloudDownloadOutlined', isHighlighted: true, label: 'Download palette' },
      { icon: 'IosShareOutlined', label: 'Share to social media' },
      { icon: 'LinkOutlined', label: 'Shareable URL' },
      { icon: 'DarkMode', isHighlighted: true, label: 'Dark mode' },
      { icon: 'ArticleOutlined', label: 'Full documentation' },
      { icon: 'MobileFriendly', label: 'Mobile support' },
      // TODO: accessibility support (when available)
      // TODO: contrast checker (when available)
      // TODO: logo designer (when available)
      // TODO: typography tool (when available)
      // TODO: save history and bookmark palettes in cloud (when available)
    ];
  }, []);

  return (
    <ScreenContainer
      contentFooter={<ContentFooter />}
      enableContentPadding
      fullyTransparentHeader
      showFeedbackButton
      title="Design the perfect UI with just a few clicks."
    >
      <Typography marginBottom={2} variant="h6">
        AppSchemer is a color palette creation tool for UI design.
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={10}
        justifyContent="center"
        marginBottom={15}
        marginTop={5}
      >
        <ContentSection color="secondary" icon="AUTOMATE" title="Color theory, automated.">
          Start with just one color and build an entire color palette in seconds! AppSchemer uses
          color theory and UX principles to guide you through the whole process start to finish.
          Your final palette will have the best colors for your brand and preferences, and the
          resulting colors always work well with each other.
        </ContentSection>
        <ContentSection color="main" icon="CUSTOMIZE" title="Customize in minutes.">
          Easily mix and match different UI colors, change the brightness and contrast of your
          palette, and even see your theme in dark mode. With just a few settings, you can rapidly
          prototype and visualize thousands of color palette variations.
        </ContentSection>
        <ContentSection color="tertiary" icon="PREVIEW" title="Preview in real time.">
          See what your palette looks like in real time, right as you edit! Example mobile and web
          UIs show you realistic examples of what your theme would look like in a real-world
          application. Or, enable the full <i>real-time theme preview</i> feature to see your theme
          visualized right within AppSchemer itself!
        </ContentSection>
      </Box>
      <Button
        aria-label="Start the flow to design a new color palette"
        size="large"
        startIcon={<PaletteOutlinedIcon />}
        variant="contained"
        onClick={() => onGoToScreen('Color', /* flowMode = */ true)}
      >
        Design a color palette
      </Button>
      <Box marginTop={15}>
        <Typography marginBottom={5} variant="h4">
          How does AppSchemer work?
        </Typography>
        <ColorBubbleList
          listContent={[
            {
              content: (
                <span>
                  First, you&apos;ll pick a single main color that reflects the emotion of your app
                  or brand, if you don&apos;t already have one. The <RouteLink screen="Color" /> can
                  easily guide you through the process using existing brand examples, precision
                  color editing, and by giving you additional insights into each color to help you
                  pick the best brand color for your use case.
                </span>
              ),
              icon: SCREENS.Color.icon,
            },
            {
              content: (
                <span>
                  Additional colors can make your UI more dynamic and interesting. Using color
                  theory, AppSchemer will generate additional colors based off of your main color
                  and a chosen color harmony rule. The resulting palette colors will blend well and
                  complement each other. The <RouteLink screen="Harmony" /> can visually guide you
                  through the process of choosing the best color harmony for your app using an
                  interactive color wheel, brand examples, and example images and artwork to
                  demonstrate the color theory in action.
                </span>
              ),
              icon: SCREENS.Harmony.icon,
            },
            {
              content: (
                <span>
                  Once you choose your main color and a color harmony rule, AppSchemer will
                  automatically generate a complete color palette for you. You can experiment with
                  the different harmony colors and adjust your theme settings. Visualize your
                  changes in real time and see your palette applied to several example UIs. Save
                  your palette to one of several supported file formats, or share them via URL or on
                  social media.
                </span>
              ),
              icon: 'Tune',
            },
          ]}
        />
      </Box>

      <Box marginBottom={20} marginTop={15}>
        <Typography marginBottom={5} variant="h4">
          Features
        </Typography>
        <FeatureList features={featureList} />
      </Box>
      <Button
        aria-label="Start the flow to design a new color palette"
        size="large"
        variant="contained"
        onClick={() => onGoToScreen('Color', /* flowMode = */ true)}
      >
        Get started
      </Button>
    </ScreenContainer>
  );
}
