import { MutableRefObject, useContext, useMemo, useState } from 'react';
import { Box, BoxProps, IconButton, Link, Tooltip, Typography, styled } from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import SettingsButtonAndPopover, {
  SettingsPopoverActionButtonType,
} from './SettingsButtonAndPopover';
import { ColorPalette, ColorVariationShade } from '../utils/colors/colorPalette';
import { getSecondaryColorOrNextBestOption, isColorDark } from '../utils/colors/colorUtils';
import { BLUR_BACKGROUND_STYLE } from '../ui/uiStyles';
import { CONTENT_PADDING_PX, HEADER_FOOTER_HEIGHT_PX } from '../ui/uiSizes';
import { AppUIContext, SCREENS, ScreenName, getScreenNameFromPath } from '../utils/appUIContext';
import { CURRENT_APP_VERSION } from '../utils/utils';
import { isDarkModeEnabled } from '../utils/settings';
import { UserPaletteContext } from '../utils/userPaletteContext';

const HEADER_Z_INDEX = 1000;
const LOGO_SIZE_PX = 30;
const HEADER_OPACITY_HEX_VALUE = 'C0';
const APPROXIMATE_HEADER_COLOR_TO_AVERAGE_WITH_WHEN_SEMI_TRANSPARENT = '#787878';

function AppLogo({ uiColorPalette }: { uiColorPalette: ColorPalette }) {
  let secondaryColor =
    uiColorPalette.otherHarmonyColors.length > 0
      ? uiColorPalette.otherHarmonyColors[0]
      : uiColorPalette.secondary;
  if (!secondaryColor) {
    secondaryColor = uiColorPalette.referencePalette.main;
  }

  return (
    <Link aria-label="App logo, return to home page" href="/" style={{ textDecoration: 'none' }}>
      <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
        <Box
          alt="AppRoll logo"
          component="img"
          height={LOGO_SIZE_PX}
          src="./logo192.png"
          width={LOGO_SIZE_PX}
        />
        <Typography fontWeight="bold" variant="h6">
          <span
            style={{
              color: secondaryColor[500],
            }}
          >
            App
          </span>
          <span
            style={{
              color: uiColorPalette.accent[500],
            }}
          >
            Schemer
          </span>
        </Typography>
      </Box>
    </Link>
  );
}

const HeaderContainerBox = ({
  // These aren't "unused", they're needed for the props of the full component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  backgroundColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isFullyTransparent,
  ...props
}: BoxProps & {
  backgroundColor: string;
  isFullyTransparent?: boolean;
}) => <Box {...props} />;

const HeaderContainer = styled(HeaderContainerBox)<{
  backgroundColor: string;
}>(({ backgroundColor, isFullyTransparent }) =>
  isFullyTransparent
    ? {}
    : {
        ...BLUR_BACKGROUND_STYLE,
        backgroundColor: `${backgroundColor}${HEADER_OPACITY_HEX_VALUE}`,
        transition: '0.3s',
        zIndex: HEADER_Z_INDEX,
        '&:hover': {
          backgroundColor: `${backgroundColor}`,
        },
      },
);

const ACTION_BUTTON_SIZE_PX = 35;

interface HeaderActionButtonPropsBase {
  buttonRef?: MutableRefObject<HTMLButtonElement | null>;
}

interface HeaderActionButtonPropsCallback {
  ariaLabel: string;
  icon: keyof typeof MUIIcon;
  tip: string;
  onClick?: () => void;
}

interface HeaderActionButtonPropsRoute {
  screen: ScreenName;
}

export type HeaderActionButtonProps = HeaderActionButtonPropsBase &
  (HeaderActionButtonPropsCallback | HeaderActionButtonPropsRoute);

function HeaderActionButton(
  props: HeaderActionButtonProps & {
    isBackgroundDark: boolean;
    isDarkMode: boolean;
    uiColorPalette: ColorPalette;
    onGoToScreen: (screenName: ScreenName) => void;
  },
) {
  const { ariaLabel, buttonRef, isBackgroundDark, isDarkMode, icon, tip, uiColorPalette, onClick } =
    useMemo(() => {
      if ('screen' in props) {
        const screenInfo = SCREENS[props.screen];
        const title = `Go to ${screenInfo.title}`;
        return {
          ...props,
          ariaLabel: title,
          icon: screenInfo.icon,
          tip: title,
          onClick: () => props.onGoToScreen(getScreenNameFromPath(screenInfo.route)),
        };
      }
      return { ...props };
    }, [props]);

  const isRouteButton = 'screen' in props;

  const Icon = MUIIcon[icon];
  const iconColorSet = isRouteButton ? uiColorPalette.icon : uiColorPalette.accent;
  const iconColorVariations = uiColorPalette.variationValues.mainColor;
  let iconColor: string;
  if (isDarkMode) {
    iconColor = isBackgroundDark
      ? iconColorSet[iconColorVariations.main]
      : iconColorSet[iconColorVariations.darker];
  } else {
    iconColor = isBackgroundDark
      ? iconColorSet[iconColorVariations.lighter]
      : iconColorSet[iconColorVariations.main];
  }

  const iconButton = (
    <Box color={iconColor}>
      <IconButton aria-label={ariaLabel} color="inherit" ref={buttonRef} onClick={onClick}>
        <Icon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
      </IconButton>
    </Box>
  );

  if (tip) {
    return (
      <Tooltip placement="bottom" title={tip}>
        {iconButton}
      </Tooltip>
    );
  }

  return iconButton;
}

interface Props {
  actionButtons?: HeaderActionButtonProps[];
  isFullyTransparent?: boolean;
  onSettingsActionButtonClicked?: (actionButtonType: SettingsPopoverActionButtonType) => void;
}

export default function AppHeader(props: Props) {
  const { actionButtons, isFullyTransparent, onSettingsActionButtonClicked } = props;
  const {
    currentScreen,
    responsiveBreakpoints: { uiSize },
    settings,
    uiColorPalette,
    onGoToScreen,
  } = useContext(AppUIContext);
  const { colorSchemeState: userColorSchemeState } = useContext(UserPaletteContext);

  const isDarkMode = isDarkModeEnabled(settings, userColorSchemeState.options);
  const isMobileView = uiSize === 'smallMobile';

  const [isHovering, setIsHovering] = useState(false);

  const backgroundColor = useMemo(
    () => uiColorPalette.background[uiColorPalette.variationValues.neutrals.dark2],
    [uiColorPalette.background, uiColorPalette.variationValues.neutrals.dark2],
  );

  const isBackgroundDark = useMemo(() => {
    if (isFullyTransparent) {
      return isDarkMode;
    }
    const backgroundOpacityColor = isHovering
      ? backgroundColor
      : APPROXIMATE_HEADER_COLOR_TO_AVERAGE_WITH_WHEN_SEMI_TRANSPARENT;
    return isColorDark(backgroundColor, backgroundOpacityColor);
  }, [backgroundColor, isDarkMode, isFullyTransparent, isHovering]);

  const betaTextColor = useMemo(() => {
    let variation: ColorVariationShade;
    if (isDarkMode) {
      variation = isBackgroundDark
        ? uiColorPalette.variationValues.mainColor.main
        : uiColorPalette.variationValues.mainColor.darker;
    } else {
      variation = isBackgroundDark
        ? uiColorPalette.variationValues.mainColor.lighter
        : uiColorPalette.variationValues.mainColor.main;
    }
    const secondaryColor = getSecondaryColorOrNextBestOption(uiColorPalette);
    return secondaryColor[variation];
  }, [isBackgroundDark, isDarkMode, uiColorPalette]);

  const isPositionFixed = !isMobileView || currentScreen === 'Palette';
  return (
    <HeaderContainer
      alignItems="center"
      backgroundColor={backgroundColor}
      display="flex"
      height={HEADER_FOOTER_HEIGHT_PX}
      isFullyTransparent={isFullyTransparent}
      justifyContent="center"
      position={isPositionFixed ? 'fixed' : 'absolute'}
      width="100%"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
        <Box
          borderRadius={5}
          display="flex"
          flexDirection="row"
          justifyContent="left"
          marginLeft={`${CONTENT_PADDING_PX}px`}
          paddingRight={1}
          style={isFullyTransparent ? BLUR_BACKGROUND_STYLE : {}}
        >
          <AppLogo uiColorPalette={uiColorPalette} />
        </Box>
        {actionButtons && !isMobileView && (
          <Box
            borderRadius={5}
            display="flex"
            flexDirection="row"
            gap={3}
            paddingX={3}
            style={isFullyTransparent ? BLUR_BACKGROUND_STYLE : {}}
          >
            {actionButtons.map((actionButtonProps, index) => (
              <HeaderActionButton
                {...actionButtonProps}
                key={`header_action_button_${index}`}
                isBackgroundDark={isBackgroundDark}
                isDarkMode={isDarkMode}
                uiColorPalette={uiColorPalette}
                onGoToScreen={onGoToScreen}
              />
            ))}
          </Box>
        )}
        <Box
          alignItems="center"
          borderRadius={5}
          color={uiColorPalette.accent[400]}
          display="flex"
          flexDirection="row"
          justifyContent="right"
          marginRight={`${CONTENT_PADDING_PX}px`}
          style={isFullyTransparent ? BLUR_BACKGROUND_STYLE : {}}
        >
          <SettingsButtonAndPopover
            isBackgroundDark={isBackgroundDark}
            isDarkMode={isDarkMode}
            isMobileView={isMobileView}
            onActionButtonClicked={onSettingsActionButtonClicked}
          />
          {!isMobileView && (
            <Typography color={betaTextColor} marginLeft={2} variant="body1">
              {CURRENT_APP_VERSION}
            </Typography>
          )}
        </Box>
      </Box>
    </HeaderContainer>
  );
}
