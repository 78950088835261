import { useCallback, useEffect, useState } from 'react';

const UI_WIDTH_BREAKPOINTS_PX = {
  smallMobile: 0, // mobile view = < 850 px wide or mobile mode enabled
  mediumTabletOrDesktop: 1000, // medium view = 1000+ px
  largeDesktop: 1600, // full view = 1800+ px wide
} as const;

export type UI_WIDTH_SIZE = keyof typeof UI_WIDTH_BREAKPOINTS_PX;

function getIsMobileDevice(): boolean {
  // The new way (supported in some browsers):
  if ('userAgentData' in navigator) {
    const userAgentData = navigator.userAgentData;
    if (
      userAgentData &&
      typeof userAgentData === 'object' &&
      'mobile' in userAgentData &&
      typeof userAgentData.mobile === 'boolean'
    ) {
      return userAgentData.mobile;
    }
  }

  // The old way:
  const { userAgent } = navigator;
  return (
    /Android/i.test(userAgent) ||
    /iPhone/i.test(userAgent) ||
    /iPad/i.test(userAgent) ||
    /IEMobile/i.test(userAgent) ||
    /Opera Mini/i.test(userAgent) ||
    /BlackBerry/i.test(userAgent) ||
    /iPod/i.test(userAgent)
  );
}

function getUISize(windowWidthPx: number): UI_WIDTH_SIZE {
  if (windowWidthPx < UI_WIDTH_BREAKPOINTS_PX.mediumTabletOrDesktop) {
    return 'smallMobile';
  }
  if (windowWidthPx >= UI_WIDTH_BREAKPOINTS_PX.largeDesktop) {
    return 'largeDesktop';
  }
  if (windowWidthPx >= UI_WIDTH_BREAKPOINTS_PX.mediumTabletOrDesktop) {
    return 'mediumTabletOrDesktop';
  }
  return 'smallMobile';
}

class GlobalWindowResizeManager {
  private static _wasInstantiated = false;
  private static _nextRegisteredCallbackHandle = 0;
  private static _registeredCallbacks: Map<number, (windowWidth: number) => void> = new Map();

  private constructor() {
    if (GlobalWindowResizeManager._wasInstantiated) {
      throw Error(
        '[GlobalWindowResizeManager] Only one instance of GlobalWindowResizeManager can exist at once.',
      );
    }
    GlobalWindowResizeManager._nextRegisteredCallbackHandle = 0;
    GlobalWindowResizeManager._registeredCallbacks = new Map();
    GlobalWindowResizeManager._wasInstantiated = true;
    window.addEventListener('resize', this._handleWindowResize);
  }

  private _handleWindowResize(): void {
    const windowWidth = window.innerWidth;
    GlobalWindowResizeManager._registeredCallbacks.forEach((fn) => fn(windowWidth));
  }

  // Returns the handle number to use for un-registering
  public static registerCallback(fn: (windowWidth: number) => void): number {
    if (!GlobalWindowResizeManager._wasInstantiated) {
      new GlobalWindowResizeManager();
    }
    const handle = GlobalWindowResizeManager._nextRegisteredCallbackHandle;
    GlobalWindowResizeManager._nextRegisteredCallbackHandle += 1;
    GlobalWindowResizeManager._registeredCallbacks.set(handle, fn);
    return handle;
  }

  public static unregisterCallback(handle: number) {
    GlobalWindowResizeManager._registeredCallbacks.delete(handle);
  }
}

export interface ResponsiveUIBreakpoints {
  isMobileDevice: boolean;
  uiSize: UI_WIDTH_SIZE;
  windowWidth: number;
}

export default function useResponsiveUIBreakpoints(): ResponsiveUIBreakpoints {
  const [isMobileDevice, setIsMobileDevice] = useState(getIsMobileDevice());
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [uiSize, setUISize] = useState<UI_WIDTH_SIZE>(getUISize(windowWidth));

  const handleWindowResize = useCallback((newWindowWidth: number) => {
    setIsMobileDevice(getIsMobileDevice());
    setWindowWidth(newWindowWidth);
    setUISize(getUISize(newWindowWidth));
  }, []);

  useEffect(() => {
    const handle = GlobalWindowResizeManager.registerCallback(handleWindowResize);
    return () => GlobalWindowResizeManager.unregisterCallback(handle);
  }, [handleWindowResize]);

  return { isMobileDevice, uiSize, windowWidth };
}
