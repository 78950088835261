import { createContext } from 'react';
import { ColorPalette } from '../utils/colors/colorPalette';
import { getColorPalette } from '../utils/colors/colorUtils';
import { DEFAULT_COLOR_SCHEME_STATE } from '../utils/colors/colorSchemeState';

interface AppPreviewContextType {
  colorPalette: ColorPalette;
  isDarkMode: boolean;
  isMobileView: boolean;
  getScaledValue: (value: number) => number;
}

export const AppPreviewContext = createContext<AppPreviewContextType>({
  colorPalette: getColorPalette(DEFAULT_COLOR_SCHEME_STATE),
  isDarkMode: false,
  isMobileView: false,
  getScaledValue: () => {
    throw new Error('[getScaledValue] This function has not yet been defined');
  },
});
