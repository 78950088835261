import { OPTION_NOT_SELECTED } from './colors/colorSchemeState';

export const CURRENT_APP_VERSION = 'v0.2 (beta)';

export interface NumberRange {
  min: number;
  max: number;
}

export function getRandomUniqueKey() {
  return (Math.random() * 1_000_000_000_000_000).toString();
}

export function getRandomElementFromArray<T>(list: T[] | readonly T[]): T {
  if (list.length === 0) {
    throw Error('[getRandomElementFromArray] Array must have at least one element.');
  }
  return list[Math.floor(Math.random() * list.length)];
}

export function getRandomTrueOrFalse(): boolean {
  return Math.random() > 0.5;
}

export function getRandomValueInRange(min: number, max: number): number {
  const diff = Math.abs(max - min);
  return Math.random() * diff + min;
}

export function removeIndicesFromArray<T>(
  list: T[],
  indicesToRemove: (number | null | undefined | typeof OPTION_NOT_SELECTED)[],
): T[] {
  const indicesToRemoveSet = new Set(indicesToRemove);
  return list.reduce((newList, nextItem, index) => {
    if (!indicesToRemoveSet.has(index)) {
      newList.push(nextItem);
    }
    return newList;
  }, [] as T[]);
}

export function getCappedValue(inputValue: number, min: number, max: number): number {
  return Math.min(Math.max(inputValue, min), max);
}

export function isBetween(value: number, min: number, max: number): boolean {
  return value >= min && value < max;
}

export function copyTextToClipboard(text: string): void {
  // Note: this will fail on localhost in mobile, but otherwise works if
  // running the website with https
  navigator.clipboard.writeText(text);
}

export function quickHash(text: string): string {
  // Algorithm taken from these sources:
  //  - https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  //  - https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/7616484#7616484
  return text
    .split('')
    .reduce((curHash, nextChar) => {
      const code = nextChar.charCodeAt(0);
      const nextHash = ((curHash << 5) - curHash + code) | 0;
      return nextHash;
    }, 0)
    .toString();
}
