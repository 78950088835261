import { useMemo } from 'react';
import { Box, TypographyTypeMap } from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import ColorBubbleListItem from './colorBubbleList/ColorBubbleListItem';

// TODO: We can maybe eventually pass in <ColorBubbleListItem> as children directly, without the index,
// by doing something like this and using a wrapper list item component:
/*
function List({ children }) {
  return (
    <div>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          index: index + 1 // Adding 1 to make the index start from 1
        });
      })}
    </div>
  );
}
*/

interface Props {
  listContent: {
    content: TypographyTypeMap['props']['children'];
    icon?: keyof typeof MUIIcon;
  }[];
}

export default function ColorBubbleList(props: Props) {
  const { listContent } = props;

  const bubbleList = useMemo(
    () =>
      listContent.map((listItem, index) => (
        <ColorBubbleListItem icon={listItem.icon} index={index} key={`bubble_list_item_${index}`}>
          {listItem.content}
        </ColorBubbleListItem>
      )),
    [listContent],
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {bubbleList}
    </Box>
  );
}
