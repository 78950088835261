import { useCallback, useContext, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { ColorHarmonyOption } from '../../utils/colors/colorSchemeState';
import BrandExampleList from '../shared/BrandExampleList';
import { HarmonyDescriptionInfo, getHarmonyDescription } from '../../utils/colors/colorInfo';
import { UserPaletteContext } from '../../utils/userPaletteContext';
import { getColorHarmonyOptions } from '../../utils/colors/colorUtils';
import ExampleImageCollection from '../shared/ExampleImageCollection';
import ColorWheel from './ColorWheel';

const MAX_DESCRIPTION_TEXT_WIDTH_PX = 600;
function HarmonyDescriptionContent({
  harmonyDescriptionInfo,
  onMainColorChanged,
}: {
  harmonyDescriptionInfo: HarmonyDescriptionInfo;
  onMainColorChanged: (colorHex: string) => void;
}) {
  const { description, exampleBrands, exampleLogos } = harmonyDescriptionInfo;

  return (
    <Box maxWidth={MAX_DESCRIPTION_TEXT_WIDTH_PX}>
      <Typography fontWeight="bold" variant="body2">
        {description}
      </Typography>
      <BrandExampleList
        brands={exampleBrands}
        isMessagePlural
        label="Example brands"
        onClick={onMainColorChanged}
      />
      <BrandExampleList
        brands={exampleLogos}
        isMessagePlural
        label="Example logos"
        onClick={onMainColorChanged}
      />
    </Box>
  );
}

const HARMONY_TO_EXAMPLE_IMAGES_TITLE_MAP: { [key in ColorHarmonyOption]: string } = {
  complementary: 'The complementary harmony in photos and artwork:',
  splitComplementary: 'The split complementary harmony in photos and artwork:',
  triadic: 'The triadic harmony in photos and artwork:',
  square: 'The square harmony in photos and artwork:',
  tetradic: 'The tetradic harmony in photos and artwork:',
  analogous: 'The analogous harmony in photos and artwork:',
  monochromatic: 'The monochromatic harmony in photos and artwork:',
};

export default function ColorHarmonyInfo() {
  const { colorSchemeState, onColorSchemeStateChanged } = useContext(UserPaletteContext);

  const { selectedHarmony } = colorSchemeState.colorHarmony;

  const colorHarmonyOptions = useMemo(
    () => getColorHarmonyOptions(colorSchemeState.mainColorHex),
    [colorSchemeState.mainColorHex],
  );

  const onMainColorChanged = useCallback(
    (colorHex: string) => onColorSchemeStateChanged({ mainColorHex: colorHex }),
    [onColorSchemeStateChanged],
  );

  const harmonyDescriptionInfo = useMemo(
    () => getHarmonyDescription(selectedHarmony),
    [selectedHarmony],
  );

  const harmonyColors = useMemo(() => {
    const harmonyOptions = colorHarmonyOptions[selectedHarmony];
    return harmonyOptions.map((colorWithVariations) => colorWithVariations[500]);
  }, [selectedHarmony, colorHarmonyOptions]);

  const showHarmonyLines = useMemo(
    () =>
      ['complementary', 'splitComplementary', 'triadic', 'tetradic', 'square'].includes(
        selectedHarmony,
      ),
    [selectedHarmony],
  );

  return (
    <Box marginTop={10}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={5}
        justifyContent="center"
      >
        <HarmonyDescriptionContent
          harmonyDescriptionInfo={harmonyDescriptionInfo}
          onMainColorChanged={onMainColorChanged}
        />
        <ColorWheel
          primaryColor={colorSchemeState.mainColorHex}
          otherColors={selectedHarmony === 'monochromatic' ? [] : harmonyColors}
          selectedHarmony={selectedHarmony}
          showHarmonyLines={showHarmonyLines}
          onPrimaryColorChange={onMainColorChanged}
        />
      </Box>
      <ExampleImageCollection
        customContainerColors={[colorSchemeState.mainColorHex, ...harmonyColors]}
        exampleImages={harmonyDescriptionInfo.examplePictures}
        marginTop={10}
        previewTooltip="Preview this color harmony"
        title={HARMONY_TO_EXAMPLE_IMAGES_TITLE_MAP[selectedHarmony]}
        onPreviewClicked={onMainColorChanged}
      />
    </Box>
  );
}
