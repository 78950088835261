import { ColorResult } from 'react-color';
import ColorSampleBox from '../../shared/ColorSampleBox';

export default function PrimaryColorBox({
  color,
  fluidWidth,
  onEdit,
}: {
  color: string;
  fluidWidth?: boolean;
  onEdit: (color: ColorResult) => void;
}) {
  return (
    <ColorSampleBox
      allowEdit
      bold
      color={color}
      fluidWidth={fluidWidth}
      label="Your main (brand) color"
      showColorCode
      onEdit={onEdit}
    />
  );
}
