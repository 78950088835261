import { useContext, useMemo, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { copyTextToClipboard, getRandomUniqueKey } from '../../../../utils/utils';
import { UserPaletteContext } from '../../../../utils/userPaletteContext';
import {
  COLOR_VARIATION_OPTIONS,
  ColorPalette,
  ColorWithVariations,
  VariationValuesSet,
  VariationValuesSetExtended,
  getLabelMapForVariations,
} from '../../../../utils/colors/colorPalette';
import { AppUIContext } from '../../../../utils/appUIContext';
import { getTertiaryColorOrNextBestOption } from '../../../../utils/colors/colorUtils';

const BRIGHTNESS_HELPER_BACKGROUND_OPACITY_HEX = '70' as const;

const ROW_COLOR_BOX_HEIGHT = 50;

const VARIATION_INDICATOR_SIZE_PX = 8;
const VARIATION_INDICATOR_BORDER_PX = 1;

function ColorPalettePreviewRow({
  color,
  isNeutralsRow,
  keyVariations,
  label,
  uiColorPalette,
}: {
  color: ColorWithVariations;
  isNeutralsRow?: boolean;
  keyVariations?: VariationValuesSet | VariationValuesSetExtended;
  label: string;
  uiColorPalette: ColorPalette;
}) {
  const [wasColorCodeCopied, setWasColorCodeCopied] = useState<boolean>(false);
  const rowUniqueId = useMemo(() => getRandomUniqueKey(), []);

  const variationsToHighlight = useMemo(() => {
    if (!keyVariations) {
      return null;
    }
    const keyVariationToLabelMap = getLabelMapForVariations(keyVariations);
    return {
      keyVariationToLabelMap,
      mainHighlightColor: uiColorPalette.accent[500],
      secondaryHighlightColor: getTertiaryColorOrNextBestOption(uiColorPalette)[500],
    };
  }, [keyVariations, uiColorPalette]);

  return (
    <Box width="100%">
      <Typography marginBottom={0.5} variant="body2">
        {label}:
      </Typography>
      <Box display="flex" flexDirection="row">
        {COLOR_VARIATION_OPTIONS.map((variation) => {
          const colorHex = color[variation];
          const colorHexFormatted = colorHex.toUpperCase();
          const variationLabel = variationsToHighlight?.keyVariationToLabelMap.get(variation);
          const additionalTooltipText = variationLabel
            ? `${variationLabel.label} - ${variation}`
            : null;
          const tooltipText = wasColorCodeCopied
            ? 'Copied to clipboard'
            : `${colorHexFormatted}${additionalTooltipText ? ` (${additionalTooltipText})` : ''}`;
          return (
            <Box key={`preview_row_${rowUniqueId}_${variation}`} width="100%">
              <Tooltip
                placement="bottom"
                title={tooltipText}
                onMouseEnter={() => setWasColorCodeCopied(false)}
              >
                <Box
                  bgcolor={colorHex}
                  height={ROW_COLOR_BOX_HEIGHT}
                  position="relative"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    copyTextToClipboard(colorHexFormatted);
                    setWasColorCodeCopied(true);
                  }}
                >
                  {variationLabel && variationsToHighlight && (
                    <Box
                      bgcolor={
                        variationLabel.key === 'main' && !isNeutralsRow
                          ? variationsToHighlight.mainHighlightColor
                          : variationsToHighlight.secondaryHighlightColor
                      }
                      border={VARIATION_INDICATOR_BORDER_PX}
                      borderColor={uiColorPalette.divider[200]}
                      borderRadius="50%"
                      bottom={5}
                      height={VARIATION_INDICATOR_SIZE_PX}
                      left={`calc(50% - ${
                        (VARIATION_INDICATOR_SIZE_PX + VARIATION_INDICATOR_BORDER_PX) / 2
                      }px)`}
                      position="absolute"
                      width={VARIATION_INDICATOR_SIZE_PX}
                    />
                  )}
                </Box>
              </Tooltip>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default function ColorPalettePreview() {
  const { colorPalette } = useContext(UserPaletteContext);
  const {
    responsiveBreakpoints: { uiSize },
    uiColorPalette,
  } = useContext(AppUIContext);

  const colorVariationStoppersToDisplay = useMemo(() => {
    const totalNumVariations = COLOR_VARIATION_OPTIONS.length;
    if (uiSize === 'smallMobile' && totalNumVariations > 0) {
      return [COLOR_VARIATION_OPTIONS[Math.floor(totalNumVariations / 2)]];
    }
    return COLOR_VARIATION_OPTIONS;
  }, [uiSize]);

  const brightnessVisualizerGradientStart = `${uiColorPalette.accent[200]}${BRIGHTNESS_HELPER_BACKGROUND_OPACITY_HEX}`;
  const brightnessVisualizerGradientEnd = `${
    getTertiaryColorOrNextBestOption(uiColorPalette)[800]
  }${BRIGHTNESS_HELPER_BACKGROUND_OPACITY_HEX}`;

  return (
    <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
      <Box
        alignItems="center"
        borderRadius={5}
        display="flex"
        flexDirection="row"
        height={ROW_COLOR_BOX_HEIGHT}
        justifyContent="space-between"
        paddingX={1}
        width="100%"
        style={{
          background: `linear-gradient(to right, ${brightnessVisualizerGradientStart} 0%, ${brightnessVisualizerGradientEnd} 100%)`,
        }}
      >
        <Box alignItems="center" display="flex" flexDirection="column" width={30}>
          <ArrowBackOutlinedIcon fontSize="small" />
          <Typography variant="caption">Light</Typography>
        </Box>
        {colorVariationStoppersToDisplay.map((variation) => (
          <Typography key={`palette_preview_header_variation_${variation}`} variant="body2">
            {variation}
          </Typography>
        ))}
        <Box alignItems="center" display="flex" flexDirection="column" width={30}>
          <ArrowForwardOutlinedIcon fontSize="small" />
          <Typography variant="caption">Dark</Typography>
        </Box>
      </Box>
      <ColorPalettePreviewRow
        color={colorPalette.referencePalette.main}
        keyVariations={colorPalette.variationValues.mainColor}
        label="Main (brand) color"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.accent}
        keyVariations={colorPalette.variationValues.mainColor}
        label="Accent"
        uiColorPalette={uiColorPalette}
      />
      {colorPalette.secondary && (
        <ColorPalettePreviewRow
          color={colorPalette.secondary}
          keyVariations={colorPalette.variationValues.mainColor}
          label="Secondary"
          uiColorPalette={uiColorPalette}
        />
      )}
      {colorPalette.otherHarmonyColors.map((colorWithVariations, index) => (
        <ColorPalettePreviewRow
          key={`palette_preview_alt_color_${index}`}
          color={colorWithVariations}
          keyVariations={colorPalette.variationValues.mainColor}
          label={`Harmony ${index + 1}`}
          uiColorPalette={uiColorPalette}
        />
      ))}
      <ColorPalettePreviewRow
        color={colorPalette.text}
        isNeutralsRow
        keyVariations={colorPalette.variationValues.neutrals}
        label="Text"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.background}
        isNeutralsRow
        keyVariations={colorPalette.variationValues.neutrals}
        label="Background"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.disabled}
        isNeutralsRow
        keyVariations={colorPalette.variationValues.neutrals}
        label="Disabled"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.divider}
        isNeutralsRow
        keyVariations={colorPalette.variationValues.neutrals}
        label="Divider"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.icon}
        keyVariations={colorPalette.variationValues.mainColor}
        label="Icon"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.semantic.info}
        keyVariations={colorPalette.variationValues.semantic}
        label="Info (semantic)"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.semantic.negative}
        keyVariations={colorPalette.variationValues.semantic}
        label="Negative (semantic)"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.semantic.positive}
        keyVariations={colorPalette.variationValues.semantic}
        label="Positive (semantic)"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.semantic.special}
        keyVariations={colorPalette.variationValues.semantic}
        label="Special (semantic)"
        uiColorPalette={uiColorPalette}
      />
      <ColorPalettePreviewRow
        color={colorPalette.semantic.warning}
        keyVariations={colorPalette.variationValues.semantic}
        label="Warning (semantic)"
        uiColorPalette={uiColorPalette}
      />
    </Box>
  );
}
