import { Box, Link, Typography } from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import { useContext, useMemo } from 'react';
import { UserPaletteContext } from '../../utils/userPaletteContext';
import { isDarkModeEnabled } from '../../utils/settings';
import { AppUIContext } from '../../utils/appUIContext';

const CELL_ICON_SIZE_PX = 16;

function ContentFooterColumn({ children, title }: { children: React.ReactNode; title: string }) {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography fontWeight="bold" variant="caption">
        {title}
      </Typography>
      {children}
    </Box>
  );
}

function ContentFooterCell({
  ariaLabel,
  children,
  href,
  icon,
}: {
  ariaLabel: string;
  children: string;
  href?: string;
  icon?: keyof typeof MUIIcon;
}) {
  const content = useMemo(() => {
    const Icon = icon ? MUIIcon[icon] : undefined;
    return (
      <>
        {Icon && <Icon style={{ fontSize: CELL_ICON_SIZE_PX }} />}
        <Typography variant="caption">{children}</Typography>
      </>
    );
  }, [children, icon]);

  if (href) {
    return (
      <Link
        aria-label={ariaLabel}
        alignItems="center"
        display="flex"
        flexDirection="row"
        gap={0.5}
        href={href}
        target="_blank"
      >
        {content}
      </Link>
    );
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="row" gap={0.5}>
      {content}
    </Box>
  );
}

export default function ContentFooter() {
  const {
    responsiveBreakpoints: { uiSize },
    settings,
    uiColorPalette,
  } = useContext(AppUIContext);
  const { colorSchemeState: userColorSchemeState } = useContext(UserPaletteContext);

  const isDarkMode = isDarkModeEnabled(settings, userColorSchemeState.options);
  const { neutrals: neutralVariationValues } = uiColorPalette.variationValues;

  const yearNow = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box
      alignItems="center"
      bgcolor={
        uiColorPalette.background[
          isDarkMode ? neutralVariationValues.darker : neutralVariationValues.light2
        ]
      }
      display="flex"
      flexDirection="column"
      padding={3}
    >
      <Box
        columnGap={uiSize === 'smallMobile' ? 3 : 15}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={uiSize === 'smallMobile' ? 'space-evenly' : 'center'}
        rowGap={3}
      >
        <ContentFooterColumn title="Color apps and tools">
          <ContentFooterCell
            ariaLabel="Adobe color wheel tool for previewing color harmonies"
            href="https://color.adobe.com/create/color-wheel"
          >
            Adobe color wheel tool
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="Realtime Colors tool for visualizing UI colors in real time"
            href="https://realtimecolors.com/"
          >
            Realtime Colors
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="Tool for generating tints and shades of a color"
            href="https://maketintsandshades.com/"
          >
            Tint and shades generator
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="Color contrast checker tool, check the contrast between two colors"
            href="https://coolors.co/contrast-checker"
          >
            Color contrast checker
          </ContentFooterCell>
        </ContentFooterColumn>
        <ContentFooterColumn title="Color resources">
          <ContentFooterCell
            ariaLabel="Article on the cultural psychology of colors"
            href="https://www.empower-yourself-with-color-psychology.com/cultural-color.html"
          >
            Cultural color psychology
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="A guide to color harmonies"
            href="https://www.colorsexplained.com/color-harmony/"
          >
            Color harmony guide
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="A guide to MUI color palettes"
            href="https://mui.com/material-ui/customization/palette/"
          >
            MUI color palettes
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="Apple's official color guidelines (for reference)"
            href="https://developer.apple.com/design/human-interface-guidelines/color"
          >
            Apple color guidelines
          </ContentFooterCell>
        </ContentFooterColumn>
        <ContentFooterColumn title="Libraries">
          <ContentFooterCell
            ariaLabel="TinyColor library for manipulating color in JavaScript"
            href="https://github.com/bgrins/TinyColor"
          >
            TinyColor
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="React Color library for color pickers and UI color components"
            href="https://casesandberg.github.io/react-color/"
          >
            React Color
          </ContentFooterCell>
        </ContentFooterColumn>
        <ContentFooterColumn title="Let's connect">
          <ContentFooterCell
            ariaLabel="My personal website"
            href="https://www.teammcode.com"
            icon="OpenInNewOutlined"
          >
            teammcode.com
          </ContentFooterCell>
          <ContentFooterCell
            ariaLabel="My Twitter (X) account"
            href="https://twitter.com/DevSchemer"
            icon="Twitter"
          >
            Twitter (X)
          </ContentFooterCell>
        </ContentFooterColumn>
      </Box>
      <Typography marginTop={3} textAlign="center" variant="body2">
        Copyright © {yearNow}. All rights reserved.
      </Typography>
    </Box>
  );
}
