import { ComponentProps, useContext, useMemo, useState } from 'react';
import { Box, BoxProps, Button, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ExamplePictureInfo } from '../../utils/colors/colorInfo';
import { AppUIContext } from '../../utils/appUIContext';
import { ColorPalette } from '../../utils/colors/colorPalette';
import { addAlphaToColor } from '../../ui/uiColors';
import { quickHash } from '../../utils/utils';

const APP_COLORS_BACKGROUND_ALPHA = 0.15;
const USER_COLOR_BACKGROUND_ALPHA = 0.65;
const USER_MULTIPLE_COLORS_BACKGROUND_ALPHA = 0.4;

const EXAMPLE_IMAGE_HEIGHT_PX = 250;

function ExampleImage({
  imageInfo,
  isMobileView,
  previewTooltip,
  uiColorPalette,
  onPreviewClicked,
}: {
  imageInfo: ExamplePictureInfo;
  isMobileView: boolean;
  previewTooltip: string;
  uiColorPalette: ColorPalette;
  onPreviewClicked: (colorHex: string) => void;
}) {
  const [isLoading, setIsLoading] = useState(true);

  const { alt, aspectRatio, baseColor, caption, url } = imageInfo;

  const sizeProps: BoxProps = useMemo(() => {
    const width = Math.round(EXAMPLE_IMAGE_HEIGHT_PX * aspectRatio);
    return isMobileView
      ? { maxHeight: EXAMPLE_IMAGE_HEIGHT_PX, maxWidth: width }
      : { height: EXAMPLE_IMAGE_HEIGHT_PX, width };
  }, [aspectRatio, isMobileView]);

  return (
    <Box display="flex" position="relative" flexDirection="column">
      <Box {...sizeProps} borderRadius={2} boxShadow={2} position="relative">
        <Box
          alt={alt}
          borderRadius={2}
          component="img"
          height="100%"
          src={url}
          visibility={isLoading ? 'hidden' : undefined}
          width="100%"
          onLoad={() => setIsLoading(false)}
        />
        {isLoading && (
          <Box borderRadius={2} height="100%" left={0} position="absolute" top={0} width="100%">
            <Skeleton
              animation="wave"
              height="100%"
              variant="rectangular"
              width="100%"
              style={{ borderRadius: 8 }}
            />
          </Box>
        )}
      </Box>
      <Typography marginTop={1} variant="caption">
        {caption}
      </Typography>
      <Box
        bgcolor={`${addAlphaToColor(uiColorPalette.background[400], 0.75)}`}
        borderRadius="50%"
        boxShadow={2}
        color={uiColorPalette.white}
        margin={0.5}
        position="absolute"
        right={0}
        top={0}
      >
        <Tooltip placement="bottom" title={previewTooltip}>
          <IconButton
            aria-label="Preview the colors represented in this image"
            color="inherit"
            onClick={() => onPreviewClicked(baseColor)}
          >
            <VisibilityOutlinedIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

interface Props {
  containerCentralColor?: string;
  customContainerColors?: string[]; // if this is provided, `containerCentralColor` will be ignored
  exampleImages: ExamplePictureInfo[];
  marginBottom?: number;
  marginTop?: number;
  previewTooltip: string;
  title: string;
  width?: ComponentProps<typeof Box>['width'];
  onPreviewClicked: (colorHex: string) => void;
}

export default function ExampleImageCollection(props: Props) {
  const {
    containerCentralColor,
    customContainerColors,
    exampleImages,
    marginBottom,
    marginTop,
    previewTooltip,
    title,
    width,
    onPreviewClicked,
  } = props;

  const {
    responsiveBreakpoints: { uiSize },
    uiColorPalette,
  } = useContext(AppUIContext);

  const isMobileView = uiSize === 'smallMobile';

  const [showExampleImages, setShowExampleImages] = useState(true);

  const gradientBackgroundColors = useMemo(() => {
    if (customContainerColors && customContainerColors.length > 0) {
      return customContainerColors.map((color) =>
        addAlphaToColor(color, USER_MULTIPLE_COLORS_BACKGROUND_ALPHA),
      );
    }
    const accentColorWithAlpha = addAlphaToColor(
      uiColorPalette.accent[uiColorPalette.variationValues.mainColor.main],
      APP_COLORS_BACKGROUND_ALPHA,
    );
    const secondaryColorWithAlpha = uiColorPalette.secondary
      ? addAlphaToColor(
          uiColorPalette.secondary[uiColorPalette.variationValues.mainColor.main],
          APP_COLORS_BACKGROUND_ALPHA,
        )
      : accentColorWithAlpha;
    if (containerCentralColor) {
      return [
        accentColorWithAlpha,
        addAlphaToColor(containerCentralColor, USER_COLOR_BACKGROUND_ALPHA),
        secondaryColorWithAlpha,
      ];
    }
    return [accentColorWithAlpha];
  }, [
    containerCentralColor,
    customContainerColors,
    uiColorPalette.accent,
    uiColorPalette.secondary,
    uiColorPalette.variationValues.mainColor.main,
  ]);

  if (exampleImages.length === 0) {
    return null;
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      marginBottom={marginBottom}
      marginTop={marginTop}
      width={width}
    >
      <Box marginBottom={1}>
        <Button onClick={() => setShowExampleImages((prev) => !prev)}>
          {showExampleImages ? 'Hide examples' : 'Show examples'}
        </Button>
      </Box>
      {showExampleImages && (
        <Box
          style={{
            background: `linear-gradient(to right, ${gradientBackgroundColors.join(', ')})`,
          }}
          borderRadius={5}
          width="100%"
        >
          <Typography padding={2} variant="h6">
            {title}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={3}
            justifyContent="center"
            paddingX={3}
            paddingY={2}
          >
            {exampleImages.map((imageInfo) => (
              <ExampleImage
                key={`example_image_${quickHash(imageInfo.url)}`}
                imageInfo={imageInfo}
                isMobileView={isMobileView}
                previewTooltip={previewTooltip}
                uiColorPalette={uiColorPalette}
                onPreviewClicked={onPreviewClicked}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
