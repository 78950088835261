import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ColorSampleBox from '../shared/ColorSampleBox';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  AllColorHarmonyOptions,
  ColorHarmonyOption,
  ColorSchemeState,
} from '../../utils/colors/colorSchemeState';
import { UserPaletteContext } from '../../utils/userPaletteContext';
import { useCallback, useContext, useMemo } from 'react';
import { getColorHarmonyOptions } from '../../utils/colors/colorUtils';
import useDebounceUpdateMainColor from '../../utils/useDebounceUpdateMainColor';
import { AppUIContext } from '../../utils/appUIContext';

function HarmonyOptionsToggleGroup({
  colorHarmonyOptions,
  colorSchemeState,
  onHarmonyOptionChange,
}: {
  colorHarmonyOptions: AllColorHarmonyOptions;
  colorSchemeState: ColorSchemeState;
  onHarmonyOptionChange: (newHarmonySelection: ColorHarmonyOption) => void;
}) {
  // The `textTransform: 'none'` style removes the text capitalization (as button text) by MUI:
  const toggleButtonStyle: React.CSSProperties = {
    border: 0,
    borderRadius: 8,
    textTransform: 'none',
  };
  return (
    <ToggleButtonGroup
      aria-label="Color harmony options"
      exclusive
      style={{ flexWrap: 'wrap', justifyContent: 'center' }}
      value={colorSchemeState.colorHarmony.selectedHarmony}
      onChange={(_event, value) => onHarmonyOptionChange(value)}
    >
      {/* COMPLEMENTARY */}
      <ToggleButton
        aria-label="Select complementary harmony option"
        style={toggleButtonStyle}
        value="complementary"
      >
        <ColorSampleBox colors={colorHarmonyOptions.complementary} label="Complementary" />
      </ToggleButton>
      {/* SPLIT COMPLEMENTARY */}
      <ToggleButton
        aria-label="Select split complementary harmony option"
        style={toggleButtonStyle}
        value="splitComplementary"
      >
        <ColorSampleBox
          colors={colorHarmonyOptions.splitComplementary}
          label="Split complementary"
        />
      </ToggleButton>
      {/* TRIADIC */}
      <ToggleButton
        aria-label="Select triadic harmony option"
        style={toggleButtonStyle}
        value="triadic"
      >
        <ColorSampleBox colors={colorHarmonyOptions.triadic} label="Triadic" />
      </ToggleButton>
      {/* SQUARE */}
      <ToggleButton
        aria-label="Select square harmony option"
        style={toggleButtonStyle}
        value="square"
      >
        <ColorSampleBox colors={colorHarmonyOptions.square} label="Square" />
      </ToggleButton>
      {/* TETRADIC */}
      <ToggleButton
        aria-label="Select tetradic harmony option"
        style={toggleButtonStyle}
        value="tetradic"
      >
        <ColorSampleBox colors={colorHarmonyOptions.tetradic} label="Tetradic" />
      </ToggleButton>
      {/* ANALOGOUS */}
      <ToggleButton
        aria-label="Select analogous harmony option"
        style={toggleButtonStyle}
        value="analogous"
      >
        <ColorSampleBox colors={colorHarmonyOptions.analogous} label="Analogous" />
      </ToggleButton>
      {/* MONOCHROMATIC */}
      <ToggleButton
        aria-label="Select monochromatic harmony option"
        style={toggleButtonStyle}
        value="monochromatic"
      >
        <ColorSampleBox colors={colorHarmonyOptions.monochromatic} label="Monochromatic" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default function ColorHarmonySelector() {
  const { colorSchemeState, onColorSchemeStateChanged } = useContext(UserPaletteContext);
  const {
    responsiveBreakpoints: { uiSize },
  } = useContext(AppUIContext);

  const colorHarmonyOptions = useMemo(
    () => getColorHarmonyOptions(colorSchemeState.mainColorHex),
    [colorSchemeState.mainColorHex],
  );

  const handleColorHarmonySelectionChange = useCallback(
    (newHarmonySelection: ColorHarmonyOption) => {
      if (!newHarmonySelection) {
        return;
      }
      onColorSchemeStateChanged({
        colorHarmony: { ...colorSchemeState.colorHarmony, selectedHarmony: newHarmonySelection },
      });
    },
    [colorSchemeState.colorHarmony, onColorSchemeStateChanged],
  );

  const { debounceUpdateMainColor, mainColorHexRealTime } = useDebounceUpdateMainColor();

  if (uiSize === 'smallMobile') {
    return (
      <Box alignItems="center" display="flex" flexDirection="column">
        <ColorSampleBox
          allowEdit
          bold
          color={mainColorHexRealTime}
          label="Your main color"
          showColorCode
          onEdit={debounceUpdateMainColor}
        />
        <Typography padding={2}>
          <DoubleArrowIcon fontSize="large" style={{ transform: 'rotate(90deg)' }} />
        </Typography>
        <HarmonyOptionsToggleGroup
          colorHarmonyOptions={colorHarmonyOptions}
          colorSchemeState={colorSchemeState}
          onHarmonyOptionChange={handleColorHarmonySelectionChange}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box alignItems="center" display="flex" flexDirection="row">
        <ColorSampleBox
          allowEdit
          bold
          color={mainColorHexRealTime}
          label="Your main color"
          showColorCode
          onEdit={debounceUpdateMainColor}
        />
      </Box>
      <Typography>
        <DoubleArrowIcon fontSize="large" style={{ marginRight: 20, height: '100%' }} />
      </Typography>
      <HarmonyOptionsToggleGroup
        colorHarmonyOptions={colorHarmonyOptions}
        colorSchemeState={colorSchemeState}
        onHarmonyOptionChange={handleColorHarmonySelectionChange}
      />
    </Box>
  );
}
