import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
} from '@mui/material';
import Draggable from 'react-draggable';
import { DIALOG_ACTIONS_STYLE } from '../../../../ui/uiStyles';
import ColorPalettePreview from './ColorPalettePreview';

const DRAGGABLE_HANDLE_ID = 'draggable-dialog-title';

function DraggableDialogPaperComponent(props: PaperProps) {
  return (
    <Draggable handle={`#${DRAGGABLE_HANDLE_ID}`} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

interface Props {
  isMovable?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export default function FullPaletteDialog(props: Props) {
  const { isMovable, isOpen, onClose } = props;

  return (
    <Dialog
      PaperComponent={isMovable ? DraggableDialogPaperComponent : undefined}
      aria-labelledby={DRAGGABLE_HANDLE_ID}
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle style={isMovable ? { cursor: 'move' } : undefined}>
        Your full color palette
      </DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={3}>
          The indicators show which shades are being used according to your selected options. Click
          on a color to copy its value.
        </DialogContentText>
        <ColorPalettePreview />
      </DialogContent>
      <DialogActions style={DIALOG_ACTIONS_STYLE}>
        <Button aria-label="Close this dialog menu" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
