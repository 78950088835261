import { useCallback, useContext, useMemo, useState } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { ColorResult, SliderPicker } from 'react-color';
import {
  adjustColorHSLValues,
  getHSLFromColorHex,
  isColorDark,
} from '../../utils/colors/colorUtils';
import { addAlphaToColor } from '../../ui/uiColors';
import { AppUIContext } from '../../utils/appUIContext';

const SLIDER_TITLE_TEXT_MAX_WIDTH_PX = 120;

interface Props {
  currentColorHex: string;
  onColorChange: (colorHex: string | ColorResult) => void;
}

export default function ColorSliders(props: Props) {
  const { currentColorHex, onColorChange } = props;

  const { uiColorPalette } = useContext(AppUIContext);

  const [isHovering, setIsHovering] = useState(false);
  const [isAdjustingSliders, setIsAdjustingSliders] = useState(false);

  const shouldShowFullColorBackground = !isHovering || isAdjustingSliders;

  const { displayTextColor, mainColorLightnessPercent, mainColorSaturationPercent, sliderColor } =
    useMemo(() => {
      const mainColorHSL = getHSLFromColorHex(currentColorHex);
      let displayTextColor: string | undefined = undefined;
      if (shouldShowFullColorBackground) {
        displayTextColor = isColorDark(currentColorHex)
          ? uiColorPalette.text[100]
          : uiColorPalette.text[900];
      }
      let sliderColor: string | undefined = undefined;
      if (shouldShowFullColorBackground) {
        sliderColor = isColorDark(currentColorHex)
          ? uiColorPalette.accent[400]
          : uiColorPalette.accent[900];
      }
      return {
        displayTextColor,
        mainColorLightnessPercent: Math.round(mainColorHSL.lightness * 100),
        mainColorSaturationPercent: Math.round(mainColorHSL.saturation * 100),
        sliderColor,
      };
    }, [
      currentColorHex,
      shouldShowFullColorBackground,
      uiColorPalette.accent,
      uiColorPalette.text,
    ]);

  const handleSaturationSliderChange = useCallback(
    (value: number | number[]) => {
      setIsAdjustingSliders(true);
      const percentage = Array.isArray(value) ? value[0] : value;
      const saturation = percentage / 100;
      onColorChange(adjustColorHSLValues(currentColorHex, { saturation }));
    },
    [currentColorHex, onColorChange],
  );

  const handleLightnessSliderChange = useCallback(
    (value: number | number[]) => {
      setIsAdjustingSliders(true);
      const percentage = Array.isArray(value) ? value[0] : value;
      const lightness = percentage / 100;
      onColorChange(adjustColorHSLValues(currentColorHex, { lightness }));
    },
    [currentColorHex, onColorChange],
  );

  return (
    <Box
      bgcolor={
        shouldShowFullColorBackground ? currentColorHex : addAlphaToColor(currentColorHex, 0.1)
      }
      border={1}
      borderColor="divider"
      borderRadius={2.5}
      component="div"
      display="flex"
      flexDirection="column"
      gap={2}
      padding={3}
      width={300}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Typography color={displayTextColor} fontWeight="bold" variant="body1">
        HSL
      </Typography>
      <Box display="flex" flexDirection="column" gap={1} marginBottom={1}>
        <Typography color={displayTextColor} textAlign="left" variant="body2">
          Hue:
        </Typography>
        <Box width="100%">
          <SliderPicker color={currentColorHex} onChange={onColorChange} />
        </Box>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" gap={1} justifyContent="center">
        <Typography
          color={displayTextColor}
          textAlign="left"
          variant="body2"
          width={SLIDER_TITLE_TEXT_MAX_WIDTH_PX}
        >
          Saturation:
        </Typography>
        <Slider
          style={{ color: sliderColor }}
          aria-label="Saturation slider"
          step={1}
          value={mainColorSaturationPercent}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
          onChange={(_e, value) => handleSaturationSliderChange(value)}
          onChangeCommitted={() => setIsAdjustingSliders(false)}
        />
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" gap={1} justifyContent="center">
        <Typography
          color={displayTextColor}
          textAlign="left"
          variant="body2"
          width={SLIDER_TITLE_TEXT_MAX_WIDTH_PX}
        >
          Lightness:
        </Typography>
        <Slider
          style={{ color: sliderColor }}
          aria-label="Lightness slider"
          step={1}
          value={mainColorLightnessPercent}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
          onChange={(_e, value) => handleLightnessSliderChange(value)}
          onChangeCommitted={() => setIsAdjustingSliders(false)}
        />
      </Box>
    </Box>
  );
}
