import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { isPaletteTooLightOrDark } from '../../../utils/colors/colorUtils';
import { useCallback, useContext, useMemo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { UserPaletteContext } from '../../../utils/userPaletteContext';
import {
  ColorBrightnessOption,
  ColorSchemeOptions,
  ContrastOption,
  DEFAULT_COLOR_SCHEME_OPTIONS,
} from '../../../utils/colors/colorSchemeState';
import { CONTAINER_DEFAULT_THEME } from '../../../ui/uiStyles';
import { AppUIContext } from '../../../utils/appUIContext';
import { getBaseColorNameLowercase } from '../../../utils/colors/colorInfo';

const CONTROL_BOX_WIDTH_PX = 300;
const CONTROL_BOX_WIDTH_MOBILE_VIEW_PX = 250;

function InfoTooltipIcon({ tip }: { tip: string }) {
  return (
    <Tooltip placement="right" title={tip}>
      <InfoOutlinedIcon color="action" fontSize="small" />
    </Tooltip>
  );
}

function ThemeOptionRow({
  children,
  label,
  tip,
}: {
  children: React.ReactNode;
  label: string;
  tip?: string;
}) {
  return (
    <FormControl
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
      }}
    >
      <Box display="flex" flexDirection="row" gap={2}>
        <FormLabel>{label}</FormLabel>
        {tip && <InfoTooltipIcon tip={tip} />}
      </Box>
      {children}
    </FormControl>
  );
}

const BRIGHTNESS_SLIDER_MARKS = [
  { label: '300', value: 300 },
  { label: '400', value: 400 },
  { label: '500', value: 500 },
  { label: '600', value: 600 },
  { label: '700', value: 700 },
];
function getSliderValueFromBrightnessValue(brightnessValue: ColorBrightnessOption): number {
  switch (brightnessValue) {
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return brightnessValue * 100;
  }
}
function getBrightnessValueFromSliderValue(sliderValue: number): ColorBrightnessOption {
  switch (sliderValue) {
    case 300:
      return 3;
    case 400:
      return 4;
    case 600:
      return 6;
    case 700:
      return 7;
    case 500:
    default:
      return 5;
  }
}
function ThemeOptionBrightnessSliderRow({
  ariaLabel,
  currentBrightness,
  label,
  tip,
  onBrightnessChange,
}: {
  ariaLabel: string;
  currentBrightness: ColorBrightnessOption;
  label: string;
  tip: string;
  onBrightnessChange: (newBrightness: ColorBrightnessOption) => void;
}) {
  const onSliderValueChanged = useCallback(
    (newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        onBrightnessChange(getBrightnessValueFromSliderValue(newValue));
      }
    },
    [onBrightnessChange],
  );

  return (
    <ThemeOptionRow label={label} tip={tip}>
      <Slider
        aria-label={ariaLabel}
        marks={BRIGHTNESS_SLIDER_MARKS}
        max={700}
        min={300}
        step={100}
        value={getSliderValueFromBrightnessValue(currentBrightness)}
        valueLabelDisplay="off"
        onChange={(_e, newVal) => onSliderValueChanged(newVal)}
      />
    </ThemeOptionRow>
  );
}

const CONTRAST_SLIDER_MARKS_LOW_MEDIUM_HIGH = [
  { label: 'Low', value: 0 },
  { label: 'Medium', value: 1 },
  { label: 'High', value: 2 },
];
const CONTRAST_SLIDER_MARKS_LOW_HIGH = [
  { label: 'Low', value: 0 },
  { label: 'High', value: 2 },
];
function getSliderValueFromContrastValue(contrastValue: ContrastOption): number {
  switch (contrastValue) {
    case 'low':
      return 0;
    case 'normal':
      return 1;
    case 'high':
      return 2;
  }
}
function getContrastValueFromSliderValue(sliderValue: number): ContrastOption {
  switch (sliderValue) {
    case 0:
      return 'low';
    case 2:
      return 'high';
    case 1:
    default:
      return 'normal';
  }
}
function ThemeOptionContrastSliderRow({
  ariaLabel,
  currentContrast,
  hideMediumOption,
  label,
  tip,
  onContrastChange,
}: {
  ariaLabel: string;
  currentContrast: ContrastOption;
  hideMediumOption?: boolean;
  label: string;
  tip: string;
  onContrastChange: (newContrast: ContrastOption) => void;
}) {
  const onSliderValueChanged = useCallback(
    (newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        onContrastChange(getContrastValueFromSliderValue(newValue));
      }
    },
    [onContrastChange],
  );

  return (
    <ThemeOptionRow label={label} tip={tip}>
      <Slider
        aria-label={ariaLabel}
        marks={
          hideMediumOption ? CONTRAST_SLIDER_MARKS_LOW_HIGH : CONTRAST_SLIDER_MARKS_LOW_MEDIUM_HIGH
        }
        max={2}
        min={0}
        step={hideMediumOption ? 2 : 1}
        value={getSliderValueFromContrastValue(currentContrast)}
        valueLabelDisplay="off"
        onChange={(_e, newVal) => onSliderValueChanged(newVal)}
      />
    </ThemeOptionRow>
  );
}

function ThemeOptionCheckboxRow({
  ariaLabel,
  checked,
  label,
  tip,
  onChange,
}: {
  ariaLabel: string;
  checked: boolean;
  label: string;
  tip?: string;
  onChange: (isChecked: boolean) => void;
}) {
  return (
    <Box textAlign="left">
      <FormControlLabel
        control={
          <Checkbox
            aria-label={ariaLabel}
            checked={checked}
            onChange={(_event, checked) => onChange(checked)}
          />
        }
        label={label}
      />
      {tip && <InfoTooltipIcon tip={tip} />}
    </Box>
  );
}

interface Props {
  isSelfContained?: boolean;
}

export default function ThemeOptionsPanel(props: Props) {
  const { isSelfContained } = props;

  const {
    colorPalette: userColorPalette,
    colorSchemeState,
    onColorSchemeStateChanged,
  } = useContext(UserPaletteContext);
  const {
    responsiveBreakpoints: { uiSize },
  } = useContext(AppUIContext);

  const paletteBrightnessWarning = useMemo(() => {
    const status = isPaletteTooLightOrDark(userColorPalette);
    if (status === 'LIGHT') {
      return 'Warning: Some colors might be too bright';
    }
    if (status === 'DARK') {
      return 'Warning: Some colors might be too dark';
    }
    return undefined;
  }, [userColorPalette]);

  const updateColorSchemeOptions = useCallback(
    (updatedOptions: Partial<ColorSchemeOptions>) => {
      onColorSchemeStateChanged({
        options: { ...colorSchemeState.options, ...updatedOptions },
      });
    },
    [colorSchemeState.options, onColorSchemeStateChanged],
  );

  const containerProps = useMemo(() => {
    if (uiSize === 'smallMobile') {
      return {
        ...CONTAINER_DEFAULT_THEME,
        maxWidth: CONTROL_BOX_WIDTH_MOBILE_VIEW_PX,
        width: '100%',
      };
    }
    if (isSelfContained) {
      return { ...CONTAINER_DEFAULT_THEME, width: CONTROL_BOX_WIDTH_PX };
    }
    return { height: '100%', overflow: 'scroll', paddingX: 3, width: '100%' };
  }, [isSelfContained, uiSize]);

  return (
    <Box {...containerProps} display="flex" flexDirection="column" gap={2} textAlign="center">
      <Typography variant="h6">Theme options</Typography>
      <ThemeOptionBrightnessSliderRow
        ariaLabel="Slider to choose main color brightness levels"
        currentBrightness={colorSchemeState.options.mainBrightness}
        label="Main color brightness"
        tip="Adjust which shades of your palette's main colors (accent, secondary, and other harmony colors) are used in the final theme. Lower is brighter."
        onBrightnessChange={(newBrightness) =>
          updateColorSchemeOptions({ mainBrightness: newBrightness })
        }
      />
      <ThemeOptionBrightnessSliderRow
        ariaLabel="Slider to choose semantic color brightness levels"
        currentBrightness={colorSchemeState.options.semanticBrightness}
        label="Semantic color brightness"
        tip="Adjust which shades of your palette's semantic colors (positive, negative, warning, info, and special) are used in the final theme. Lower is brighter."
        onBrightnessChange={(newBrightness) =>
          updateColorSchemeOptions({ semanticBrightness: newBrightness })
        }
      />
      <ThemeOptionContrastSliderRow
        ariaLabel="Slider to choose contrast levels between main color shades"
        currentContrast={colorSchemeState.options.mainContrast}
        label="Contrast between main colors"
        tip="Adjust the contrast between the applied shades of your palette's main colors (accent, secondary, and other harmony colors)."
        onContrastChange={(newContrast) => updateColorSchemeOptions({ mainContrast: newContrast })}
      />
      <ThemeOptionContrastSliderRow
        ariaLabel="Slider to choose contrast levels between neutral shades"
        currentContrast={colorSchemeState.options.neutralContrast}
        hideMediumOption
        label="Contrast between neutrals"
        tip={`Shift the "lighter" and "darker" variations of your palette's neutral colors closer to or further from the middle shade.`}
        onContrastChange={(newContrast) =>
          updateColorSchemeOptions({ neutralContrast: newContrast })
        }
      />
      <ThemeOptionCheckboxRow
        ariaLabel="Preview your palette in dark mode"
        checked={colorSchemeState.options.tintTextNeutrals}
        label="Tint text with main color"
        tip={`Slightly tint the text and some other foreground neutrals with your main color (${getBaseColorNameLowercase(
          userColorPalette.accent[500],
        )}). The "background neutrals hue" option in the palette color settings menu does not affect text neutrals.`}
        onChange={(checked) => updateColorSchemeOptions({ tintTextNeutrals: checked })}
      />
      <ThemeOptionCheckboxRow
        ariaLabel="Preview your palette in dark mode"
        checked={colorSchemeState.options.darkMode}
        label="Dark mode"
        tip="View your palette in dark mode. You may want to make additional adjustments to your theme's brightness and contrast values for best results in dark mode."
        onChange={(checked) => updateColorSchemeOptions({ darkMode: checked })}
      />
      {paletteBrightnessWarning && (
        <Typography color="warning.main" variant="body1">
          {paletteBrightnessWarning}
        </Typography>
      )}
      <Box
        color="error.main"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop={2}
        marginBottom={1}
      >
        <Button
          aria-label="Reset all theme options to their default settings"
          color="inherit"
          variant="outlined"
          onClick={() => updateColorSchemeOptions(DEFAULT_COLOR_SCHEME_OPTIONS)}
        >
          Restore defaults
        </Button>
      </Box>
    </Box>
  );
}
