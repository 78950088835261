import React, { useCallback, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, ThemeProvider } from '@mui/material';
import { getColorPalette, getRandomHexColor } from './utils/colors/colorUtils';
import { getColorSchemeStateFromURL, useDebounceUpdateURL } from './utils/url';
import { getColorPaletteForUI, getMUIThemeForUI } from './ui/uiColors';
import { DEFAULT_SETTINGS, Settings } from './utils/settings';
import { UserPaletteContext } from './utils/userPaletteContext';
import {
  ColorSchemeState,
  DEFAULT_COLOR_HARMONY_SETTINGS,
  DEFAULT_COLOR_SCHEME_OPTIONS,
  getValidatedColorSchemeState,
} from './utils/colors/colorSchemeState';
import useResponsiveUIBreakpoints from './utils/useResponsiveUIBreakpoints';
import { TYPOGRAPHY_GLOBAL_FONT_SIZES } from './utils/muiTheme';
import {
  AppUIContext,
  SCREENS,
  SCREEN_NAMES,
  ScreenName,
  getScreenNameFromPath,
} from './utils/appUIContext';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentScreen = useMemo(
    () => getScreenNameFromPath(location.pathname),
    [location.pathname],
  );

  const { isMobileDevice, uiSize, windowWidth } = useResponsiveUIBreakpoints();

  // Memoize this because on some browsers (e.g. Safari), whenever the window is resized,
  // all state initializations are called again. Those that deal with getting state from
  // URL also sanitize the URL, causing history to be updated too fast beyond the allowed limit.
  const startingColorSchemeState = useMemo(() => getColorSchemeStateFromURL(), []);

  // User color palette state values:
  const [userColorSchemeState, setUserColorSchemeState] = useState<ColorSchemeState>(
    startingColorSchemeState ?? {
      mainColorHex: getRandomHexColor({ suitableBaseColorsOnly: true }),
      colorHarmony: DEFAULT_COLOR_HARMONY_SETTINGS,
      options: DEFAULT_COLOR_SCHEME_OPTIONS,
    },
  );

  useDebounceUpdateURL(currentScreen, userColorSchemeState);

  const userColorPalette = useMemo(
    () => getColorPalette(userColorSchemeState),
    [userColorSchemeState],
  );

  const updateUserColorSchemeState = useCallback(
    (updates: Partial<ColorSchemeState>) =>
      setUserColorSchemeState((prevState) =>
        getValidatedColorSchemeState({ ...prevState, ...updates }),
      ),
    [],
  );

  // UI state values:
  const [settings, setSettings] = useState<Settings>(DEFAULT_SETTINGS);
  const uiTheme = useMemo(
    () =>
      getMUIThemeForUI(
        settings,
        userColorPalette,
        userColorSchemeState.options,
        uiSize === 'smallMobile'
          ? TYPOGRAPHY_GLOBAL_FONT_SIZES.MOBILE
          : TYPOGRAPHY_GLOBAL_FONT_SIZES.WEB,
      ),
    [settings, uiSize, userColorPalette, userColorSchemeState.options],
  );
  const uiColorPalette = useMemo(
    () => getColorPaletteForUI(settings, userColorSchemeState),
    [userColorSchemeState, settings],
  );

  const pageTitle = useMemo(() => {
    switch (currentScreen) {
      case 'Palette':
        return `AppSchemer - ${SCREENS['Palette'].title}`;
      case 'Color':
        return `AppSchemer - ${SCREENS['Color'].title}`;
      case 'Harmony':
        return `AppSchemer - ${SCREENS['Harmony'].title}`;
      default:
        return 'AppSchemer';
    }
  }, [currentScreen]);

  const updateSettings = useCallback(
    (updates: Partial<Settings>) =>
      setSettings((prevSettings) => ({ ...prevSettings, ...updates })),
    [],
  );

  const goToScreen = useCallback(
    (screenName: ScreenName, flowMode = false) =>
      navigate(SCREENS[screenName].route, { state: { flowMode } }),
    [navigate],
  );

  return (
    <AppUIContext.Provider
      value={{
        currentScreen,
        responsiveBreakpoints: {
          isMobileDevice,
          uiSize,
          windowWidth,
        },
        settings,
        uiColorPalette,
        uiTheme,
        onGoToScreen: goToScreen,
        onSettingsChanged: updateSettings,
      }}
    >
      <UserPaletteContext.Provider
        value={{
          colorPalette: userColorPalette,
          colorSchemeState: userColorSchemeState,
          onColorSchemeStateChanged: updateUserColorSchemeState,
        }}
      >
        <ThemeProvider theme={uiTheme}>
          <HelmetProvider>
            <Helmet>
              <title>{pageTitle}</title>
              <link rel="canonical" href="https://www.appschemer.com" />
              <meta charSet="utf-8" />
              <meta
                name="description"
                content="Create beautiful custom color palettes for your UI in minutes with AppSchemer - a tool for UI designers and developers."
                // content="Create beautiful custom color palettes for your UI with AppSchemer - a tool for UI designers and frontend developers. AppSchemer automates color theory and guides you through the whole process. Preview your palette in real time, switch to dark mode, and save your results in one click."
              />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <meta property="og:title" content="AppSchemer" />
              <meta
                property="og:description"
                content="Create beautiful custom color palettes for your UI in minutes with AppSchemer - a tool for UI designers and developers."
              />
              <meta property="og:image" content="https://www.appschemer.com/logo192.png" />
              <meta property="og:url" content="https://www.appschemer.com" />
              <meta property="og:type" content="website" />
              {/* <meta name="color-scheme" content="dark light" /> */}
              {/* <meta name="author" content="William Shakespeare" /> */}
            </Helmet>
          </HelmetProvider>
          <Box display="flex" justifyContent="center" overflow="hidden">
            <Routes>
              {SCREEN_NAMES.map((screenName) => {
                return (
                  <Route
                    key={screenName}
                    element={React.createElement(SCREENS[screenName].component)}
                    path={SCREENS[screenName].route}
                  />
                );
              })}
            </Routes>
          </Box>
        </ThemeProvider>
      </UserPaletteContext.Provider>
    </AppUIContext.Provider>
  );
}

export default App;
