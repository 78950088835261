import { Box, Link, Typography } from '@mui/material';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import { AppPreviewContext } from '../appPreviewContext';
import { useContext } from 'react';
import { getSecondaryColorOrNextBestOption } from '../../utils/colors/colorUtils';

function SidebarLinkRow({ title }: { title: string }) {
  return (
    <Typography color="inherit" fontWeight={600} variant="body1">
      <Link color="inherit" style={{ cursor: 'pointer', textDecoration: 'none' }}>
        {title}
      </Link>
    </Typography>
  );
}

export default function CityBoostWebsite() {
  const { colorPalette, isDarkMode, getScaledValue } = useContext(AppPreviewContext);

  const { mainColor: mainColorVariations, neutrals: neutralVariations } =
    colorPalette.variationValues;
  return (
    <Box display="flex" flexDirection="row" height="100%">
      <Box
        bgcolor={getSecondaryColorOrNextBestOption(colorPalette)[mainColorVariations.darker]}
        color={colorPalette.text[neutralVariations.light2]}
        display="flex"
        flexDirection="column"
        gap={getScaledValue(1)}
        width={getScaledValue(250)}
      >
        <Box
          color={colorPalette.referencePalette.main[mainColorVariations.lighter]}
          marginBottom={getScaledValue(2)}
          marginTop={getScaledValue(3)}
        >
          <VaccinesOutlinedIcon color="inherit" style={{ fontSize: getScaledValue(80) }} />
        </Box>
        <SidebarLinkRow title="Vaccine Information" />
        <SidebarLinkRow title="Diseases" />
        <SidebarLinkRow title="City Mandates" />
        <SidebarLinkRow title="Travel" />
        <SidebarLinkRow title="Kids" />
        <SidebarLinkRow title="About The Program" />
        <Box color={colorPalette.accent[mainColorVariations.lighter]}>
          <SidebarLinkRow title="Find A Vaccine" />
        </Box>
      </Box>
      <Box
        bgcolor={
          colorPalette.background[isDarkMode ? neutralVariations.dark1 : neutralVariations.light1]
        }
        width="100%"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding={getScaledValue(2)}
        >
          <Typography textAlign="left" variant="h4">
            CityBoost New York
          </Typography>
          <Box
            bgcolor={isDarkMode ? colorPalette.background[neutralVariations.light2] : undefined}
            borderRadius={getScaledValue(1)}
            position="relative"
          >
            <Box
              component="img"
              src="https://upload.wikimedia.org/wikipedia/commons/4/41/NYC_Skyline_Silhouette.png?20140120215533"
              width={getScaledValue(200)}
              paddingX={getScaledValue(0.5)}
            />
            <Typography
              color={
                colorPalette.text[isDarkMode ? neutralVariations.darker : neutralVariations.dark1]
              }
              fontWeight="bold"
              position="absolute"
              textAlign="center"
              top={0}
              variant="h6"
              width="100%"
            >
              New York City
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding={getScaledValue(2)}
        >
          <Box
            component="img"
            height={getScaledValue(200)}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/SARS-CoV-2_without_background.png/239px-SARS-CoV-2_without_background.png"
            width={getScaledValue(200)}
          />
          <Box marginLeft={getScaledValue(5)} textAlign="left" width="100%">
            <Typography variant="h6">CityBoost Disease Response</Typography>
            <Typography marginTop={getScaledValue(1)} variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <Typography marginTop={getScaledValue(1)} variant="body2">
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </Typography>
          </Box>
        </Box>
        <Typography
          marginLeft={getScaledValue(2)}
          marginRight={getScaledValue(2)}
          marginTop={getScaledValue(1)}
          textAlign="left"
          variant="body2"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Vulputate odio ut enim blandit. Ullamcorper sit amet
          risus nullam eget. Tortor posuere ac ut consequat. Enim tortor at auctor urna nunc id.
          Turpis massa sed elementum tempus. Tortor condimentum lacinia quis vel eros donec ac odio.
          Augue eget arcu dictum varius duis at consectetur lorem. Dignissim sodales ut eu sem.
          Risus sed vulputate odio ut enim blandit volutpat. Ipsum suspendisse ultrices gravida
          dictum fusce ut placerat orci. Maecenas pharetra convallis posuere morbi leo urna molestie
          at elementum. Praesent elementum facilisis leo vel. Gravida rutrum quisque non tellus orci
          ac auctor. Sit amet nisl suscipit adipiscing bibendum. Phasellus egestas tellus rutrum
          tellus pellentesque eu tincidunt. Volutpat ac tincidunt vitae semper quis lectus nulla at
          volutpat.
        </Typography>
      </Box>
    </Box>
  );
}
