import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import { ColorPalette } from '../../utils/colors/colorPalette';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import * as MUIIcon from '@mui/icons-material';
import { getRandomHexColor, isColorDark } from '../../utils/colors/colorUtils';
import { useContext, useMemo } from 'react';
import { getRandomElementFromArray } from '../../utils/utils';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { AppPreviewContext } from '../appPreviewContext';

const PROFILE_PICTURE_ICONS: readonly (keyof typeof MUIIcon)[] = [
  'AccountCircleOutlined',
  'Person2Outlined',
  'Person3Outlined',
  'Person4Outlined',
  'FaceOutlined',
  'Face2Outlined',
  'Face3Outlined',
  'Face4Outlined',
  'Face5Outlined',
  'Face6Outlined',
  'InsertEmoticonOutlined',
  'PeopleAltOutlined',
  'PermIdentityOutlined',
  'SentimentSatisfiedAltOutlined',
  'SupervisorAccountOutlined',
] as const;

function AppsRow({
  getScaledValue,
  icon,
  title,
}: {
  getScaledValue: (value: number) => number;
  icon: keyof typeof MUIIcon;
  title: string;
}) {
  const Icon = MUIIcon[icon];
  return (
    <Box display="flex" flexDirection="row" gap={getScaledValue(1)}>
      <Icon color="inherit" fontSize="medium" />
      <Typography fontWeight="bold" variant="body1">
        <Link color="inherit" style={{ cursor: 'pointer', textDecoration: 'none' }}>
          {title}
        </Link>
      </Typography>
    </Box>
  );
}

function ContactsRow({
  getScaledValue,
  name,
}: {
  getScaledValue: (value: number) => number;
  name: string;
}) {
  const profilePictureIconName = useMemo(
    () => getRandomElementFromArray(PROFILE_PICTURE_ICONS),
    [],
  );

  const ProfilePictureIcon = MUIIcon[profilePictureIconName];
  return (
    <Box alignItems="center" display="flex" flexDirection="row" gap={getScaledValue(1)}>
      <ProfilePictureIcon color="inherit" fontSize="medium" />
      <Typography variant="body2">
        <Link color="inherit" style={{ cursor: 'pointer', textDecoration: 'none' }}>
          {name}
        </Link>
      </Typography>
    </Box>
  );
}

const STORY_CARD_WIDTH_PX = 80;
const STORY_CARD_HEIGHT_PX = 120;
const STORY_CARD_BORDER_RADIUS_PX = 2;
function StoryCard({
  colorPalette,
  getScaledValue,
  imageURL,
  userName,
}: {
  colorPalette: ColorPalette;
  getScaledValue: (value: number) => number;
  imageURL?: string;
  userName: string;
}) {
  const { neutrals: neutralVariations } = colorPalette.variationValues;

  const { profileBackgroundColor, profilePictureIconName } = useMemo(
    () => ({
      profileBackgroundColor: getRandomHexColor(),
      profilePictureIconName: getRandomElementFromArray(PROFILE_PICTURE_ICONS),
    }),
    [],
  );

  const storyImage = useMemo(
    () => (
      <Box
        alt="Randomly generated user story preview image"
        borderRadius={getScaledValue(STORY_CARD_BORDER_RADIUS_PX)}
        component="img"
        height="100%"
        src={imageURL ?? `https://picsum.photos/${STORY_CARD_WIDTH_PX}/${STORY_CARD_HEIGHT_PX}`}
        width="100%"
      />
    ),
    [getScaledValue, imageURL],
  );

  const lightTextColor = colorPalette.text[neutralVariations.light1];
  const darkTextColor = colorPalette.text[neutralVariations.dark1];
  const ProfilePictureIcon = MUIIcon[profilePictureIconName];
  return (
    <Box
      borderRadius={getScaledValue(STORY_CARD_BORDER_RADIUS_PX)}
      boxShadow={getScaledValue(2)}
      height={getScaledValue(STORY_CARD_HEIGHT_PX)}
      position="relative"
      width={getScaledValue(STORY_CARD_WIDTH_PX)}
    >
      <Box
        alignItems="center"
        bgcolor={profileBackgroundColor}
        borderRadius="50%"
        color={isColorDark(profileBackgroundColor) ? lightTextColor : darkTextColor}
        display="flex"
        height={getScaledValue(30)}
        justifyContent="center"
        left={getScaledValue(5)}
        position="absolute"
        top={getScaledValue(5)}
        width={getScaledValue(30)}
      >
        <ProfilePictureIcon color="inherit" fontSize="medium" />
      </Box>
      {storyImage}
      <Typography
        bottom={getScaledValue(5)}
        color={darkTextColor}
        left={getScaledValue(10)}
        position="absolute"
        textAlign="left"
        variant="caption"
        style={{
          textShadow: `0 0 ${getScaledValue(4)}px ${lightTextColor}, 0 0 ${getScaledValue(
            3,
          )}px ${lightTextColor}, 0 0 ${getScaledValue(2)}px ${lightTextColor}`,
        }}
      >
        {userName}
      </Typography>
    </Box>
  );
}

export default function SocialMediaWebsite() {
  const { colorPalette, isDarkMode, getScaledValue } = useContext(AppPreviewContext);

  const { mainColor: mainColorVariations, neutrals: neutralVariations } =
    colorPalette.variationValues;

  const postAvatarColor = useMemo(() => getRandomHexColor(), []);

  return (
    <Box
      bgcolor={
        isDarkMode
          ? colorPalette.background[neutralVariations.dark2]
          : colorPalette.background[neutralVariations.light1]
      }
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        alignItems="center"
        bgcolor={
          isDarkMode
            ? colorPalette.background[neutralVariations.dark1]
            : colorPalette.background[neutralVariations.light2]
        }
        boxShadow={getScaledValue(1)}
        display="flex"
        flexDirection="row"
        height={getScaledValue(65)}
        justifyContent="space-between"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <Box
            color={colorPalette.accent[mainColorVariations.main]}
            fontSize={getScaledValue(35)}
            marginX={getScaledValue(2)}
            paddingTop={getScaledValue(1)}
          >
            <Diversity2Icon color="inherit" fontSize="inherit" />
          </Box>
          <Box
            alignItems="center"
            bgcolor={
              isDarkMode
                ? colorPalette.background[neutralVariations.lighter]
                : colorPalette.background[neutralVariations.light2]
            }
            color={
              isDarkMode
                ? colorPalette.text[neutralVariations.dark1]
                : colorPalette.text[neutralVariations.dark2]
            }
            border={getScaledValue(1)}
            borderColor={colorPalette.divider[neutralVariations.lighter]}
            borderRadius={getScaledValue(5)}
            display="flex"
            flexDirection="row"
            height={getScaledValue(35)}
            paddingLeft={getScaledValue(2)}
            gap={getScaledValue(1)}
            width={getScaledValue(250)}
          >
            <SearchOutlinedIcon color="inherit" fontSize="medium" />
            <Typography color="inherit" variant="body1">
              Search
            </Typography>
          </Box>
        </Box>
        <Box
          alignItems="center"
          color={
            colorPalette.icon[isDarkMode ? mainColorVariations.lighter : mainColorVariations.darker]
          }
          display="flex"
          flexDirection="row"
          gap={getScaledValue(1)}
          marginRight={getScaledValue(2)}
        >
          <AdminPanelSettingsIcon color="inherit" fontSize="large" />
          <SettingsIcon color="inherit" fontSize="large" />
          <AccountCircleIcon color="inherit" fontSize="large" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" overflow="hidden" padding={getScaledValue(2)}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" width="25%">
          <Box
            color={
              colorPalette.accent[
                isDarkMode ? mainColorVariations.lighter : mainColorVariations.darker
              ]
            }
            display="flex"
            flexDirection="column"
            gap={getScaledValue(2)}
            textAlign="left"
          >
            <Typography fontWeight="bold" variant="h6">
              Your apps
            </Typography>
            <AppsRow getScaledValue={getScaledValue} icon="PeopleOutline" title="Friends" />
            <AppsRow getScaledValue={getScaledValue} icon="Groups2" title="Groups" />
            <AppsRow getScaledValue={getScaledValue} icon="EventAvailable" title="Events" />
            <AppsRow getScaledValue={getScaledValue} icon="VolunteerActivism" title="Charity" />
            <AppsRow getScaledValue={getScaledValue} icon="Favorite" title="Memories" />
            <AppsRow getScaledValue={getScaledValue} icon="OndemandVideo" title="Movies & Videos" />
            <AppsRow getScaledValue={getScaledValue} icon="InsertPhoto" title="Photos" />
            <AppsRow getScaledValue={getScaledValue} icon="Bookmarks" title="Saved Posts" />
            <AppsRow getScaledValue={getScaledValue} icon="Settings" title="Settings & Privacy" />
            <AppsRow getScaledValue={getScaledValue} icon="Interests" title="See more" />
          </Box>
          <Typography variant="caption">
            Privacy &middot; Terms of Service &middot; Advertising &middot; Careers
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="left" width="55%">
          <Typography
            textAlign="left"
            fontWeight="bold"
            marginBottom={getScaledValue(1)}
            width="100%"
          >
            Stories
          </Typography>
          <Box display="flex" flexDirection="row" gap={getScaledValue(1.5)} width="100%">
            <Box alignItems="center" display="flex" flexDirection="row" height="100%">
              <IconButton aria-label="Example add story icon button with your theme">
                <AddCircleOutlinedIcon color="primary" fontSize="large" />
              </IconButton>
            </Box>
            <StoryCard
              colorPalette={colorPalette}
              getScaledValue={getScaledValue}
              imageURL="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Dolly_Parton_at_%27Blue_Smoke_World_Tour%27_in_Knoxville.jpg/180px-Dolly_Parton_at_%27Blue_Smoke_World_Tour%27_in_Knoxville.jpg"
              userName="Dolly Parton"
            />
            <StoryCard
              colorPalette={colorPalette}
              getScaledValue={getScaledValue}
              imageURL="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Justin_Timberlake_Cannes_2016_-_2.jpg/180px-Justin_Timberlake_Cannes_2016_-_2.jpg"
              userName="Justin Timberlake"
            />
            <StoryCard
              colorPalette={colorPalette}
              getScaledValue={getScaledValue}
              imageURL="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Taylor_Swift_-_Red_Tour_18.jpg/160px-Taylor_Swift_-_Red_Tour_18.jpg"
              userName="T. Swift"
            />
            <StoryCard
              colorPalette={colorPalette}
              getScaledValue={getScaledValue}
              userName="Jennifer Coolidge"
            />
          </Box>
          <Box
            alignItems="center"
            borderRadius={getScaledValue(2)}
            boxShadow={getScaledValue(2)}
            color={colorPalette.accent[mainColorVariations.main]}
            display="flex"
            flexDirection="row"
            marginBottom={getScaledValue(5)}
            marginTop={getScaledValue(5)}
            padding={getScaledValue(1)}
            width="75%"
          >
            <AccountCircleOutlinedIcon
              color="inherit"
              fontSize="large"
              style={{ marginRight: getScaledValue(8) }}
            />
            <Button
              aria-label="Example create post button with your theme"
              color="inherit"
              fullWidth
              size="small"
              variant="outlined"
            >
              Create a new post
            </Button>
            <IconButton
              aria-label="Example add image icon button with your theme"
              style={{ marginLeft: getScaledValue(8) }}
            >
              <ImageOutlinedIcon color="inherit" fontSize="medium" />
            </IconButton>
          </Box>
          <Box
            alignItems="center"
            border={getScaledValue(0.5)}
            borderColor={
              colorPalette.divider[isDarkMode ? neutralVariations.darker : neutralVariations.light1]
            }
            borderRadius={getScaledValue(2)}
            boxShadow={getScaledValue(2)}
            display="flex"
            flexDirection="column"
            padding={getScaledValue(1)}
            width="90%"
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Box alignItems="center" color={postAvatarColor} display="flex" flexDirection="row">
                <AccountCircleOutlinedIcon color="inherit" fontSize="large" />
                <Box marginLeft={getScaledValue(1)}>
                  <Typography fontWeight="bold" variant="body2">
                    John Smith
                  </Typography>
                  <Typography variant="caption">15 min</Typography>
                </Box>
              </Box>
              <IconButton aria-label="Example more menu button with your theme">
                <MoreHorizOutlinedIcon color="inherit" />
              </IconButton>
            </Box>
            <Box marginTop={getScaledValue(1)} width="100%">
              <Typography textAlign="left" variant="body2">
                I had a great time today! 🎉
              </Typography>
              <Box
                borderRadius={getScaledValue(2)}
                component="img"
                marginTop={getScaledValue(1)}
                src="https://upload.wikimedia.org/wikipedia/commons/c/c3/HLR-FOAM-PARTY-02.JPG"
                width="100%"
              />
            </Box>
          </Box>
        </Box>
        <Box width="20%">
          <Box
            color={
              colorPalette.accent[
                isDarkMode ? mainColorVariations.lighter : mainColorVariations.darker
              ]
            }
            display="flex"
            flexDirection="column"
            marginLeft={getScaledValue(2)}
            gap={getScaledValue(1)}
            textAlign="left"
          >
            <Typography fontWeight="bold" marginBottom={getScaledValue(1)} variant="h6">
              Contacts
            </Typography>
            <ContactsRow getScaledValue={getScaledValue} name="Billie Eilish" />
            <ContactsRow getScaledValue={getScaledValue} name="The Weeknd" />
            <ContactsRow getScaledValue={getScaledValue} name="Miley Cyrus" />
            <ContactsRow getScaledValue={getScaledValue} name="Jennifer Lopez" />
            <ContactsRow getScaledValue={getScaledValue} name="Harry Styles" />
            <ContactsRow getScaledValue={getScaledValue} name="Megan Thee Stallion" />
            <ContactsRow getScaledValue={getScaledValue} name="Justin Bieber" />
            <ContactsRow getScaledValue={getScaledValue} name="Dua Lipa" />
            <ContactsRow getScaledValue={getScaledValue} name="Ariana Grande" />
            <ContactsRow getScaledValue={getScaledValue} name="Jenna Ortega" />
            <ContactsRow getScaledValue={getScaledValue} name="Pedro Pascal" />
            <ContactsRow getScaledValue={getScaledValue} name="Ed Sheeran" />
            <ContactsRow getScaledValue={getScaledValue} name="Lady Gaga" />
            <ContactsRow getScaledValue={getScaledValue} name="Tom Holland" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
