import { useContext, useMemo, useState } from 'react';
import { Box, Button, ThemeProvider } from '@mui/material';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import { UserPaletteContext } from '../../../utils/userPaletteContext';
import { CONTAINER_USER_THEME } from '../../../ui/uiStyles';
import { AppUIContext } from '../../../utils/appUIContext';
import ColorPaletteOverviewRow, { OverviewRowColorInfo } from './ColorPaletteOverviewRow';
import {
  COLOR_VARIATION_OPTIONS,
  getLabelMapForVariations,
} from '../../../utils/colors/colorPalette';
import FullPaletteDialog from './fullColorPaletteDialog/FullPaletteDialog';
import { getMUIThemeFromColorPalette } from '../../../utils/muiTheme';

const MARGIN_BETWEEN_ROWS = 2;

interface Props {
  isFullWidth?: boolean;
}

export default function ColorPaletteOverview(props: Props) {
  const { isFullWidth } = props;

  const { colorPalette: userColorPalette, colorSchemeState } = useContext(UserPaletteContext);
  const {
    responsiveBreakpoints: { uiSize },
  } = useContext(AppUIContext);

  const [isFullPaletteDialogOpen, setIsFullPaletteDialogOpen] = useState(false);

  const {
    mainColor: mainColorVariations,
    neutrals: neutralVariations,
    semantic: semanticVariations,
  } = userColorPalette.variationValues;
  const { darkMode } = colorSchemeState.options;

  const userTheme = useMemo(
    () => getMUIThemeFromColorPalette(userColorPalette, colorSchemeState.options),
    [colorSchemeState.options, userColorPalette],
  );

  const colorGroupSets = useMemo(() => {
    const mainColorVariationsMap = getLabelMapForVariations(mainColorVariations);
    const neutralColorVariationsMap = getLabelMapForVariations(
      userColorPalette.variationValues.neutrals,
    );
    const accentColorInfos: OverviewRowColorInfo[] = [];
    const secondaryColorInfos: OverviewRowColorInfo[] = [];
    const textNeutralsColorInfos: OverviewRowColorInfo[] = [];
    const backgroundNeutralsColorInfos: OverviewRowColorInfo[] = [];
    COLOR_VARIATION_OPTIONS.forEach((variation) => {
      // Main colors:
      const mainVariationInfo = mainColorVariationsMap.get(variation);
      const mainColorLabel = mainVariationInfo
        ? `${mainVariationInfo.label} (${variation})`
        : variation.toString();
      accentColorInfos.push({
        isLabelBold: !!mainVariationInfo,
        hexValue: userColorPalette.accent[variation],
        label: mainColorLabel,
      });
      secondaryColorInfos.push({
        isDisabled: !userColorPalette.secondary,
        isLabelBold: userColorPalette.secondary && !!mainVariationInfo,
        hexValue:
          userColorPalette.secondary?.[variation] ??
          userColorPalette.disabled[userColorPalette.variationValues.neutrals.main],
        label: userColorPalette.secondary ? mainColorLabel : variation.toString(),
      });
      // Neutrals:
      const neutralVariationInfo = neutralColorVariationsMap.get(variation);
      const neutralColorLabel = neutralVariationInfo
        ? `${neutralVariationInfo.label} (${variation})`
        : variation.toString();
      textNeutralsColorInfos.push({
        isLabelBold: !!neutralVariationInfo,
        hexValue: userColorPalette.text[variation],
        label: neutralColorLabel,
      });
      backgroundNeutralsColorInfos.push({
        isLabelBold: !!neutralVariationInfo,
        hexValue: userColorPalette.background[variation],
        label: neutralColorLabel,
      });
    });
    return {
      accent: [accentColorInfos],
      secondary: [secondaryColorInfos],
      text: [textNeutralsColorInfos],
      background: [backgroundNeutralsColorInfos],
      otherHarmonyColors: userColorPalette.otherHarmonyColors.map((colorWithVariations, index) => [
        {
          hexValue: colorWithVariations[mainColorVariations.main],
          label: `Harmony ${index + 1}`,
        },
      ]),
    };
  }, [mainColorVariations, userColorPalette]);

  return (
    <>
      <FullPaletteDialog
        isMovable
        isOpen={isFullPaletteDialogOpen}
        onClose={() => setIsFullPaletteDialogOpen(false)}
      />
      <ThemeProvider theme={userTheme}>
        <Box
          {...CONTAINER_USER_THEME}
          alignItems="center"
          bgcolor={
            userColorPalette.background[
              darkMode ? neutralVariations.dark2 : neutralVariations.light1
            ]
          }
          display="flex"
          flexDirection="column"
          width={isFullWidth || uiSize === 'smallMobile' ? '100%' : undefined}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
            marginBottom={3}
            width="100%"
          >
            <ColorPaletteOverviewRow
              colorGroups={colorGroupSets.accent}
              marginBottom={MARGIN_BETWEEN_ROWS}
              showWarningIfColorIsTooLightOrDark
              title="Accent"
            />
            <ColorPaletteOverviewRow
              colorGroups={colorGroupSets.secondary}
              disabledMessage="Secondary color not selected. You can choose a secondary color from the palette color settings menu."
              marginBottom={MARGIN_BETWEEN_ROWS}
              showWarningIfColorIsTooLightOrDark
              title="Secondary"
            />
            <ColorPaletteOverviewRow
              colorGroups={colorGroupSets.text}
              marginBottom={MARGIN_BETWEEN_ROWS}
              title="Text neutrals"
            />
            <ColorPaletteOverviewRow
              colorGroups={colorGroupSets.background}
              marginBottom={MARGIN_BETWEEN_ROWS}
              title="Background neutrals"
            />
            <ColorPaletteOverviewRow
              colorGroups={[
                [
                  {
                    hexValue: userColorPalette.semantic.positive[semanticVariations.main],
                    label: 'Positive',
                  },
                  {
                    hexValue: userColorPalette.semantic.negative[semanticVariations.main],
                    label: 'Negative',
                  },
                  {
                    hexValue: userColorPalette.semantic.warning[semanticVariations.main],
                    label: 'Warning',
                  },
                  {
                    hexValue: userColorPalette.semantic.info[semanticVariations.main],
                    label: 'Info',
                  },
                  {
                    hexValue: userColorPalette.semantic.special[semanticVariations.main],
                    label: 'Special',
                  },
                ],
                ...colorGroupSets.otherHarmonyColors,
              ]}
              showWarningIfColorIsTooLightOrDark
              title="Semantic and other harmony colors"
            />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" width="100%">
            <Button
              aria-label="Open a dialog to view the full palette, including all colors and variations"
              endIcon={<PaletteOutlinedIcon />}
              onClick={() => setIsFullPaletteDialogOpen(true)}
            >
              View full palette
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
