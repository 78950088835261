import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { UserPaletteContext } from '../utils/userPaletteContext';
import { HEADER_FOOTER_HEIGHT_PX } from '../ui/uiSizes';
import { isDarkModeEnabled } from '../utils/settings';
import { AppUIContext } from '../utils/appUIContext';

interface FooterButtonProps {
  ariaLabel: string;
  isDisabled?: boolean;
  label: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface AppFooterProps {
  footerButtonProps?: FooterButtonProps | [FooterButtonProps, FooterButtonProps];
}

export default function AppFooter(props: AppFooterProps) {
  const { footerButtonProps } = props;

  const {
    responsiveBreakpoints: { uiSize },
    settings,
    uiColorPalette,
  } = useContext(AppUIContext);
  const { colorSchemeState: userColorSchemeState } = useContext(UserPaletteContext);

  const isDarkMode = isDarkModeEnabled(settings, userColorSchemeState.options);
  const { neutrals: neutralVariationValues } = uiColorPalette.variationValues;

  if (!footerButtonProps) {
    return null;
  }

  const buttonPropsAsArray = Array.isArray(footerButtonProps)
    ? footerButtonProps
    : [footerButtonProps];
  const firstButtonProps = buttonPropsAsArray[0];
  const secondButtonProps = buttonPropsAsArray.length > 1 ? buttonPropsAsArray[1] : null;

  return (
    <Box
      bgcolor={
        uiColorPalette.background[
          isDarkMode ? neutralVariationValues.darker : neutralVariationValues.light2
        ]
      }
      display="flex"
      flexDirection="row"
      justifyContent="center"
      height={HEADER_FOOTER_HEIGHT_PX}
      width="100%"
      position="fixed"
      bottom={0}
      zIndex={1000}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent={uiSize === 'smallMobile' ? 'center' : 'flex-end'}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={3}
          marginRight={uiSize === 'smallMobile' ? 0 : 10}
        >
          {secondButtonProps && (
            <Button
              aria-label={secondButtonProps.ariaLabel}
              disabled={secondButtonProps.isDisabled}
              variant="outlined"
              onClick={secondButtonProps.onClick}
            >
              {secondButtonProps.label}
            </Button>
          )}
          {firstButtonProps && (
            <Button
              aria-label={firstButtonProps.ariaLabel}
              disabled={firstButtonProps.isDisabled}
              variant="contained"
              onClick={firstButtonProps.onClick}
            >
              {firstButtonProps.label}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
