import { useMemo } from 'react';
import {
  PALETTE_CONTROL_BOX_SELECT_DROPDOWN_DIALOG_WIDTH_PX,
  PALETTE_CONTROL_BOX_SELECT_DROPDOWN_WIDTH_PX,
} from './ColorSelectorDropdown';

export default function useControlBoxDropdownSelectorWidth(
  isDialog: boolean | undefined,
  isMobileView: boolean | undefined,
) {
  const width = useMemo(() => {
    if (isMobileView) {
      return '100%';
    }
    if (isDialog) {
      return PALETTE_CONTROL_BOX_SELECT_DROPDOWN_DIALOG_WIDTH_PX;
    }
    return PALETTE_CONTROL_BOX_SELECT_DROPDOWN_WIDTH_PX;
  }, [isDialog, isMobileView]);

  return { width };
}
