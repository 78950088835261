import { getCurrentURL } from '../utils/url';

export const BETA_FEEDBACK_FORM_URL = 'https://forms.gle/GgELuR3ZSN2YuhWk8';

const SHARED_POST_TITLE = 'AppSchemer color palette';
const SHARED_POST_TEXT = 'Check out this awesome UI color palette I made with AppSchemer!';

function encodeQueryParams(params: Record<string, string>) {
  const encodedParams: string[] = [];
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      encodedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });
  if (encodedParams.length === 0) {
    return '';
  }
  return `?${encodedParams.join('&')}`;
}

export function getShareToFacebookLink() {
  const queryParams = encodeQueryParams({ u: getCurrentURL(), quote: SHARED_POST_TEXT });
  return `https://www.facebook.com/sharer/sharer.php${queryParams}`;
}

export function getShareToTwitterLink() {
  const queryParams = encodeQueryParams({ text: `${SHARED_POST_TEXT} ${getCurrentURL()}` });
  return `https://twitter.com/intent/tweet${queryParams}`;
}

export function getShareToLinkedInLink() {
  const queryParams = encodeQueryParams({
    url: getCurrentURL(),
    mini: 'true',
    title: SHARED_POST_TITLE,
    summary: SHARED_POST_TEXT,
  });
  return `https://www.linkedin.com/shareArticle${queryParams}`;
}

export function getShareToEmailLink() {
  const queryParams = encodeQueryParams({
    subject: 'AppSchemer color palette',
    body: `${SHARED_POST_TEXT} ${getCurrentURL()}`,
  });
  return `mailto:${queryParams}`;
}
