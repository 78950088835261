import { useCallback, useContext, useEffect, useRef } from 'react';
import {
  COLOR_HARMONY_OPTIONS,
  ColorHarmonyOption,
  ColorHarmonySettings,
  ColorSchemeOptions,
  ColorSchemeState,
  ContrastOption,
  DEFAULT_COLOR_HARMONY_SETTINGS,
  DEFAULT_COLOR_SCHEME_OPTIONS,
  OPTION_NOT_SELECTED,
} from './colors/colorSchemeState';
import { isColorValid } from './colors/colorUtils';
import { AppUIContext, ScreenName } from './appUIContext';

const URL_PARAM_MAIN_COLOR = 'mainColor';
const URL_PARAM_COLOR_HARMONY_COLOR = 'colorHarmony';
const URL_PARAM_OPTIONS = 'options';

const NUM_COLOR_HARMONY_SETTINGS_VARS = Object.keys(DEFAULT_COLOR_HARMONY_SETTINGS).length - 1; // subtract 1 for the harmony itself
const NUM_PALETTE_OPTIONS_VARS = Object.keys(DEFAULT_COLOR_SCHEME_OPTIONS).length;

const UPDATE_URL_DEBOUNCE_TIME_MS = 500;
const UPDATE_URL_DEBOUNCE_TIME_MOBILE_MS = 1000;

function encodeContrastOption(input: ContrastOption): '0' | '1' | '2' {
  switch (input) {
    case 'low':
      return '0';
    case 'normal':
      return '1';
    case 'high':
      return '2';
  }
}

function decodeContrastOption(input: string): ContrastOption {
  switch (input) {
    case '0':
      return 'low';
    case '2':
      return 'high';
    case '1':
    default:
      return 'normal';
  }
}

function encodeColorHarmonySettings(settings: ColorHarmonySettings): string {
  let encodedSettings = '';
  (Object.keys(DEFAULT_COLOR_HARMONY_SETTINGS) as (keyof ColorHarmonySettings)[]).forEach(
    (optionKey) => {
      if (optionKey !== 'selectedHarmony') {
        encodedSettings += settings[optionKey] ?? '0';
      }
    },
  );
  return encodedSettings;
}

function encodeColorSchemeOptions(options: ColorSchemeOptions): string {
  let encodedOptions = '';
  (Object.keys(DEFAULT_COLOR_SCHEME_OPTIONS) as (keyof ColorSchemeOptions)[]).forEach(
    (optionKey) => {
      if (optionKey === 'mainContrast' || optionKey === 'neutralContrast') {
        encodedOptions += encodeContrastOption(options[optionKey]);
      } else if (typeof DEFAULT_COLOR_SCHEME_OPTIONS[optionKey] === 'boolean') {
        encodedOptions += options[optionKey] ? '1' : '0';
      } else {
        encodedOptions += options[optionKey] ?? '0';
      }
    },
  );
  return encodedOptions;
}

function decodeColorHarmonySettings(
  encodedSettings: string,
  colorHarmonyOption: ColorHarmonyOption | undefined,
): ColorHarmonySettings {
  let decodedSettings: Partial<ColorHarmonySettings> = {
    selectedHarmony: colorHarmonyOption,
  };
  let encodedOptionsIndex = 0;
  (Object.keys(DEFAULT_COLOR_HARMONY_SETTINGS) as (keyof ColorHarmonySettings)[]).forEach(
    (optionKey) => {
      if (encodedSettings.length <= encodedOptionsIndex || optionKey === 'selectedHarmony') {
        return;
      }
      const encodedOption = encodedSettings[encodedOptionsIndex++];
      if (encodedOption === OPTION_NOT_SELECTED) {
        decodedSettings = {
          ...decodedSettings,
          [optionKey]: OPTION_NOT_SELECTED,
        };
      } else {
        const encodedOptionAsNumber = parseInt(encodedOption);
        decodedSettings = {
          ...decodedSettings,
          [optionKey]:
            isNaN(encodedOptionAsNumber) || encodedOptionAsNumber < 0 || encodedOptionAsNumber > 9
              ? DEFAULT_COLOR_HARMONY_SETTINGS[optionKey]
              : encodedOptionAsNumber,
        };
      }
    },
  );
  return {
    ...DEFAULT_COLOR_HARMONY_SETTINGS,
    ...decodedSettings,
  };
}

function decodeColorSchemeOptions(encodedOptions: string): ColorSchemeOptions {
  let decodedOptions: Partial<ColorSchemeOptions> = {};
  (Object.keys(DEFAULT_COLOR_SCHEME_OPTIONS) as (keyof ColorSchemeOptions)[]).forEach(
    (optionKey, index) => {
      if (encodedOptions.length <= index) {
        return;
      }
      if (optionKey === 'mainContrast' || optionKey === 'neutralContrast') {
        decodedOptions = {
          ...decodedOptions,
          [optionKey]: decodeContrastOption(encodedOptions[index]),
        };
      } else if (typeof DEFAULT_COLOR_SCHEME_OPTIONS[optionKey] === 'boolean') {
        decodedOptions = {
          ...decodedOptions,
          [optionKey]: encodedOptions[index] === '1',
        };
      } else {
        const encodedValue = parseInt(encodedOptions[index]);
        decodedOptions = {
          ...decodedOptions,
          [optionKey]:
            isNaN(encodedValue) || encodedValue < 0 || encodedValue > 9
              ? DEFAULT_COLOR_SCHEME_OPTIONS[optionKey]
              : encodedValue,
        };
      }
    },
  );
  return {
    ...DEFAULT_COLOR_SCHEME_OPTIONS,
    ...decodedOptions,
  };
}

function getAllStateValuesFromURLAndCleanUpParams(): {
  mainColorHex?: string;
  colorHarmony?: ColorHarmonySettings;
  options?: ColorSchemeOptions;
} {
  const urlParams = new URLSearchParams(window.location.search);

  let mainColorHex = urlParams.get(URL_PARAM_MAIN_COLOR) ?? undefined;
  if (mainColorHex && !isColorValid(mainColorHex)) {
    mainColorHex = undefined; // if it's not valid, don't set it
  }

  let colorHarmonyOption: ColorHarmonyOption | undefined = undefined;
  const colorHarmonyOptionParam = urlParams.get(URL_PARAM_COLOR_HARMONY_COLOR);
  if (
    colorHarmonyOptionParam &&
    (COLOR_HARMONY_OPTIONS as string[]).includes(colorHarmonyOptionParam)
  ) {
    colorHarmonyOption = colorHarmonyOptionParam as ColorHarmonyOption;
  }

  let colorHarmonySettings: ColorHarmonySettings | undefined = undefined;
  let colorSchemeOptions: ColorSchemeOptions | undefined = undefined;
  const colorSchemeOptionsParam = urlParams.get(URL_PARAM_OPTIONS);
  if (
    colorSchemeOptionsParam &&
    colorSchemeOptionsParam.length === NUM_COLOR_HARMONY_SETTINGS_VARS + NUM_PALETTE_OPTIONS_VARS
  ) {
    colorHarmonySettings = decodeColorHarmonySettings(
      colorSchemeOptionsParam.slice(0, NUM_COLOR_HARMONY_SETTINGS_VARS),
      colorHarmonyOption,
    );
    colorSchemeOptions = decodeColorSchemeOptions(
      colorSchemeOptionsParam.slice(NUM_COLOR_HARMONY_SETTINGS_VARS),
    );
  }

  // Clean up all the params, and remove any that aren't relevant to this app:
  Array.from(urlParams.keys()).forEach((param) => {
    let shouldDeleteParam: boolean;
    switch (param) {
      case URL_PARAM_MAIN_COLOR:
        shouldDeleteParam = !mainColorHex || !colorSchemeOptions;
        break;
      case URL_PARAM_COLOR_HARMONY_COLOR:
        shouldDeleteParam = !mainColorHex || !colorHarmonyOption || !colorSchemeOptions;
        break;
      case URL_PARAM_OPTIONS:
        shouldDeleteParam = !mainColorHex || !colorSchemeOptions;
        break;
      default:
        shouldDeleteParam = true;
        break;
    }
    if (shouldDeleteParam) {
      urlParams.delete(param);
    }
  });
  const newQueryString = urlParams.toString();
  if (newQueryString.length > 0) {
    window.history.replaceState({}, '', `?${newQueryString}`);
  } else {
    window.history.replaceState({}, '', '/');
  }

  return {
    mainColorHex,
    colorHarmony: colorHarmonySettings,
    options: colorSchemeOptions,
  };
}

export function getStartingScreenFromURL(): ScreenName {
  const { mainColorHex, colorHarmony, options } = getAllStateValuesFromURLAndCleanUpParams();
  if (mainColorHex && colorHarmony && options) {
    return 'Palette';
  }
  return 'Home';
}

export function getColorSchemeStateFromURL(): ColorSchemeState | null {
  const { mainColorHex, colorHarmony, options } = getAllStateValuesFromURLAndCleanUpParams();
  if (!mainColorHex || !colorHarmony || !options) {
    return null;
  }
  return {
    mainColorHex,
    colorHarmony,
    options,
  };
}

export function getCurrentURL(): string {
  return window.location.href;
}

export function updateURLForColorScheme(colorSchemeState: ColorSchemeState): void {
  const { mainColorHex, colorHarmony, options } = colorSchemeState;
  const queryParams = new URLSearchParams({
    [URL_PARAM_MAIN_COLOR]: mainColorHex,
    [URL_PARAM_COLOR_HARMONY_COLOR]: colorHarmony.selectedHarmony,
    [URL_PARAM_OPTIONS]: `${encodeColorHarmonySettings(colorHarmony)}${encodeColorSchemeOptions(
      options,
    )}`,
  }).toString();
  window.history.replaceState({}, '', `?${queryParams}`);
}

export function useDebounceUpdateURL(
  currentScreen: ScreenName,
  userColorSchemeState: ColorSchemeState,
) {
  const {
    responsiveBreakpoints: { isMobileDevice },
  } = useContext(AppUIContext);

  const debounceUpdateURLTimerRef = useRef<NodeJS.Timeout | null>(null);

  const debounceUpdateURL = useCallback(
    (userColorSchemeState: ColorSchemeState) => {
      if (debounceUpdateURLTimerRef.current) {
        clearTimeout(debounceUpdateURLTimerRef.current);
      }
      debounceUpdateURLTimerRef.current = setTimeout(
        () => updateURLForColorScheme(userColorSchemeState),
        isMobileDevice ? UPDATE_URL_DEBOUNCE_TIME_MOBILE_MS : UPDATE_URL_DEBOUNCE_TIME_MS,
      );
    },
    [isMobileDevice],
  );

  useEffect(() => {
    if (currentScreen !== 'Home') {
      debounceUpdateURL(userColorSchemeState);
    }
  }, [currentScreen, debounceUpdateURL, userColorSchemeState]);
}
