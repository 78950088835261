import { RefObject, useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import ExportPalette from './ExportPalette';

interface Props {
  buttonRef: RefObject<HTMLButtonElement>;
  placement?: 'top' | 'bottom';
}

export default function ExportPalettePopover(props: Props) {
  const { buttonRef, placement } = props;

  const [exportButtonAnchor, setExportButtonAnchor] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.onclick = (event) => setExportButtonAnchor(event.target as HTMLElement);
    }
  }, [buttonRef]);

  return (
    <Popover
      anchorEl={exportButtonAnchor}
      anchorOrigin={{ vertical: placement === 'bottom' ? 'bottom' : 'top', horizontal: 'center' }}
      open={!!exportButtonAnchor}
      transformOrigin={{
        vertical: placement === 'bottom' ? 'top' : 'bottom',
        horizontal: 'center',
      }}
      onClose={() => setExportButtonAnchor(null)}
    >
      <ExportPalette />
    </Popover>
  );
}
