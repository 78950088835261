import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Rating,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { VolumeDown, VolumeUp } from '@mui/icons-material';
import { useContext } from 'react';
import { AppPreviewContext } from '../appPreviewContext';

export default function MUIDemo() {
  const { colorPalette, isDarkMode, getScaledValue } = useContext(AppPreviewContext);

  const { neutrals: neutralVariations } = colorPalette.variationValues;

  return (
    <Box
      bgcolor={
        colorPalette.background[isDarkMode ? neutralVariations.dark1 : neutralVariations.light1]
      }
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      height="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        width="45%"
      >
        <Tooltip title="Neutral text color">
          <Typography variant="h1">This is H1</Typography>
        </Tooltip>
        <Paper elevation={0}>
          <Tooltip title="Accent color">
            <Typography color="primary" variant="h2">
              This is H2
            </Typography>
          </Tooltip>
        </Paper>
        <Tooltip title="Secondary color (if available)">
          <Typography color="secondary" variant="h3">
            This is H3
          </Typography>
        </Tooltip>
        <Paper elevation={1}>
          <Tooltip title="Secondary color (if available)">
            <Typography color="secondary" variant="h4">
              This is H4
            </Typography>
          </Tooltip>
        </Paper>
        <Tooltip title="Accent color">
          <Typography color="primary" variant="h5">
            This is H5
          </Typography>
        </Tooltip>
        <Paper elevation={2}>
          <Tooltip title="Neutral text color">
            <Typography variant="h6">This is H6</Typography>
          </Tooltip>
        </Paper>
        <Box
          display="flex"
          flexDirection="row"
          gap={getScaledValue(3)}
          marginTop={getScaledValue(2)}
          width="100%"
        >
          <Box display="flex" flexDirection="column" flexGrow={1} gap={getScaledValue(0.5)}>
            <Tooltip title="Negative semantic color">
              <Alert severity="error">Error alert!</Alert>
            </Tooltip>
            <Tooltip title="Warning semantic color">
              <Alert severity="warning">Warning alert!</Alert>
            </Tooltip>
            <Tooltip title="Info semantic color">
              <Alert severity="info">Info alert!</Alert>
            </Tooltip>
            <Tooltip title="Positive semantic color">
              <Alert severity="success">Success alert!</Alert>
            </Tooltip>
          </Box>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Typography variant="body1">This is body1</Typography>
            <Typography variant="body2">This is body2</Typography>
            <Paper elevation={3}>
              <Typography variant="subtitle1">This is subtitle1</Typography>
              <Typography variant="subtitle2">This is subtitle2</Typography>
            </Paper>
            <Typography variant="button">This is button</Typography>
            <Typography variant="caption">This is caption</Typography>
            <Typography variant="overline">This is overline</Typography>
            <Tooltip title="Accent color">
              <Link aria-label="Example link with your theme" style={{ cursor: 'pointer' }}>
                This is a link
              </Link>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        overflow="scroll"
        padding={getScaledValue(3)}
        width="40%"
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={getScaledValue(1)}
          justifyContent="center"
          marginBottom={getScaledValue(2)}
        >
          <Tooltip title="Accent color">
            <Chip label="Chip Filled" />
          </Tooltip>
          <Tooltip title="Secondary color (if available)">
            <Chip color="secondary" label="Chip Outlined" variant="outlined" />
          </Tooltip>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          gap={getScaledValue(1)}
          justifyContent="center"
          marginBottom={getScaledValue(2)}
        >
          <Tooltip title="Accent color">
            <Button aria-label="Example button with your theme" variant="contained">
              Contained
            </Button>
          </Tooltip>
          <Tooltip title="Secondary color (if available)">
            <Button aria-label="Example button with your theme" color="secondary" variant="text">
              Text
            </Button>
          </Tooltip>
          <Tooltip title="Accent color">
            <Button aria-label="Example button with your theme" variant="outlined">
              Outlined
            </Button>
          </Tooltip>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="row" marginY={getScaledValue(2)}>
          <FormGroup>
            <FormControlLabel
              aria-label="Example checkbox with your theme"
              control={
                <Tooltip title="Accent color">
                  <Checkbox defaultChecked />
                </Tooltip>
              }
              label="Checkbox"
            />
            <Tooltip title="Neutral disabled color">
              <FormControlLabel
                aria-label="Example checkbox with your theme"
                disabled
                control={<Checkbox defaultChecked />}
                label="Disabled"
              />
            </Tooltip>
          </FormGroup>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={getScaledValue(1)}
            justifyContent="center"
            width="100%"
          >
            <Rating aria-label="Example 5-star rating" defaultValue={4} />
            <Tooltip title="Accent color">
              <Stack alignItems="center" direction="row" spacing={getScaledValue(2)} width="100%">
                <VolumeDown color="primary" />
                <Slider aria-label="Example volume slider with your theme" defaultValue={50} />
                <VolumeUp color="primary" />
              </Stack>
            </Tooltip>
          </Box>
        </Box>
        <Divider />
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gap={getScaledValue(2)}
          marginY={getScaledValue(2)}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Tooltip title="Accent color">
                  <Switch defaultChecked />
                </Tooltip>
              }
              label="Switch"
              required
            />
            <Tooltip title="Neutral disabled color">
              <FormControlLabel disabled control={<Switch />} label="Disabled" />
            </Tooltip>
          </FormGroup>
          <Tooltip placement="top" title="Accent color">
            <FormControl fullWidth>
              <InputLabel>Favorite color</InputLabel>
              <Select
                aria-label="Example dropdown selector with your theme"
                defaultValue="red"
                label="Favorite color"
              >
                <MenuItem value="red">Red</MenuItem>
                <MenuItem value="green">Green</MenuItem>
                <MenuItem value="blue">Blue</MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Box>
        <Divider />
        <Box marginTop={getScaledValue(2)}>
          <Card variant="outlined">
            <Box
              display="flex"
              flexDirection="column"
              gap={getScaledValue(2)}
              padding={getScaledValue(2)}
            >
              <TextField
                aria-label="Example text field with your theme"
                label="Outlined TextField"
                placeholder="Enter some text"
                variant="outlined"
              />
              <TextField
                aria-label="Example text field with your theme"
                label="Filled TextField"
                placeholder="Enter some text"
                variant="filled"
              />
              <TextField
                aria-label="Example text field with your theme"
                label="Standard TextField"
                variant="standard"
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
