import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { AppUIContext } from '../../utils/appUIContext';
import { isColorDark } from '../../utils/colors/colorUtils';

const PUSH_TO_STACK_DEBOUNCE_TIME_MS = 500;
const HOVER_POPOVER_DELAY_MS = 1500;

function HistoryPopoverColorRow({
  color,
  listNumber,
  onClick,
}: {
  color: string;
  listNumber: number;
  onClick: () => void;
}) {
  const { uiColorPalette } = useContext(AppUIContext);

  const textColor = useMemo(() => {
    const {
      variationValues: { neutrals: neutralVariations },
    } = uiColorPalette;
    if (isColorDark(color)) {
      return uiColorPalette.text[neutralVariations.light1];
    } else {
      return uiColorPalette.text[neutralVariations.dark1];
    }
  }, [color, uiColorPalette]);

  return (
    <Button
      style={{
        backgroundColor: color,
        borderRadius: 8,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
      }}
      onClick={onClick}
    >
      <Typography color={textColor}>
        {listNumber}. {color.toUpperCase()}
      </Typography>
    </Button>
  );
}

function UndoRedoButton({
  ariaLabel,
  colorList,
  isDisabled,
  title,
  type,
  onClick,
  onJumpToColorIndex,
}: {
  ariaLabel: string;
  colorList: string[];
  isDisabled: boolean;
  title: string;
  type: 'UNDO' | 'REDO';
  onClick: () => void;
  onJumpToColorIndex: (index: number) => void;
}) {
  const popoverButtonRef = useRef<Element | null>(null);
  const [isColorListPopoverOpen, setIsColorListPopoverOpen] = useState(false);

  const hoverPopoverDelayTimerRef = useRef<NodeJS.Timeout | null>(null);

  const clearPopupDelayTimer = useCallback(() => {
    if (hoverPopoverDelayTimerRef.current) {
      clearTimeout(hoverPopoverDelayTimerRef.current);
      hoverPopoverDelayTimerRef.current = null;
    }
  }, []);

  const handleMouseHoverStart = useCallback(() => {
    clearPopupDelayTimer();
    hoverPopoverDelayTimerRef.current = setTimeout(() => {
      setIsColorListPopoverOpen(true);
    }, HOVER_POPOVER_DELAY_MS);
  }, [clearPopupDelayTimer]);

  const handleMouseHoverEnd = useCallback(() => {
    clearPopupDelayTimer();
    if (isDisabled || colorList.length === 0) {
      setIsColorListPopoverOpen(false);
    }
  }, [clearPopupDelayTimer, colorList.length, isDisabled]);

  const handleButtonClicked = useCallback(() => {
    clearPopupDelayTimer();
    onClick();
    setIsColorListPopoverOpen(false); // TODO: this doesn't actually close the popup? Debug!
  }, [clearPopupDelayTimer, onClick]);

  useEffect(() => {
    // Clear the timer on component unmount:
    return () => clearPopupDelayTimer();
  }, [clearPopupDelayTimer]);

  const reversedList = [...colorList];
  reversedList.reverse();

  return (
    <>
      <Popover
        anchorEl={popoverButtonRef.current}
        anchorOrigin={{ horizontal: type === 'UNDO' ? 'left' : 'right', vertical: 'top' }}
        open={!isDisabled && colorList.length > 0 && isColorListPopoverOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: type === 'UNDO' ? 'right' : 'left',
        }}
        onClose={() => setIsColorListPopoverOpen(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          paddingY={1}
          paddingX={3}
          maxHeight={300}
          overflow="scroll"
        >
          {(type === 'UNDO' ? reversedList : colorList).map((color, index) => {
            const listNumber = index + 1;
            const inOrderIndex = type === 'UNDO' ? colorList.length - index - 1 : index;
            return (
              <HistoryPopoverColorRow
                key={`color_row_${color}_${index}`}
                color={color}
                listNumber={listNumber}
                onClick={() => onJumpToColorIndex(inOrderIndex)}
              />
            );
          })}
        </Box>
      </Popover>
      <Tooltip title={title}>
        <Box
          style={{ cursor: 'pointer' }}
          component="div"
          ref={popoverButtonRef}
          onMouseEnter={handleMouseHoverStart}
          onMouseLeave={handleMouseHoverEnd}
        >
          <IconButton aria-label={ariaLabel} disabled={isDisabled} onClick={handleButtonClicked}>
            {type === 'UNDO' ? <UndoIcon /> : <RedoIcon />}
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

interface Props {
  currentColorHex: string;
  onColorChange: (colorHex: string) => void;
}

export default function UndoRedoActions(props: Props) {
  const { currentColorHex, onColorChange } = props;

  const colorHistoryRef = useRef<{
    curColorIndex: number;
    stack: string[];
  }>({ curColorIndex: -1, stack: [] });

  const debouncePushToStackTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [undoColorsList, setUndoColorsList] = useState<string[]>([]);
  const [redoColorsList, setRedoColorsList] = useState<string[]>([]);

  const clearDebounceTimer = useCallback(() => {
    if (debouncePushToStackTimerRef.current) {
      clearTimeout(debouncePushToStackTimerRef.current);
      debouncePushToStackTimerRef.current = null;
    }
  }, []);

  const recomputeUndoRedoColorLists = useCallback(() => {
    const { curColorIndex, stack } = colorHistoryRef.current;
    const undoColors = [...stack];
    undoColors.splice(curColorIndex);
    setUndoColorsList(undoColors);
    setRedoColorsList(stack.slice(curColorIndex + 1));
  }, []);

  const handleUndoClicked = useCallback(() => {
    if (colorHistoryRef.current.curColorIndex < 1) {
      return;
    }
    clearDebounceTimer();
    colorHistoryRef.current.curColorIndex -= 1;
    onColorChange(colorHistoryRef.current.stack[colorHistoryRef.current.curColorIndex]);
    recomputeUndoRedoColorLists();
  }, [clearDebounceTimer, onColorChange, recomputeUndoRedoColorLists]);

  const handleRedoClicked = useCallback(() => {
    if (colorHistoryRef.current.curColorIndex >= colorHistoryRef.current.stack.length - 1) {
      return;
    }
    clearDebounceTimer();
    colorHistoryRef.current.curColorIndex += 1;
    onColorChange(colorHistoryRef.current.stack[colorHistoryRef.current.curColorIndex]);
    recomputeUndoRedoColorLists();
  }, [clearDebounceTimer, onColorChange, recomputeUndoRedoColorLists]);

  const pushToStack = useCallback(
    (nextColorHex: string) => {
      if (
        colorHistoryRef.current.stack.length > 0 &&
        colorHistoryRef.current.stack[colorHistoryRef.current.curColorIndex] === currentColorHex
      ) {
        return;
      }
      colorHistoryRef.current.curColorIndex += 1;
      if (colorHistoryRef.current.curColorIndex < colorHistoryRef.current.stack.length) {
        colorHistoryRef.current.stack.splice(colorHistoryRef.current.curColorIndex);
      }
      colorHistoryRef.current.stack.push(nextColorHex);
      recomputeUndoRedoColorLists();
    },
    [currentColorHex, recomputeUndoRedoColorLists],
  );

  const debouncePushToStack = useCallback(
    (nextColorHex: string) => {
      clearDebounceTimer();
      debouncePushToStackTimerRef.current = setTimeout(
        // NOTE: This should be safe, since the data will only change if a new color comes in
        // or if undo/redo is clicked. If a new color comes in, the timer will be reset anyway.
        // If undo/redo is pressed, the timer will also be reset. So the data should never get overwritten.
        () => pushToStack(nextColorHex),
        PUSH_TO_STACK_DEBOUNCE_TIME_MS,
      );
    },
    [clearDebounceTimer, pushToStack],
  );

  const onJumpToUndoColorIndex = useCallback(
    (undoColorsListIndex: number) => {
      colorHistoryRef.current.curColorIndex = undoColorsListIndex;
      onColorChange(colorHistoryRef.current.stack[colorHistoryRef.current.curColorIndex]);
      recomputeUndoRedoColorLists();
    },
    [onColorChange, recomputeUndoRedoColorLists],
  );

  const onJumpToRedoColorIndex = useCallback(
    (redoColorsListIndex: number) => {
      colorHistoryRef.current.curColorIndex += redoColorsListIndex + 1;
      onColorChange(colorHistoryRef.current.stack[colorHistoryRef.current.curColorIndex]);
      recomputeUndoRedoColorLists();
    },
    [onColorChange, recomputeUndoRedoColorLists],
  );

  useEffect(() => {
    if (colorHistoryRef.current.stack.length === 0) {
      pushToStack(currentColorHex);
    } else {
      debouncePushToStack(currentColorHex);
    }
    return () => clearDebounceTimer(); // Clear the timer on component unmount
  }, [clearDebounceTimer, colorHistoryRef, currentColorHex, debouncePushToStack, pushToStack]);

  return (
    <Box display="flex" flexDirection="row">
      <UndoRedoButton
        ariaLabel="Undo the most recent color change"
        colorList={undoColorsList}
        isDisabled={undoColorsList.length === 0}
        title="Undo change color"
        type="UNDO"
        onClick={handleUndoClicked}
        onJumpToColorIndex={onJumpToUndoColorIndex}
      />
      <UndoRedoButton
        ariaLabel="Redo the most recent color change"
        colorList={redoColorsList}
        isDisabled={redoColorsList.length === 0}
        title="Redo change color"
        type="REDO"
        onClick={handleRedoClicked}
        onJumpToColorIndex={onJumpToRedoColorIndex}
      />
    </Box>
  );
}
