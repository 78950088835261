import React, { useMemo } from 'react';
import { Link, Tooltip, Typography } from '@mui/material';
import { getRandomUniqueKey } from '../../utils/utils';

export interface BrandInfo {
  brandName: string;
  mainColor: string;
}

interface Props {
  brands: BrandInfo[];
  isMessagePlural?: boolean;
  label: string;
  onClick: (colorHex: string) => void;
}

export default function BrandExampleList(props: Props) {
  const { brands, isMessagePlural, label, onClick } = props;

  const brandLinks = useMemo(
    () =>
      brands.map((brand, index) => (
        <React.Fragment key={`brand_link_${getRandomUniqueKey()}`}>
          <Tooltip
            placement="top"
            title={
              isMessagePlural ? 'Click to preview brand colors' : 'Click to preview brand color'
            }
          >
            <Link
              style={{
                cursor: 'pointer',
                textDecorationColor: brand.mainColor,
                textDecorationThickness: 3,
              }}
              color="inherit"
              underline="hover"
              variant="inherit"
              onClick={() => onClick(brand.mainColor)}
            >
              {brand.brandName}
            </Link>
          </Tooltip>
          {index < brands.length - 1 ? ', ' : ''}
        </React.Fragment>
      )),
    [brands, isMessagePlural, onClick],
  );

  if (brandLinks.length === 0) {
    return null;
  }

  return (
    <Typography marginTop={1} variant="body2">
      {label}: <i>{brandLinks}</i>.
    </Typography>
  );
}
