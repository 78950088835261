import { useContext, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { isColorDark } from '../../utils/colors/colorUtils';
import PhoneStatusBar from '../phoneAppComponents/PhoneStatusBar';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { ColorPalette } from '../../utils/colors/colorPalette';
import { AppPreviewContext } from '../appPreviewContext';

function GoalItem({
  colorPalette,
  frequency,
  getScaledValue,
  isDarkMode,
  progress,
  targetProgress,
  title,
}: {
  colorPalette: ColorPalette;
  frequency: string;
  getScaledValue: (value: number) => number;
  isDarkMode: boolean;
  progress: number;
  targetProgress: number;
  title: string;
}) {
  const {
    mainColor: mainColorVariations,
    neutrals: neutralVariations,
    semantic: semanticVariations,
  } = colorPalette.variationValues;

  const backgroundColor =
    colorPalette.referencePalette.main[
      isDarkMode ? mainColorVariations.darker : mainColorVariations.lighter
    ];
  const textColor = isColorDark(backgroundColor)
    ? colorPalette.text[neutralVariations.light1]
    : colorPalette.text[neutralVariations.dark1];
  const defaultProgressBarColor =
    colorPalette.semantic.positive[
      isDarkMode ? semanticVariations.main : semanticVariations.lighter
    ];
  const progressBarColor = isColorDark(defaultProgressBarColor)
    ? colorPalette.semantic.positive[semanticVariations.lighter]
    : defaultProgressBarColor;

  return (
    <Box
      bgcolor={backgroundColor}
      border={getScaledValue(1)}
      borderColor={colorPalette.divider[neutralVariations.lighter]}
      borderRadius={getScaledValue(2)}
      height={getScaledValue(65)}
      marginTop={getScaledValue(2)}
      position="relative"
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        height="100%"
        justifyContent="space-between"
        paddingX={getScaledValue(2)}
      >
        <Box alignItems="flex-start" display="flex" flexDirection="column">
          <Typography color={textColor} variant="h6">
            {title}
          </Typography>
          <Typography color={textColor} variant="body1">
            {frequency}
          </Typography>
        </Box>
        <Typography color={textColor} variant="h6">
          {progress} / {targetProgress}
        </Typography>
      </Box>
      <Box
        bgcolor={progressBarColor}
        borderRadius={getScaledValue(2)}
        bottom={0}
        height={getScaledValue(8)}
        position="absolute"
        width={`${Math.round((100 * progress) / targetProgress)}%`}
      />
    </Box>
  );
}

export default function PhoneGoals() {
  const { colorPalette, isDarkMode, getScaledValue } = useContext(AppPreviewContext);

  const { mainColor: mainColorVariations, neutrals: neutralVariations } =
    colorPalette.variationValues;

  const colors = useMemo(() => {
    return {
      background:
        colorPalette.background[isDarkMode ? neutralVariations.dark2 : neutralVariations.light1],
      topIcon:
        colorPalette.icon[isDarkMode ? mainColorVariations.lighter : mainColorVariations.main],
      bottomNavBar:
        colorPalette.referencePalette.main[
          isDarkMode ? mainColorVariations.darker : mainColorVariations.main
        ],
      bottomNavBarIcon:
        colorPalette.icon[isDarkMode ? mainColorVariations.main : mainColorVariations.lighter],
      addIconColor:
        colorPalette.accent[isDarkMode ? mainColorVariations.lighter : mainColorVariations.darker],
    };
  }, [colorPalette, isDarkMode, mainColorVariations, neutralVariations]);

  const dateToday = useMemo(
    () =>
      new Date().toLocaleString('en-us', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      }),
    [],
  );

  return (
    <Box
      bgcolor={colors.background}
      display="flex"
      flexDirection="column"
      height="100%"
      position="relative"
    >
      <PhoneStatusBar containerBackgroundColor={colors.background} />
      <Box paddingX={getScaledValue(3)} marginTop={getScaledValue(1)}>
        <Box
          alignItems="center"
          color={colors.topIcon}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <MenuIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
          <Box display="flex" flexDirection="column">
            <Typography fontWeight="bold" variant="h5">
              Goals
            </Typography>
            <Typography marginTop={getScaledValue(0.25)} variant="body2">
              {dateToday}
            </Typography>
          </Box>
          <SettingsIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
        </Box>
        <GoalItem
          colorPalette={colorPalette}
          frequency="Every week"
          getScaledValue={getScaledValue}
          isDarkMode={isDarkMode}
          progress={14}
          targetProgress={52}
          title="Save $250 per week"
        />
        <GoalItem
          colorPalette={colorPalette}
          frequency="Every day"
          getScaledValue={getScaledValue}
          isDarkMode={isDarkMode}
          progress={5}
          targetProgress={30}
          title="Practice Spanish"
        />
        <GoalItem
          colorPalette={colorPalette}
          frequency="Two times per week"
          getScaledValue={getScaledValue}
          isDarkMode={isDarkMode}
          progress={88}
          targetProgress={100}
          title="Work out"
        />
        <GoalItem
          colorPalette={colorPalette}
          frequency="Every day"
          getScaledValue={getScaledValue}
          isDarkMode={isDarkMode}
          progress={123}
          targetProgress={365}
          title="Get 8+ hours of sleep"
        />
        <GoalItem
          colorPalette={colorPalette}
          frequency="Every 2 days"
          getScaledValue={getScaledValue}
          isDarkMode={isDarkMode}
          progress={25}
          targetProgress={26}
          title="Make progress on app"
        />
        <Box
          bgcolor={colors.bottomNavBar}
          borderRadius={getScaledValue(4)}
          bottom={0}
          color={colors.bottomNavBarIcon}
          height={getScaledValue(50)}
          left={0}
          position="absolute"
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <TrackChangesIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
          <Box height="100%" position="relative">
            <Box
              bgcolor={colors.addIconColor}
              borderRadius="50%"
              bottom={getScaledValue(25)}
              height={getScaledValue(50)}
              left={getScaledValue(-25)}
              position="absolute"
              style={{ cursor: 'pointer' }}
              width={getScaledValue(50)}
            >
              <Typography
                color={
                  isColorDark(colors.addIconColor)
                    ? colorPalette.text[neutralVariations.light2]
                    : colorPalette.text[neutralVariations.dark2]
                }
                lineHeight="100%"
                variant="h2"
              >
                +
              </Typography>
            </Box>
          </Box>
          <ArchiveOutlinedIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
        </Box>
      </Box>
    </Box>
  );
}
