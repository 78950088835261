import { useContext } from 'react';
import { Typography } from '@mui/material';
import ScreenContainer from '../components/ScreenContainer';
import ColorHarmonySelector from '../components/harmony/ColorHarmonySelector';
import ColorHarmonyInfo from '../components/harmony/ColorHarmonyInfo';
import { AppUIContext, SCREENS } from '../utils/appUIContext';
import useIsFlowMode from '../utils/useIsFlowMode';

export default function HarmonyTool() {
  const { onGoToScreen } = useContext(AppUIContext);
  const isFlowMode = useIsFlowMode();

  return (
    <ScreenContainer
      enableContentPadding
      footerButtonProps={[
        {
          ariaLabel: 'Save harmony and go to palette overview screen',
          label: isFlowMode ? 'Continue' : 'Done',
          onClick: () => onGoToScreen('Palette'),
        },
        {
          ariaLabel: 'Save harmony and go to color selection screen',
          label: isFlowMode ? 'Back' : SCREENS['Color'].shortTitle,
          onClick: () => onGoToScreen('Color', isFlowMode),
        },
      ]}
      fullyTransparentHeader
      showFeedbackButton
      title={SCREENS['Harmony'].title}
    >
      <Typography marginBottom={10} variant="body1">
        Different color harmonies yield additional colors you can use in your designs. This tool can
        help you <b>pick the harmony that you feel fits most perfectly with your brand.</b> Colors
        within each harmony work well with your main color, and they will be combined with neutral
        colors to build your app&apos;s complete color palette. You&apos;ll be able to customize
        which shades are used and how these colors are applied in the main{' '}
        {SCREENS['Palette'].shortTitle}.
      </Typography>
      <ColorHarmonySelector />
      <ColorHarmonyInfo />
    </ScreenContainer>
  );
}
