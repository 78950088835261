import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { ColorPalette, ColorWithVariations } from '../../../utils/colors/colorPalette';
import { OPTION_NOT_SELECTED } from '../../../utils/colors/colorSchemeState';
import { getBaseColorName } from '../../../utils/colors/colorInfo';
import { isColorDark } from '../../../utils/colors/colorUtils';
import { useCallback, useContext, useMemo } from 'react';
import { getRandomUniqueKey } from '../../../utils/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useControlBoxDropdownSelectorWidth from './useControlBoxDropdownSelectorWidth';
import { AppUIContext } from '../../../utils/appUIContext';

export const PALETTE_CONTROL_BOX_SELECT_DROPDOWN_WIDTH_PX = 250;
export const PALETTE_CONTROL_BOX_SELECT_DROPDOWN_DIALOG_WIDTH_PX = 200;

function InfoTooltipIcon({ isDialog, tip }: { isDialog?: boolean; tip: string }) {
  return (
    <Tooltip placement={isDialog ? 'top' : 'right'} title={tip}>
      <InfoOutlinedIcon style={{ fontSize: 18 }} />
    </Tooltip>
  );
}

function MenuItemContent({
  backgroundColor,
  backgroundColor2, // pass in to get a gradient effect
  label,
  uiColorPalette,
}: {
  backgroundColor: string;
  backgroundColor2?: string;
  label: string;
  uiColorPalette: ColorPalette;
}) {
  return (
    <Box
      borderRadius={2}
      paddingX={1}
      textAlign="center"
      flex={1}
      style={{
        background: backgroundColor2
          ? `repeating-linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor2} 25%, ${backgroundColor} 50%)`
          : backgroundColor,
      }}
    >
      <Typography
        color={isColorDark(backgroundColor) ? uiColorPalette.text[100] : uiColorPalette.text[900]}
        variant="inherit"
      >
        {label}
      </Typography>
    </Box>
  );
}

interface PureOptionProps {
  selectedIndex?: number;
}

interface OptionPropsWithNoneOption {
  noOptionLabel: string;
  selectedIndex?: number | typeof OPTION_NOT_SELECTED;
}

type Props<IndexType> = (PureOptionProps | OptionPropsWithNoneOption) & {
  ariaLabel: string;
  disabledIndex?: number;
  isDialog?: boolean;
  isMobileView?: boolean;
  label: string;
  options: ColorWithVariations[];
  tip?: string;
  onChange: (newIndex: IndexType) => void;
};

export default function ColorSelectorDropdown<IndexType>(props: Props<IndexType>) {
  const {
    ariaLabel,
    disabledIndex,
    isDialog,
    isMobileView,
    label,
    options,
    selectedIndex,
    tip,
    onChange,
  } = props;
  const { uiColorPalette } = useContext(AppUIContext);

  const selectorLabel = useMemo(() => {
    return (
      <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
        {label}
        {tip && <InfoTooltipIcon isDialog={isDialog} tip={tip} />}
      </Box>
    );
  }, [isDialog, label, tip]);

  const menuItems = useMemo(
    () =>
      options.map((option, index) => {
        const secondaryColor = option[500];
        const secondaryColorName = getBaseColorName(secondaryColor);
        return (
          <MenuItem
            aria-label={`Color option ${secondaryColorName}`}
            disabled={index === disabledIndex}
            key={`color_selector_option_${getRandomUniqueKey()}`}
            style={{ justifyContent: 'center' }}
            value={index}
          >
            <MenuItemContent
              backgroundColor={secondaryColor}
              label={secondaryColorName}
              uiColorPalette={uiColorPalette}
            />
          </MenuItem>
        );
      }),
    [disabledIndex, options, uiColorPalette],
  );

  const handleSelectionChange = useCallback(
    (event: SelectChangeEvent) => {
      const newSelection = event.target.value;
      if (newSelection === OPTION_NOT_SELECTED) {
        onChange(OPTION_NOT_SELECTED as IndexType);
      } else {
        const newIndex = parseInt(newSelection);
        onChange(newIndex as IndexType);
      }
    },
    [onChange],
  );

  const { width } = useControlBoxDropdownSelectorWidth(isDialog, isMobileView);

  const neutralColor = uiColorPalette.background[uiColorPalette.variationValues.neutrals.darker];
  const neutralColor2 = uiColorPalette.background[uiColorPalette.variationValues.neutrals.lighter];
  return (
    <FormControl sx={{ width }}>
      <InputLabel>{selectorLabel}</InputLabel>
      <Select
        aria-label={ariaLabel}
        label={selectorLabel}
        value={selectedIndex?.toString()}
        onChange={handleSelectionChange}
      >
        {'noOptionLabel' in props && (
          <MenuItem
            aria-label="No color selected option"
            style={{ justifyContent: 'center' }}
            value={OPTION_NOT_SELECTED}
          >
            <MenuItemContent
              backgroundColor={neutralColor}
              backgroundColor2={neutralColor2}
              label={props.noOptionLabel}
              uiColorPalette={uiColorPalette}
            />
          </MenuItem>
        )}
        {menuItems}
      </Select>
    </FormControl>
  );
}
