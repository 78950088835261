import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { getSelectedColorHarmonyColors } from '../../../utils/colors/colorUtils';
import { UserPaletteContext } from '../../../utils/userPaletteContext';
import { ColorHarmonyOption, ColorHarmonySettings } from '../../../utils/colors/colorSchemeState';
import { CONTAINER_DEFAULT_THEME } from '../../../ui/uiStyles';
import useDebounceUpdateMainColor from '../../../utils/useDebounceUpdateMainColor';
import { AppUIContext } from '../../../utils/appUIContext';
import ColorHarmonyDropdown from './ColorHarmonyDropdown';
import PrimaryColorBox from './PrimaryColorBox';
import AccentColorDropdown from './AccentColorDropdown';
import SecondaryColorDropdown from './SecondaryColorDropdown';
import NeutralsBaseColorDropdown from './NeutralsBaseColorDropdown';
import FullColorPaletteSettingsDialog from './FullColorPaletteSettingsDialog';

const CONTROL_BOX_WIDTH_PX = 300;
const CONTROL_BOX_WIDTH_MOBILE_VIEW_PX = 250;

interface Props {
  isSelfContained?: boolean;
}

export default function ColorPaletteSettingsPanel(props: Props) {
  const { isSelfContained } = props;

  const {
    responsiveBreakpoints: { uiSize },
  } = useContext(AppUIContext);
  const { colorPalette, colorSchemeState, onColorSchemeStateChanged } =
    useContext(UserPaletteContext);

  const isMobileView = uiSize === 'smallMobile';

  const { mainColorHex, colorHarmony } = colorSchemeState;

  const [showDialog, setShowDialog] = useState(false);

  const allColorsWithVariations = useMemo(
    () =>
      getSelectedColorHarmonyColors(colorSchemeState.mainColorHex, colorHarmony.selectedHarmony),
    [colorHarmony.selectedHarmony, colorSchemeState.mainColorHex],
  );

  // Keep a local state of the primary color that updates immediately for rendering the editable
  // main color box, and debounce all other updates to avoid re-rendering the entire app every
  // time the user moves their mouse when picking a new color:
  const { debounceUpdateMainColor, mainColorHexRealTime, setMainColor } =
    useDebounceUpdateMainColor();

  useEffect(() => {
    setMainColor(colorSchemeState.mainColorHex);
  }, [colorSchemeState.mainColorHex, setMainColor]);

  const updateHarmonySettings = useCallback(
    (updates: Partial<ColorHarmonySettings>) =>
      onColorSchemeStateChanged({
        colorHarmony: {
          ...colorHarmony,
          ...updates,
        },
      }),
    [colorHarmony, onColorSchemeStateChanged],
  );

  const updateHarmonySelection = useCallback(
    (newHarmonySelection: ColorHarmonyOption) => {
      updateHarmonySettings({
        selectedHarmony: newHarmonySelection,
      });
    },
    [updateHarmonySettings],
  );

  const containerProps = useMemo(() => {
    if (isMobileView) {
      return {
        ...CONTAINER_DEFAULT_THEME,
        maxWidth: CONTROL_BOX_WIDTH_MOBILE_VIEW_PX,
        width: '100%',
      };
    }
    if (isSelfContained) {
      return { ...CONTAINER_DEFAULT_THEME, width: CONTROL_BOX_WIDTH_PX };
    }
    return { height: '100%', overflow: 'scroll', width: '100%' };
  }, [isMobileView, isSelfContained]);

  return (
    <>
      <FullColorPaletteSettingsDialog
        allColorsWithVariations={allColorsWithVariations}
        colorHarmony={colorHarmony}
        colorPalette={colorPalette}
        isOpen={showDialog && !isMobileView}
        mainColorHex={mainColorHexRealTime}
        onClose={() => setShowDialog(false)}
        onColorHarmonyChange={updateHarmonySelection}
        onHarmonySettingsUpdate={updateHarmonySettings}
        onMainColorChange={debounceUpdateMainColor}
      />
      <Box
        {...containerProps}
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          gap={3}
          width="100%"
        >
          <Typography variant="h6">Palette color settings</Typography>
          <PrimaryColorBox
            color={showDialog ? mainColorHex : mainColorHexRealTime}
            fluidWidth
            onEdit={debounceUpdateMainColor}
          />
          <AddIcon fontSize="large" />
          <ColorHarmonyDropdown
            isMobileView={isMobileView}
            selection={colorHarmony.selectedHarmony}
            onChange={updateHarmonySelection}
          />
          <Box marginBottom={0.5}>
            <AddIcon fontSize="large" />
          </Box>
          <AccentColorDropdown
            accentColorOptions={allColorsWithVariations}
            colorHarmony={colorHarmony}
            isMobileView={isMobileView}
            onColorHarmonyChange={updateHarmonySettings}
          />
          <SecondaryColorDropdown
            colorHarmony={colorHarmony}
            isMobileView={isMobileView}
            secondaryColorOptions={allColorsWithVariations}
            onColorHarmonyChange={updateHarmonySettings}
          />
          <NeutralsBaseColorDropdown
            colorHarmony={colorHarmony}
            colorOptions={allColorsWithVariations}
            isMobileView={isMobileView}
            onColorHarmonyChange={updateHarmonySettings}
          />
        </Box>
        {!isMobileView && (
          <Box marginTop={5}>
            <Button color="primary" endIcon={<TuneIcon />} onClick={() => setShowDialog(true)}>
              Open full menu
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
