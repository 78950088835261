import { useCallback, useContext, useRef, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import ScreenContainer from '../components/ScreenContainer';
import ColorPaletteOverview from '../components/palette/colorPaletteOverview/ColorPaletteOverview';
import AppPreviews from '../appPreview/AppPreviews';
import ThemeOptionsPanel from '../components/palette/themeOptions/ThemeOptionsPanel';
import ColorPaletteSettingsPanel from '../components/palette/paletteSettings/ColorPaletteSettingsPanel';
import ContentFooter from '../components/home/ContentFooter';
import MoreInformationDialog, {
  MoreInformationDialogSection,
} from '../components/palette/MoreInformationDialog';
import { AppUIContext, SCREENS } from '../utils/appUIContext';
import RouteLink from '../components/shared/RouteLink';
import RandomizePaletteDialog, {
  RandomizeFunctionRef,
} from '../components/palette/RandomizePaletteDialog';
import ExportPalettePopover from '../components/palette/export/ExportPalettePopover';
import FullPaletteDialog from '../components/palette/colorPaletteOverview/fullColorPaletteDialog/FullPaletteDialog';
import { SettingsPopoverActionButtonType } from '../components/SettingsButtonAndPopover';
import ExportPaletteDialog from '../components/palette/export/ExportPaletteDialog';

export default function FinalPalette() {
  const {
    responsiveBreakpoints: { uiSize },
    settings,
  } = useContext(AppUIContext);

  const randomizeFunctionRef = useRef<RandomizeFunctionRef>(null);
  const [isRandomizePaletteDialogOpen, setIsRandomizePaletteDialogOpen] = useState(false);

  const [isFullPaletteDialogOpen, setIsFullPaletteDialogOpen] = useState(false);

  const exportButtonRef = useRef<HTMLButtonElement>(null);
  const [isExportPaletteDialogOpen, setIsExportPaletteDialogOpen] = useState(false);

  const [isInformationDialogOpen, setIsInformationDialogOpen] = useState(false);
  const [informationDialogDefaultSection, setInformationDialogDefaultSection] = useState<
    MoreInformationDialogSection | undefined
  >(undefined);

  const handleRandomizeButtonClicked = useCallback(() => {
    if (settings.randomizeWarning) {
      setIsRandomizePaletteDialogOpen(true);
    } else {
      randomizeFunctionRef.current?.();
    }
  }, [settings.randomizeWarning]);

  const toggleInformationDialog = useCallback(
    (isOpen: boolean, defaultExpandedSection?: MoreInformationDialogSection) => {
      setInformationDialogDefaultSection(defaultExpandedSection);
      setIsInformationDialogOpen(isOpen);
    },
    [],
  );

  const handleSettingsMenuActionButtonClicked = useCallback(
    (actionButtonType: SettingsPopoverActionButtonType) => {
      switch (actionButtonType) {
        case 'RANDOMIZE':
          handleRandomizeButtonClicked();
          break;
        case 'VIEW_FULL_PALETTE':
          setIsFullPaletteDialogOpen(true);
          break;
        case 'DOWNLOAD_AND_SHARE':
          setIsExportPaletteDialogOpen(true);
          break;
        case 'INFO_AND_HELP':
          toggleInformationDialog(true);
          break;
      }
    },
    [handleRandomizeButtonClicked, toggleInformationDialog],
  );

  return (
    <ScreenContainer
      contentFooter={<ContentFooter />}
      enableContentPadding
      headerActionButtons={[
        {
          ariaLabel: 'Reset and randomize the entire color palette',
          icon: 'CasinoOutlined',
          tip: 'Randomize palette',
          onClick: handleRandomizeButtonClicked,
        },
        {
          ariaLabel: 'Open the full palette dialog',
          icon: 'PaletteOutlined',
          tip: 'View full palette',
          onClick: () => setIsFullPaletteDialogOpen(true),
        },
        {
          ariaLabel: 'Open menu to share or download your color palette',
          icon: 'CloudDownloadOutlined',
          buttonRef: exportButtonRef,
          tip: 'Save and share palette',
        },
        {
          ariaLabel: 'Open help / info menu to view instructions',
          icon: 'InfoOutlined',
          tip: 'Instructions and help',
          onClick: () => toggleInformationDialog(true),
        },
        { screen: 'Color' },
        { screen: 'Harmony' },
      ]}
      leftSidebarContent={uiSize === 'largeDesktop' ? <ColorPaletteSettingsPanel /> : undefined}
      rightSidebarContent={uiSize === 'largeDesktop' ? <ThemeOptionsPanel /> : undefined}
      showFeedbackButton
      title={SCREENS['Palette'].title}
      onSettingsActionButtonClicked={handleSettingsMenuActionButtonClicked}
    >
      <RandomizePaletteDialog
        isOpen={isRandomizePaletteDialogOpen}
        randomizeFunctionRef={randomizeFunctionRef}
        onClose={() => setIsRandomizePaletteDialogOpen(false)}
      />
      <FullPaletteDialog
        isMovable
        isOpen={isFullPaletteDialogOpen}
        onClose={() => setIsFullPaletteDialogOpen(false)}
      />
      <ExportPalettePopover buttonRef={exportButtonRef} placement="bottom" />
      <ExportPaletteDialog
        isOpen={isExportPaletteDialogOpen}
        onClose={() => setIsExportPaletteDialogOpen(false)}
      />
      <Typography variant="body1" marginBottom={2}>
        Choose which harmony colors to use and how to apply them from the menus on this page. Use
        the <RouteLink screen="Color" /> or the <RouteLink screen="Harmony" /> for a more guided
        experience. You can then view or download the full color palette.
      </Typography>
      <Typography variant="body1" marginBottom={2}>
        Need help? Learn{' '}
        <Link
          style={{ cursor: 'pointer' }}
          onClick={() => toggleInformationDialog(true, 'PALETTE_EDITOR')}
        >
          how to use this tool
        </Link>{' '}
        or check out some{' '}
        <Link style={{ cursor: 'pointer' }} onClick={() => toggleInformationDialog(true, 'TIPS')}>
          additional tips
        </Link>{' '}
        to get the most out of AppSchemer.
      </Typography>
      <MoreInformationDialog
        defaultExpandedSection={informationDialogDefaultSection}
        isOpen={isInformationDialogOpen}
        onClose={() => toggleInformationDialog(false)}
      />
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={3}
        justifyContent="center"
        marginTop={3}
        width="100%"
      >
        <ColorPaletteOverview isFullWidth={uiSize === 'largeDesktop'} />
        {uiSize !== 'largeDesktop' && <ColorPaletteSettingsPanel isSelfContained />}
        {uiSize !== 'largeDesktop' && <ThemeOptionsPanel isSelfContained />}
      </Box>
      <Box marginTop={10} width="100%">
        <AppPreviews scale={uiSize === 'smallMobile' ? 'MOBILE' : 'DESKTOP'} />
      </Box>
    </ScreenContainer>
  );
}
