// Not to be confused with "colors.ts" which is color utilities. This is just to define a constant
// color scheme for this app itself.

import { Theme } from '@emotion/react';
import { getColorPalette } from '../utils/colors/colorUtils';
import { Settings } from '../utils/settings';
import {
  TypographyGlobalFontSize,
  getDefaultMUITheme,
  getMUIThemeFromColorPalette,
} from '../utils/muiTheme';
import { ColorPalette } from '../utils/colors/colorPalette';
import {
  ColorSchemeOptions,
  ColorSchemeState,
  DEFAULT_COLOR_SCHEME_OPTIONS,
} from '../utils/colors/colorSchemeState';

export const DEFAULT_UI_MAIN_COLOR_HEX = '#9DB4E2';

// For reference, these are the other two triadic colors:
//  Accent (main): #9DB4E2
//  Secondary: #E29DB4
//  Other: #B4E29D

const DEFAULT_UI_COLOR_SCHEME_STATE: ColorSchemeState = {
  mainColorHex: DEFAULT_UI_MAIN_COLOR_HEX,
  colorHarmony: {
    selectedHarmony: 'triadic',
    accentColorIndex: 0,
    secondaryColorIndex: 1,
    neutralColorIndex: 'X',
  },
  options: {
    mainBrightness: 7,
    neutralBrightness: 5,
    semanticBrightness: 6,
    mainContrast: 'normal',
    neutralContrast: 'low',
    tintTextNeutrals: true,
    darkMode: false,
  },
};

const DEFAULT_UI_COLOR_SCHEME_STATE_DARK_MODE: ColorSchemeState = {
  ...DEFAULT_UI_COLOR_SCHEME_STATE,
  options: {
    ...DEFAULT_UI_COLOR_SCHEME_STATE.options,
    mainBrightness: 5,
    semanticBrightness: 3,
    darkMode: true,
  },
};

export function getColorPaletteForUI(
  settings: Settings,
  userColorSchemeState: ColorSchemeState,
): ColorPalette {
  switch (settings.theme) {
    case 'DEFAULT':
      return getColorPalette(
        settings.darkMode ? DEFAULT_UI_COLOR_SCHEME_STATE_DARK_MODE : DEFAULT_UI_COLOR_SCHEME_STATE,
      );
    case 'USER':
      return getColorPalette(userColorSchemeState);
    case 'MUI':
      return getColorPalette(
        settings.darkMode ? DEFAULT_UI_COLOR_SCHEME_STATE_DARK_MODE : DEFAULT_UI_COLOR_SCHEME_STATE,
      ); // TODO: somehow remap the MUI theme to the ColorPalette
  }
}

export function getMUIThemeForUI(
  settings: Settings,
  userColorPalette: ColorPalette,
  colorSchemeOptions: ColorSchemeOptions,
  typographyGlobalFontSize?: TypographyGlobalFontSize,
): Theme {
  switch (settings.theme) {
    case 'DEFAULT': {
      const { darkMode } = settings;
      return getMUIThemeFromColorPalette(
        getColorPalette(
          darkMode ? DEFAULT_UI_COLOR_SCHEME_STATE_DARK_MODE : DEFAULT_UI_COLOR_SCHEME_STATE,
        ),
        { ...DEFAULT_COLOR_SCHEME_OPTIONS, darkMode },
        { typographyGlobalFontSize },
      );
    }
    case 'USER':
      return getMUIThemeFromColorPalette(userColorPalette, colorSchemeOptions, {
        typographyGlobalFontSize,
      });
    case 'MUI':
      return getDefaultMUITheme();
  }
}

export function addAlphaToColor(color: string, alphaNormalized: number): string {
  // TODO: add testing for this function & move to utils & clean up w/ existing utilities
  if (alphaNormalized < 0 || alphaNormalized > 1) {
    throw new Error(`Invalid alpha value: ${alphaNormalized}. Expected a value between 0 and 1.`);
  }
  const isHex = color.startsWith('#');
  const isRGB = color.startsWith('rgb');
  const isRGBA = isRGB && color.startsWith('rgba');
  if (color.length < 7 || !(isHex || isRGB)) {
    throw new Error(`Cannot add alpha to invalid color: ${color}.`);
  }
  if (isHex) {
    let alphaHex = Math.floor(alphaNormalized * 256)
      .toString(16)
      .toUpperCase();
    if (alphaHex.length < 2) {
      alphaHex = alphaHex + '0';
    } else if (alphaHex.length > 2) {
      alphaHex = 'FF'; // this is the max, since 100% alpha will be `100` in Hex (not FF)
    }
    return `${color.substring(0, 7)}${alphaHex}`;
  }
  if (isRGB || isRGBA) {
    throw new Error('TODO: RGB and RGBA colors not yet supported!');
  }
  return color;
}
