import { ColorSchemeOptions } from './colors/colorSchemeState';

export type AppTheme = 'DEFAULT' | 'USER' | 'MUI';

export interface Settings {
  darkMode: boolean;
  randomizeHarmonyColors: boolean;
  randomizeOptions: boolean;
  randomizeWarning: boolean;
  scaleDownPreviewsOnMobile: boolean;
  theme: AppTheme;
  warnBeforeLeavingApp: boolean;
}

export const DEFAULT_SETTINGS: Settings = {
  darkMode: false,
  randomizeHarmonyColors: true,
  randomizeOptions: true,
  randomizeWarning: true,
  scaleDownPreviewsOnMobile: true,
  theme: 'DEFAULT',
  warnBeforeLeavingApp: true,
};

export function isDarkModeEnabled(settings: Settings, userColorSchemeOptions: ColorSchemeOptions) {
  if (settings.theme === 'USER') {
    return userColorSchemeOptions.darkMode;
  }
  return settings.darkMode;
}
