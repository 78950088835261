import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import ColorSampleBox from '../../shared/ColorSampleBox';
import { useContext, useMemo, useState } from 'react';
import { getRandomUniqueKey } from '../../../utils/utils';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreInformationDialog from '../MoreInformationDialog';
import { UserPaletteContext } from '../../../utils/userPaletteContext';

export type OverviewRowColorInfo = {
  isDisabled?: boolean;
  isLabelBold?: boolean;
  hexValue: string;
  label: string;
};

const COLOR_GRADIENT_BACKGROUND_OPACITY_HEX = '40' as const;
const HELP_ICON_SIZE = 25;

function getColorGradientStyle(colors: OverviewRowColorInfo[]) {
  const gradientColorStoppers = colors.map((color, index) => {
    const colorHexWithOpacity = `${color.hexValue}${COLOR_GRADIENT_BACKGROUND_OPACITY_HEX}`;
    const stopperPercentage = Math.round((100 * index) / (colors.length - 1));
    return `${colorHexWithOpacity} ${stopperPercentage}%`;
  });
  return `linear-gradient(to right, ${gradientColorStoppers.join(', ')})`;
}

function GroupedColorsRow({
  colorGroups,
  disabledMessage,
  disabledTextColor,
  showWarningIfColorIsTooLightOrDark,
}: {
  colorGroups: OverviewRowColorInfo[][];
  disabledMessage?: string;
  disabledTextColor?: string;
  showWarningIfColorIsTooLightOrDark?: boolean;
}) {
  const groupRowKey = useMemo(() => getRandomUniqueKey(), []);

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2} justifyContent="center">
      {colorGroups.map((colorGroup, groupIndex) => {
        if (colorGroup.length === 0) {
          return null;
        }
        const areAllColorsDisabled = colorGroup.every((color) => color.isDisabled);
        return (
          <Box
            borderRadius={5}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            key={`group_${groupRowKey}_${groupIndex}`}
            paddingX={1}
            position="relative"
            style={{ background: getColorGradientStyle(colorGroup) }}
          >
            {colorGroup.map((color, colorIndex) => {
              return (
                <ColorSampleBox
                  bold={color.isLabelBold}
                  color={color.hexValue}
                  compact
                  containerStyle={color.isDisabled ? { visibility: 'hidden' } : undefined}
                  halfHeight
                  key={`group_${groupRowKey}_${groupIndex}_color_${colorIndex}`}
                  label={color.label}
                  paddingY={2}
                  showColorCode={!color.isDisabled}
                  showWarningIfColorIsTooLightOrDark={
                    !color.isDisabled && showWarningIfColorIsTooLightOrDark
                  }
                />
              );
            })}
            {areAllColorsDisabled && (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                height="100%"
                position="absolute"
              >
                <Typography color={disabledTextColor} variant="body1">
                  {disabledMessage ?? 'No colors to show'}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

interface Props {
  colorGroups: OverviewRowColorInfo[][];
  disabledMessage?: string;
  marginBottom?: number;
  showWarningIfColorIsTooLightOrDark?: boolean;
  title: string;
}

export default function ColorPaletteOverviewRow(props: Props) {
  const { colorGroups, disabledMessage, marginBottom, showWarningIfColorIsTooLightOrDark, title } =
    props;

  const { colorPalette: userColorPalette, colorSchemeState } = useContext(UserPaletteContext);

  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const { areAllColorsInAllColorGroupsDisabled, iconColor, textColor } = useMemo(() => {
    const { darkMode } = colorSchemeState.options;
    const { mainColor: mainColorVariations, neutrals: neutralVariations } =
      userColorPalette.variationValues;
    const areAllColorsInAllColorGroupsDisabled = colorGroups.every((colorGroup) =>
      colorGroup.every((color) => color.isDisabled),
    );
    return {
      areAllColorsInAllColorGroupsDisabled,
      iconColor:
        userColorPalette.icon[darkMode ? mainColorVariations.lighter : mainColorVariations.darker],
      textColor:
        userColorPalette.text[darkMode ? neutralVariations.light1 : neutralVariations.dark1],
    };
  }, [
    colorGroups,
    colorSchemeState.options,
    userColorPalette.icon,
    userColorPalette.text,
    userColorPalette.variationValues,
  ]);

  return (
    <>
      <MoreInformationDialog
        defaultExpandedSection="COLOR_PALETTE"
        isOpen={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
      />
      <Box
        alignItems="center"
        color={textColor}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
        justifyContent="center"
        marginBottom={marginBottom}
        marginX={2}
        maxWidth="100%"
      >
        <Typography textAlign="right" variant="h6">
          {title}:
        </Typography>
        <GroupedColorsRow
          colorGroups={colorGroups}
          disabledMessage={disabledMessage}
          disabledTextColor={
            userColorPalette.disabled[userColorPalette.variationValues.neutrals.main]
          }
          showWarningIfColorIsTooLightOrDark={showWarningIfColorIsTooLightOrDark}
        />
        <Box color={iconColor}>
          <IconButton
            aria-label="Open documentation on how the palette was generated and how it can be used"
            color="inherit"
            disabled={areAllColorsInAllColorGroupsDisabled}
            onClick={() => setShowInfoDialog(true)}
          >
            <Tooltip placement="right" title="How are these colors generated and used?">
              <HelpOutlineOutlinedIcon style={{ fontSize: HELP_ICON_SIZE }} />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
