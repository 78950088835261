import { ColorWithVariations } from '../../../utils/colors/colorPalette';
import { ColorHarmonySettings } from '../../../utils/colors/colorSchemeState';
import ColorSelectorDropdown from './ColorSelectorDropdown';

interface Props {
  colorHarmony: ColorHarmonySettings;
  colorOptions: ColorWithVariations[];
  isDialog?: boolean;
  isMobileView?: boolean;
  onColorHarmonyChange: (updates: Partial<ColorHarmonySettings>) => void;
}

export default function NeutralsBaseColorDropdown(props: Props) {
  const { colorHarmony, colorOptions, isDialog, isMobileView, onColorHarmonyChange } = props;

  return (
    <ColorSelectorDropdown
      ariaLabel="Choose which harmony color will be used as your neutrals hue"
      isDialog={isDialog}
      isMobileView={isMobileView}
      label="Background neutrals hue"
      noOptionLabel="Pure neutral"
      options={colorOptions}
      selectedIndex={colorHarmony.neutralColorIndex}
      tip={
        'Adds a subtle tint to some of the background neutrals. The "pure neutral" option will be colorless (fully de-saturated). This option is best visualized with dark mode enabled.'
      }
      onChange={(newIndex: ColorHarmonySettings['neutralColorIndex']) =>
        onColorHarmonyChange({
          neutralColorIndex: newIndex,
        })
      }
    />
  );
}
