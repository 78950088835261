import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import AppHeader, { HeaderActionButtonProps } from './AppHeader';
import AppFooter, { AppFooterProps } from './AppFooter';
import { UserPaletteContext } from '../utils/userPaletteContext';
import { ColorPalette } from '../utils/colors/colorPalette';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { BETA_FEEDBACK_FORM_URL } from '../ui/uiUrls';
import { BLUR_BACKGROUND_STYLE } from '../ui/uiStyles';
import {
  CONTENT_PADDING_PX,
  HEADER_FOOTER_HEIGHT_PX,
  MAX_CONTENT_WIDTH_PX,
  SIDEBAR_PADDING_PX,
  SIDEBAR_WIDTH_PX,
} from '../ui/uiSizes';
import { UI_WIDTH_SIZE } from '../utils/useResponsiveUIBreakpoints';
import { isDarkModeEnabled } from '../utils/settings';
import { AppUIContext } from '../utils/appUIContext';
import { getSecondaryColorOrNextBestOption } from '../utils/colors/colorUtils';
import { SettingsPopoverActionButtonType } from './SettingsButtonAndPopover';

function PageSidebar({
  children,
  isDarkMode,
  side,
  uiColorPalette,
}: {
  children: React.ReactNode;
  isDarkMode: boolean;
  side: 'LEFT' | 'RIGHT';
  uiColorPalette: ColorPalette;
}) {
  const contentHeightOffsetPx =
    HEADER_FOOTER_HEIGHT_PX + // offset header
    2 * SIDEBAR_PADDING_PX; // top and bottom padding

  const boxShadow =
    side === 'LEFT'
      ? `3px 0px 6px ${uiColorPalette.background[200]}`
      : `-3px 0px 6px ${uiColorPalette.background[200]}`;

  return (
    <Box height="100vh" width={SIDEBAR_WIDTH_PX}>
      <Box
        alignItems="center"
        bgcolor={uiColorPalette.background[isDarkMode ? 800 : 100]}
        boxShadow={boxShadow}
        display="flex"
        flexDirection="column"
        height={`calc(100% - ${contentHeightOffsetPx}px)`}
        marginTop={`${HEADER_FOOTER_HEIGHT_PX}px`}
        padding={`${SIDEBAR_PADDING_PX}px`}
        paddingBottom={`${2 * HEADER_FOOTER_HEIGHT_PX}px`}
        position="fixed"
        width={SIDEBAR_WIDTH_PX - 2 * SIDEBAR_PADDING_PX}
      >
        {children}
      </Box>
    </Box>
  );
}

function PageContent({
  children,
  enableContentPadding,
  hasLeftSidebar,
  hasRightSidebar,
  isDarkMode,
  title,
  uiColorPalette,
  uiSize,
  windowWidth,
}: {
  children: React.ReactNode;
  enableContentPadding?: boolean;
  hasLeftSidebar: boolean;
  hasRightSidebar: boolean;
  isDarkMode: boolean;
  title?: string;
  uiColorPalette: ColorPalette;
  uiSize: UI_WIDTH_SIZE;
  windowWidth: number;
}) {
  const maxContentWidth = useMemo(() => {
    const sidebarOffsets =
      (hasLeftSidebar ? SIDEBAR_WIDTH_PX : 0) + (hasRightSidebar ? SIDEBAR_WIDTH_PX : 0);

    const contentWidthPaddingOffsetPx = enableContentPadding
      ? 2 * CONTENT_PADDING_PX // left and right side padding
      : 0;

    const maxWidth = windowWidth - sidebarOffsets - contentWidthPaddingOffsetPx;
    return Math.min(maxWidth, MAX_CONTENT_WIDTH_PX);
  }, [enableContentPadding, hasLeftSidebar, hasRightSidebar, windowWidth]);

  return (
    <Box
      alignItems="center"
      bgcolor={isDarkMode ? uiColorPalette.black : uiColorPalette.white}
      display="flex"
      flexDirection="column"
      maxWidth={maxContentWidth}
      overflow="scroll"
      paddingX={enableContentPadding ? `${CONTENT_PADDING_PX}px` : undefined}
      paddingY={`${CONTENT_PADDING_PX}px`}
    >
      {title && (
        <Typography
          textAlign="center"
          fontWeight="light"
          marginBottom={`${CONTENT_PADDING_PX}px`}
          marginTop={`${HEADER_FOOTER_HEIGHT_PX}px`}
          paddingX={!enableContentPadding ? `${CONTENT_PADDING_PX}px` : undefined}
          variant={uiSize === 'smallMobile' ? 'h3' : 'h2'}
        >
          {title}
        </Typography>
      )}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginBottom={`${HEADER_FOOTER_HEIGHT_PX + CONTENT_PADDING_PX}px`}
        textAlign="center"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}

interface Props extends AppFooterProps {
  children: React.ReactNode;
  contentFooter?: React.ReactNode; // NOT part of the app footer, but at the bottom of the regular content
  enableContentPadding?: boolean;
  fullyTransparentHeader?: boolean;
  headerActionButtons?: HeaderActionButtonProps[];
  leftSidebarContent?: React.ReactNode;
  rightSidebarContent?: React.ReactNode;
  showFeedbackButton?: boolean;
  title?: string;
  onSettingsActionButtonClicked?: (actionButtonType: SettingsPopoverActionButtonType) => void;
}

export default function ScreenContainer(props: Props) {
  const {
    contentFooter,
    enableContentPadding,
    footerButtonProps,
    fullyTransparentHeader,
    headerActionButtons,
    leftSidebarContent,
    rightSidebarContent,
    showFeedbackButton,
    title,
    onSettingsActionButtonClicked,
  } = props;
  const {
    currentScreen,
    responsiveBreakpoints: { uiSize, windowWidth },
    settings,
    uiColorPalette,
  } = useContext(AppUIContext);
  const { colorSchemeState: userColorSchemeState } = useContext(UserPaletteContext);

  const isDarkMode = isDarkModeEnabled(settings, userColorSchemeState.options);

  const handleWindowUnloadEvent = useCallback((event: BeforeUnloadEvent) => {
    event.returnValue = 'Leave page? Your color palette may not be saved.';
    return 'Leave page? Your color palette may not be saved.';
  }, []);

  useEffect(() => {
    if (settings.warnBeforeLeavingApp && ['Color', 'Harmony', 'Palette'].includes(currentScreen)) {
      window.addEventListener('beforeunload', handleWindowUnloadEvent);
      return () => window.removeEventListener('beforeunload', handleWindowUnloadEvent);
    }
  }, [currentScreen, handleWindowUnloadEvent, settings.warnBeforeLeavingApp]);

  useEffect(() => {
    document.body.style.backgroundColor = isDarkMode ? uiColorPalette.black : uiColorPalette.white;
  }, [isDarkMode, uiColorPalette.black, uiColorPalette.white]);

  const feedbackIconColor = useMemo(() => {
    const variation = uiColorPalette.variationValues.mainColor.main;
    const secondaryColor = getSecondaryColorOrNextBestOption(uiColorPalette);
    return secondaryColor[variation];
  }, [uiColorPalette]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      position="relative"
      width="100%"
    >
      <AppHeader
        actionButtons={headerActionButtons}
        isFullyTransparent={fullyTransparentHeader}
        onSettingsActionButtonClicked={onSettingsActionButtonClicked}
      />
      <Box display="flex" flexDirection="row">
        {leftSidebarContent && (
          <PageSidebar isDarkMode={isDarkMode} side="LEFT" uiColorPalette={uiColorPalette}>
            {leftSidebarContent}
          </PageSidebar>
        )}
        <PageContent
          enableContentPadding={enableContentPadding}
          hasLeftSidebar={!!leftSidebarContent}
          hasRightSidebar={!!rightSidebarContent}
          isDarkMode={isDarkMode}
          title={title}
          uiColorPalette={uiColorPalette}
          uiSize={uiSize}
          windowWidth={windowWidth}
        >
          {props.children}
        </PageContent>
        {rightSidebarContent && (
          <PageSidebar isDarkMode={isDarkMode} side="RIGHT" uiColorPalette={uiColorPalette}>
            {rightSidebarContent}
          </PageSidebar>
        )}
      </Box>
      {contentFooter && <Box width="100%">{contentFooter}</Box>}
      <AppFooter footerButtonProps={footerButtonProps} />
      {showFeedbackButton && uiSize !== 'smallMobile' && (
        <Box
          borderRadius="50%"
          bottom={25 + (footerButtonProps ? 60 : 0)}
          color={feedbackIconColor}
          position="fixed"
          right={132}
          style={BLUR_BACKGROUND_STYLE}
        >
          <Tooltip placement="top" title="Share feedback (via Google Forms)">
            <IconButton
              aria-label="Open a Google Forms page to share feedback about AppSchemer"
              color="inherit"
              href={BETA_FEEDBACK_FORM_URL}
              target="_blank"
            >
              <FeedbackOutlinedIcon style={{ padding: 15, fontSize: 65 }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
