import { Theme } from '@emotion/react';
import { isColorDark } from './colors/colorUtils';
import { ThemeOptions, createTheme } from '@mui/material';
import { ColorPalette } from './colors/colorPalette';
import { ColorSchemeOptions } from './colors/colorSchemeState';

export const TYPOGRAPHY_GLOBAL_FONT_SIZES = {
  APP_PREVIEW_PHONE: 10,
  APP_PREVIEW_WEB: 14,
  MOBILE: 12,
  WEB: 14,
} as const;
export type TypographyGlobalFontSize =
  (typeof TYPOGRAPHY_GLOBAL_FONT_SIZES)[keyof typeof TYPOGRAPHY_GLOBAL_FONT_SIZES];

interface GetThemeOptions {
  components?: ThemeOptions['components'];
  typographyGlobalFontSize?: number; // scale all text globally (e.g. for preview app renders)
}

export function getMUIThemeOptions(
  colorPalette: ColorPalette,
  colorSchemeOptions: ColorSchemeOptions,
  options?: GetThemeOptions,
): ThemeOptions {
  const { darkMode: isDarkMode } = colorSchemeOptions;
  const { components, typographyGlobalFontSize } = options ?? {};

  const {
    mainColor: mainColorVariations,
    neutrals: neutralVariations,
    semantic: semanticVariations,
  } = colorPalette.variationValues;

  const { accent, background, disabled, divider, secondary, semantic, text } = colorPalette;
  const secondaryColor = secondary ?? accent;

  return {
    palette: {
      primary: {
        ...accent,
        main: accent[mainColorVariations.main],
        light: accent[mainColorVariations.lighter],
        dark: accent[mainColorVariations.darker],
        contrastText: isColorDark(
          accent[mainColorVariations.main],
          accent[mainColorVariations.darker],
        )
          ? text[neutralVariations.light1]
          : text[neutralVariations.dark1],
      },
      secondary: {
        ...secondaryColor,
        main: secondaryColor[mainColorVariations.main],
        light: secondaryColor[mainColorVariations.lighter],
        dark: secondaryColor[mainColorVariations.darker],
        contrastText: isColorDark(
          secondaryColor[mainColorVariations.main],
          text[neutralVariations.darker],
        )
          ? text[neutralVariations.light1]
          : text[neutralVariations.dark1],
      },
      error: {
        ...semantic.negative,
        main: semantic.negative[semanticVariations.main],
        light: semantic.negative[semanticVariations.lighter],
        dark: semantic.negative[semanticVariations.darker],
        contrastText: isColorDark(
          semantic.negative[semanticVariations.main],
          semantic.negative[semanticVariations.darker],
        )
          ? text[neutralVariations.light1]
          : text[neutralVariations.dark1],
      },
      warning: {
        ...semantic.warning,
        main: semantic.warning[semanticVariations.main],
        light: semantic.warning[semanticVariations.lighter],
        dark: semantic.warning[semanticVariations.darker],
        contrastText: isColorDark(
          semantic.warning[semanticVariations.main],
          semantic.warning[semanticVariations.darker],
        )
          ? text[neutralVariations.light1]
          : text[neutralVariations.dark1],
      },
      info: {
        ...semantic.info,
        main: semantic.info[semanticVariations.main],
        light: semantic.info[semanticVariations.lighter],
        dark: semantic.info[semanticVariations.darker],
        contrastText: isColorDark(
          semantic.info[semanticVariations.main],
          semantic.info[semanticVariations.darker],
        )
          ? text[neutralVariations.light1]
          : text[neutralVariations.dark1],
      },
      success: {
        ...semantic.positive,
        main: semantic.positive[semanticVariations.main],
        light: semantic.positive[semanticVariations.lighter],
        dark: semantic.positive[semanticVariations.darker],
        contrastText: isColorDark(
          semantic.positive[semanticVariations.main],
          semantic.positive[semanticVariations.darker],
        )
          ? text[neutralVariations.light1]
          : text[neutralVariations.dark1],
      },
      mode: isDarkMode ? 'dark' : 'light',
      grey: background,
      text: {
        // This is used for non-typography text, e.g. text inside <Chip>, <Slider>, etc.
        primary: text[isDarkMode ? neutralVariations.light1 : neutralVariations.dark1],
        secondary: text[isDarkMode ? neutralVariations.lighter : neutralVariations.darker],
        disabled: text[neutralVariations.main],
      },
      divider: divider[isDarkMode ? neutralVariations.darker : neutralVariations.lighter],
      action: {
        active: accent[mainColorVariations.main],
        hover: accent[isDarkMode ? mainColorVariations.darker : mainColorVariations.lighter],
        selected: accent[mainColorVariations.main],
        disabled: disabled[neutralVariations.main],
        disabledBackground:
          disabled[isDarkMode ? neutralVariations.darker : neutralVariations.lighter],
        focus: accent[isDarkMode ? mainColorVariations.darker : mainColorVariations.lighter],
      },
      background: {
        default: background[isDarkMode ? neutralVariations.dark2 : neutralVariations.light1],
        paper: background[isDarkMode ? neutralVariations.dark1 : neutralVariations.light2],
      },
    },
    typography: {
      fontSize: typographyGlobalFontSize,
      allVariants: {
        color: text[isDarkMode ? neutralVariations.light1 : neutralVariations.dark1],
      },
      caption: {
        color: text[isDarkMode ? neutralVariations.light2 : neutralVariations.dark2],
      },
      subtitle1: {
        color: text[isDarkMode ? neutralVariations.light2 : neutralVariations.dark2],
      },
      subtitle2: {
        color: text[isDarkMode ? neutralVariations.light2 : neutralVariations.dark2],
      },
    },
    ...(components ? { components } : {}),
  };
}

export function getMUIThemeFromColorPalette(
  colorPalette: ColorPalette,
  colorSchemeOptions: ColorSchemeOptions,
  options?: GetThemeOptions,
): Theme {
  return createTheme(getMUIThemeOptions(colorPalette, colorSchemeOptions, options));
}

export function getDefaultMUITheme(): Theme {
  return createTheme();
}
