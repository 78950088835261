import { useContext, useMemo } from 'react';
import { Box, Typography, TypographyTypeMap } from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import { AppUIContext } from '../../../utils/appUIContext';
import { isColorDark } from '../../../utils/colors/colorUtils';

const BUBBLE_SIZE_DESKTOP_PX = 175;
const BUBBLE_SIZE_MOBILE_PX = 75;
const BUBBLE_ICON_SIZE_DESKTOP_PX = 70;
const BUBBLE_ICON_SIZE_MOBILE_PX = 35;
const MAX_TEXT_WIDTH_PX = 1000;

interface Props {
  children: TypographyTypeMap['props']['children'];
  icon?: keyof typeof MUIIcon;
  index: number;
}

export default function ColorBubbleListItem(props: Props) {
  const { index, icon } = props;

  const {
    responsiveBreakpoints: { uiSize },
    uiColorPalette,
  } = useContext(AppUIContext);

  const isMobileView = uiSize === 'smallMobile';

  const { bubbleColor, bubbleContentNumberOrIcon } = useMemo(() => {
    const { neutrals: neutralVariations, mainColor: mainColorVariations } =
      uiColorPalette.variationValues;
    const bubbleColorList = [
      uiColorPalette.accent[mainColorVariations.lighter],
      ...(uiColorPalette.secondary ? [uiColorPalette.secondary[mainColorVariations.lighter]] : []),
      ...uiColorPalette.otherHarmonyColors.map(
        (otherHarmonyColor) => otherHarmonyColor[mainColorVariations.lighter],
      ),
    ];
    const bubbleColor = bubbleColorList[index % bubbleColorList.length];
    const bubbleTextColor = isColorDark(bubbleColor)
      ? uiColorPalette.text[neutralVariations.light1]
      : uiColorPalette.text[neutralVariations.dark1];
    if (icon) {
      const BubbleIcon = MUIIcon[icon];
      return {
        bubbleColor,
        bubbleContentNumberOrIcon: (
          <BubbleIcon
            color="inherit"
            style={{
              fontSize: isMobileView ? BUBBLE_ICON_SIZE_MOBILE_PX : BUBBLE_ICON_SIZE_DESKTOP_PX,
            }}
          />
        ),
      };
    }
    return {
      bubbleColor,
      bubbleContentNumberOrIcon: (
        <Typography
          color={bubbleTextColor}
          fontWeight="medium"
          variant={isMobileView ? 'h3' : 'h1'}
        >
          {index + 1}
        </Typography>
      ),
    };
  }, [icon, index, isMobileView, uiColorPalette]);

  const isFlipped = index % 2 !== 0 && !isMobileView;
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={isFlipped ? 'row-reverse' : 'row'}
      gap={5}
    >
      <Box
        alignItems="center"
        bgcolor={bubbleColor}
        borderRadius="50%"
        display="flex"
        flexDirection="row"
        height={isMobileView ? BUBBLE_SIZE_MOBILE_PX : BUBBLE_SIZE_DESKTOP_PX}
        justifyContent="center"
        minWidth={isMobileView ? BUBBLE_SIZE_MOBILE_PX : BUBBLE_SIZE_DESKTOP_PX}
        width={isMobileView ? BUBBLE_SIZE_MOBILE_PX : BUBBLE_SIZE_DESKTOP_PX}
        style={{
          boxShadow: `0 0 20px 0 ${bubbleColor}`,
          transform: isMobileView ? undefined : `rotate(${isFlipped ? '' : '-'}10deg)`,
        }}
      >
        {bubbleContentNumberOrIcon}
      </Box>
      <Typography maxWidth={MAX_TEXT_WIDTH_PX} textAlign={isFlipped ? 'right' : 'left'}>
        {props.children}
      </Typography>
    </Box>
  );
}
