import { Box, Typography, TypographyTypeMap } from '@mui/material';
import { useContext, useMemo } from 'react';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import TuneIcon from '@mui/icons-material/Tune';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import { AppUIContext } from '../../utils/appUIContext';

const SECTION_DESKTOP_WIDTH_PX = 500;
const SECTION_ICONS_MAP = {
  AUTOMATE: AutoModeIcon,
  CUSTOMIZE: TuneIcon,
  PREVIEW: AppShortcutIcon,
} as const;
const SECTION_ICON_SIZE = 50;

interface Props {
  children: TypographyTypeMap['props']['children'];
  color?: 'main' | 'secondary' | 'tertiary';
  icon?: keyof typeof SECTION_ICONS_MAP;
  title: string;
}
export default function ContentSection(props: Props) {
  const { children, color, icon, title } = props;
  const {
    responsiveBreakpoints: { uiSize },
    uiColorPalette,
  } = useContext(AppUIContext);

  const IconComponent = icon ? SECTION_ICONS_MAP[icon] : undefined;
  const iconColor = useMemo(() => {
    if (!icon || !color) {
      return undefined;
    }
    const mainVariations = uiColorPalette.variationValues.mainColor;
    switch (color) {
      case 'main':
        return uiColorPalette.accent[mainVariations.main];
      case 'secondary':
        if (uiColorPalette.secondary) {
          return uiColorPalette.secondary[mainVariations.main];
        }
        // TODO: may want to clean this up, so that the top 3 colors are always in order
        // even if the `secondary` isn't selected for some reason
        return uiColorPalette.accent[mainVariations.darker];
      case 'tertiary':
        if (uiColorPalette.otherHarmonyColors.length > 0) {
          return uiColorPalette.otherHarmonyColors[0][mainVariations.main];
        }
        return uiColorPalette.accent[mainVariations.darker];
    }
  }, [color, icon, uiColorPalette]);

  return (
    <Box
      borderRadius={2}
      boxShadow={2}
      maxWidth={SECTION_DESKTOP_WIDTH_PX}
      padding={3}
      width={uiSize === 'smallMobile' ? '100%' : SECTION_DESKTOP_WIDTH_PX}
    >
      <Box alignItems="center" color={iconColor} display="flex" flexDirection="row" gap={1.5}>
        {IconComponent && <IconComponent color="inherit" style={{ fontSize: SECTION_ICON_SIZE }} />}
        <Typography fontWeight="light" textAlign="left" variant="h4">
          {title}
        </Typography>
      </Box>
      <Typography lineHeight={1.75} marginTop={2} textAlign="left" variant="body1">
        {children}
      </Typography>
    </Box>
  );
}
