import React, { useContext, useMemo, useRef, useState } from 'react';
import { Box, IconButton, Link, Popover, Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import WarningIcon from '@mui/icons-material/Warning';
import { ChromePicker, ColorResult } from 'react-color';
import {
  getColorBrightnessLevel,
  getTertiaryColorOrNextBestOption,
  isColorDark,
} from '../../utils/colors/colorUtils';
import { copyTextToClipboard, getRandomUniqueKey } from '../../utils/utils';
import { ColorVariationShade, ColorWithVariations } from '../../utils/colors/colorPalette';
import { AppUIContext, SCREENS } from '../../utils/appUIContext';

const BOX_SIZE = 100;
const BOX_WIDTH_DOUBLE = 200;
const BOX_HEIGHT_HALF = 50;
const BOX_WIDTH_PADDING = 50;
const BOX_BORDER_WIDTH = 1;
const BOX_BORDER_RADIUS = 2.5;
const BOX_BORDER_RADIUS_PX = BOX_BORDER_RADIUS * 4;

type ColorInput = string | ColorWithVariations;
interface SingleColorInput {
  color: ColorInput;
  halfHeight?: boolean; // box is only half as high as normal, only works with single colors
  selectedColorIndex?: undefined;
}
interface MultipleColorsInput {
  colors: ColorInput[];
  halfHeight?: undefined;
  selectedColorIndex?: number | number[] | 'ALL';
}

type Props = (SingleColorInput | MultipleColorsInput) & {
  allowEdit?: boolean;
  bold?: boolean; // make the label bold
  compact?: boolean; // no additional width, short labels may wrap
  containerStyle?: React.CSSProperties;
  doubleWidth?: boolean;
  fluidWidth?: boolean; // if true, width of container will not be restricted (opposite of "compact")
  label?: string;
  paddingY?: number;
  showAllVariations?: boolean; // render all 9 (instead of 5) colors if input is ColorWithVariations
  showColorCode?: boolean; // show copyable hex code over the box
  showWarningIfColorIsTooLightOrDark?: boolean;
  onEdit?: (color: ColorResult) => void;
};

export default function ColorSampleBox(props: Props) {
  const { uiColorPalette, onGoToScreen } = useContext(AppUIContext);

  const rootComponentRef = useRef<Element | null>(null);

  const colors = useMemo(() => ('color' in props ? [props.color] : props.colors), [props]);

  const selectedColorIndices = useMemo(() => {
    if (props.selectedColorIndex == null) {
      return new Set<number>();
    }
    if (props.selectedColorIndex === 'ALL') {
      return 'ALL';
    }
    return new Set(
      Array.isArray(props.selectedColorIndex)
        ? props.selectedColorIndex
        : [props.selectedColorIndex],
    );
  }, [props.selectedColorIndex]);

  const selectedColorIndicatorColor = useMemo(
    () => getTertiaryColorOrNextBestOption(uiColorPalette),
    [uiColorPalette],
  );

  const [wasColorCodeCopied, setWasColorCodeCopied] = useState<boolean>(false);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const colorCodeLabel = useMemo(() => {
    if (!props.showColorCode || colors.length > 1) {
      return null;
    }
    const color = colors[0];
    const colorCode = typeof color === 'string' ? color : color[500];
    const colorCodeFormatted = colorCode.toUpperCase();
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left={0}
        position="absolute"
        top={0}
        width="100%"
      >
        <Link
          color={isColorDark(colorCode) ? uiColorPalette.text[100] : uiColorPalette.text[900]}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            copyTextToClipboard(colorCodeFormatted);
            setWasColorCodeCopied(true);
          }}
        >
          <Tooltip
            placement={props.halfHeight ? 'top' : 'bottom'}
            title={wasColorCodeCopied ? 'Copied' : 'Copy to clipboard'}
            onMouseEnter={() => setWasColorCodeCopied(false)}
          >
            <Typography color="inherit" variant="caption">
              {colorCodeFormatted}
            </Typography>
          </Tooltip>
        </Link>
      </Box>
    );
  }, [colors, props.halfHeight, props.showColorCode, uiColorPalette.text, wasColorCodeCopied]);

  const editButton = useMemo(() => {
    if (!props.allowEdit || colors.length > 1) {
      return null;
    }
    const color = colors[0];
    const colorCode = typeof color === 'string' ? color : color[500];
    return (
      <Box position="absolute" textAlign="center" top={0} width="100%">
        <Link
          aria-label="Open the color picker to choose a new color"
          color={isColorDark(colorCode) ? uiColorPalette.text[100] : uiColorPalette.text[900]}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowColorPicker((prev) => !prev);
          }}
        >
          <Typography color="inherit" fontWeight="bold" variant="caption">
            Edit color
          </Typography>
        </Link>
      </Box>
    );
  }, [colors, props.allowEdit, uiColorPalette.text]);

  const colorPicker = useMemo(() => {
    if (!showColorPicker || colors.length > 1) {
      return null;
    }
    const color = colors[0];
    const colorCode = typeof color === 'string' ? color : color[500];
    return (
      <Popover
        anchorEl={rootComponentRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showColorPicker}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={() => setShowColorPicker(false)}
      >
        <Box display="flex" flexDirection="column">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            paddingX={0.5}
          >
            <IconButton
              aria-label="Close the color selection popup"
              onClick={() => setShowColorPicker(false)}
            >
              <CloseIcon color="inherit" fontSize="small" />
            </IconButton>
            <Typography fontWeight="bold" variant="body2">
              Choose Color
            </Typography>
            <Tooltip placement="top" title={`Edit with ${SCREENS['Color'].shortTitle}`}>
              <IconButton
                aria-label="Edit this color using the Color Tool"
                onClick={() => onGoToScreen('Color')}
              >
                <LaunchIcon color="inherit" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <ChromePicker color={colorCode} disableAlpha onChange={props.onEdit} />
        </Box>
      </Popover>
    );
  }, [colors, onGoToScreen, props.onEdit, showColorPicker]);

  const boxUniqueId = useMemo(() => getRandomUniqueKey(), []);
  const colorBoxes: React.ReactNode[] = [];
  const boxWidth = props.doubleWidth ? BOX_WIDTH_DOUBLE : BOX_SIZE;
  colors.forEach((color, colorIndex) => {
    if (typeof color === 'string') {
      const tooLightOrDarkWarning = getColorBrightnessLevel(color);
      let warningTip: string | undefined = undefined;
      if (props.showWarningIfColorIsTooLightOrDark && tooLightOrDarkWarning === 'LIGHT') {
        warningTip =
          'This color might be too bright. Consider choosing a darker main color to reduce the brightness of your palette.';
      } else if (props.showWarningIfColorIsTooLightOrDark && tooLightOrDarkWarning === 'DARK') {
        warningTip =
          'This color might be too dark. Consider choosing a lighter main color to increase the brightness of your palette.';
      }
      colorBoxes.push(
        <Box
          key={`color_box_${boxUniqueId}_${colorIndex}`}
          bgcolor={color}
          border={BOX_BORDER_WIDTH}
          borderRadius={BOX_BORDER_RADIUS}
          borderRight={colorIndex === colors.length - 1 ? BOX_BORDER_WIDTH : 0}
          borderColor="divider" /* NOTE: borderColor has to come AFTER borderRight to apply to it as well */
          height={props.halfHeight ? BOX_HEIGHT_HALF : BOX_SIZE}
          position="relative"
          width={boxWidth / colors.length}
        >
          {colorCodeLabel}
          {editButton}
          {colorPicker}
          {warningTip && (
            <Box
              alignItems="center"
              bottom={0}
              display="flex"
              justifyContent="center"
              paddingRight={0.5}
              position="absolute"
              right={0}
            >
              <Tooltip placement="top" title={warningTip}>
                <WarningIcon color="warning" style={{ width: 15 }} />
              </Tooltip>
            </Box>
          )}
        </Box>,
      );
    } else {
      const variationShades: ColorVariationShade[] = props.showAllVariations
        ? [100, 200, 300, 400, 500, 600, 700, 800, 900]
        : [100, 300, 500, 700, 900];
      const innerBoxHeight = Math.ceil(BOX_SIZE / variationShades.length);
      const colorBoxKey = `color_box_${boxUniqueId}_${colorIndex}`;
      const isColorSelected =
        selectedColorIndices === 'ALL' || selectedColorIndices.has(colorIndex);
      const isFirstColor = colorIndex === 0;
      const isLastColor = colorIndex === colors.length - 1;
      colorBoxes.push(
        <Box
          key={colorBoxKey}
          border={BOX_BORDER_WIDTH}
          borderRight={colorIndex === colors.length - 1 ? BOX_BORDER_WIDTH : 0}
          display="flex"
          flexDirection="column"
          height={BOX_SIZE}
          position="relative"
          width={boxWidth / colors.length}
          style={{
            borderTopLeftRadius: isFirstColor ? BOX_BORDER_RADIUS_PX : 0,
            borderBottomLeftRadius: isFirstColor ? BOX_BORDER_RADIUS_PX : 0,
            borderTopRightRadius: isLastColor ? BOX_BORDER_RADIUS_PX : 0,
            borderBottomRightRadius: isLastColor ? BOX_BORDER_RADIUS_PX : 0,
          }}
        >
          {variationShades.map((shade, variationIndex) => {
            const isTopVariation = variationIndex === 0;
            const isBottomVariation = variationIndex === variationShades.length - 1;
            return (
              <Box
                key={`${colorBoxKey}_${shade}`}
                bgcolor={color[shade]}
                height={innerBoxHeight}
                style={{
                  borderTopLeftRadius: isFirstColor && isTopVariation ? BOX_BORDER_RADIUS_PX : 0,
                  borderBottomLeftRadius:
                    isFirstColor && isBottomVariation ? BOX_BORDER_RADIUS_PX : 0,
                  borderTopRightRadius: isLastColor && isTopVariation ? BOX_BORDER_RADIUS_PX : 0,
                  borderBottomRightRadius:
                    isLastColor && isBottomVariation ? BOX_BORDER_RADIUS_PX : 0,
                }}
              />
            );
          })}
          {colorCodeLabel}
          {editButton}
          {colorPicker}
          {isColorSelected && (
            <Box bottom={0} display="flex" justifyContent="center" position="absolute" width="100%">
              <Box
                alignItems="center"
                borderRadius="50%"
                display="flex"
                height={18}
                justifyContent="center"
                marginBottom={0.5}
                padding={0.5}
                width={18}
                style={{
                  background: `radial-gradient(${selectedColorIndicatorColor[400]}, ${selectedColorIndicatorColor[600]}90)`,
                }}
              >
                <CheckCircleOutlineIcon fontSize="small" />
              </Box>
            </Box>
          )}
        </Box>,
      );
    }
  });

  const containerWidth = useMemo(() => {
    const colorBoxWidth = props.doubleWidth ? BOX_WIDTH_DOUBLE : BOX_SIZE;
    if (props.fluidWidth) {
      return undefined;
    }
    if (props.compact) {
      return colorBoxWidth;
    }
    return colorBoxWidth + BOX_WIDTH_PADDING;
  }, [props.compact, props.doubleWidth, props.fluidWidth]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      paddingX={1}
      paddingY={props.paddingY}
      ref={rootComponentRef}
      style={props.containerStyle}
      width={containerWidth}
    >
      <Box display="flex" flexDirection="row">
        {colorBoxes}
      </Box>
      {props.label && (
        <Typography paddingTop={1} textAlign="center" variant="body2">
          {props.bold ? <b>{props.label}</b> : props.label}
        </Typography>
      )}
    </Box>
  );
}
