import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DIALOG_ACTIONS_STYLE } from '../../../ui/uiStyles';
import ExportPalette from './ExportPalette';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function ExportPaletteDialog(props: Props) {
  const { isOpen, onClose } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Save and share your palette</DialogTitle>
      <DialogContent style={{ margin: 0, padding: 0 }}>
        <ExportPalette />
      </DialogContent>
      <DialogActions style={DIALOG_ACTIONS_STYLE}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
