import { createContext } from 'react';
import * as MUIIcon from '@mui/icons-material';
import { Theme } from '@emotion/react';
import ColorTool from '../screens/ColorTool';
import HarmonyTool from '../screens/HarmonyTool';
import FinalPalette from '../screens/FinalPalette';
import { getColorPalette } from './colors/colorUtils';
import { DEFAULT_SETTINGS, Settings } from './settings';
import { getMUIThemeFromColorPalette } from './muiTheme';
import {
  DEFAULT_COLOR_SCHEME_OPTIONS,
  DEFAULT_COLOR_SCHEME_STATE,
} from './colors/colorSchemeState';
import { ColorPalette } from './colors/colorPalette';
import WelcomeScreen from '../screens/WelcomeScreen';
import { ResponsiveUIBreakpoints } from './useResponsiveUIBreakpoints';

interface ScreenConfig {
  component: () => React.ReactElement;
  icon: keyof typeof MUIIcon;
  route: `/${string}`; // any string but must start with '/'
  shortTitle: string;
  title: string;
}

export type ScreenName = 'Home' | 'Color' | 'Harmony' | 'Palette';
export const SCREENS: Record<ScreenName, ScreenConfig> = {
  Home: {
    component: WelcomeScreen,
    icon: 'Home',
    route: '/',
    shortTitle: 'Home', // NOTE: not used
    title: 'Welcome Screen', // NOTE: not used
  },
  Color: {
    component: ColorTool,
    icon: 'Colorize',
    route: '/color',
    shortTitle: 'Color Tool',
    title: 'Color Selection Tool',
  },
  Harmony: {
    component: HarmonyTool,
    icon: 'Looks',
    route: '/harmony',
    shortTitle: 'Harmony Tool',
    title: 'Color Harmony Tool',
  },
  Palette: {
    component: FinalPalette,
    icon: 'Palette',
    route: '/palette',
    shortTitle: 'Palette Editor',
    title: 'Color Palette Editor',
  },
};
// TODO: consider making this an enum instead, to avoid untyped strings everywhere
export const SCREEN_NAMES = Object.keys(SCREENS) as ScreenName[];

export function getScreenNameFromPath(path: string): ScreenName {
  return SCREEN_NAMES.find((screenName) => SCREENS[screenName].route === path) ?? 'Home';
}

export type GoToScreenState =
  | null
  | undefined
  | {
      flowMode?: boolean;
    };

interface AppUIContextType {
  currentScreen: ScreenName;
  responsiveBreakpoints: ResponsiveUIBreakpoints;
  settings: Settings;
  uiColorPalette: ColorPalette;
  uiTheme: Theme;
  onGoToScreen: (screenName: ScreenName, flowMode?: boolean) => void;
  onSettingsChanged: (updates: Partial<Settings>) => void;
}

export const AppUIContext = createContext<AppUIContextType>({
  currentScreen: 'Home',
  responsiveBreakpoints: {
    isMobileDevice: false,
    uiSize: 'largeDesktop',
    windowWidth: 1800,
  },
  settings: DEFAULT_SETTINGS,
  uiColorPalette: getColorPalette(DEFAULT_COLOR_SCHEME_STATE),
  uiTheme: getMUIThemeFromColorPalette(
    getColorPalette(DEFAULT_COLOR_SCHEME_STATE),
    DEFAULT_COLOR_SCHEME_OPTIONS,
  ),
  onGoToScreen: () => {
    throw new Error('[onGoToScreen] This function has not yet been defined');
  },
  onSettingsChanged: () => {
    throw new Error('[onSettingsChanged] This function has not yet been defined');
  },
});
