import { ColorWithVariations } from '../../../utils/colors/colorPalette';
import { ColorHarmonySettings } from '../../../utils/colors/colorSchemeState';
import ColorSelectorDropdown from './ColorSelectorDropdown';

interface Props {
  colorHarmony: ColorHarmonySettings;
  isDialog?: boolean;
  isMobileView?: boolean;
  secondaryColorOptions: ColorWithVariations[];
  onColorHarmonyChange: (updates: Partial<ColorHarmonySettings>) => void;
}

export default function SecondaryColorDropdown(props: Props) {
  const { colorHarmony, isDialog, isMobileView, secondaryColorOptions, onColorHarmonyChange } =
    props;

  if (secondaryColorOptions.length <= 1) {
    return null;
  }

  return (
    <ColorSelectorDropdown
      ariaLabel="Choose which harmony color will be used as your secondary color (cannot be the same as your accent color)"
      disabledIndex={colorHarmony.accentColorIndex}
      isDialog={isDialog}
      isMobileView={isMobileView}
      label="Secondary color"
      noOptionLabel="None"
      options={secondaryColorOptions}
      selectedIndex={colorHarmony.secondaryColorIndex}
      tip={
        'A secondary color can be used for special or critical actions that are not common in your UI, such as a "purchase" button. It can serve as your 10% color.'
      }
      onChange={(newIndex: ColorHarmonySettings['secondaryColorIndex']) =>
        onColorHarmonyChange({
          secondaryColorIndex: newIndex,
        })
      }
    />
  );
}
