export interface ColorWithVariations {
  100: string; // light
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string; // dark
}
export type ColorVariationShade = keyof ColorWithVariations;
export const COLOR_VARIATION_OPTIONS: readonly ColorVariationShade[] = [
  100, 200, 300, 400, 500, 600, 700, 800, 900,
] as const;

export interface VariationValuesSet {
  main: ColorVariationShade; // middle (default) shade
  lighter: ColorVariationShade; // lighter than main (more light w/ higher contrast)
  darker: ColorVariationShade; // darker than main (more dark w/ higher contrast)
}

export interface VariationValuesSetExtended extends VariationValuesSet {
  dark1: ColorVariationShade; // darkest shade
  dark2: ColorVariationShade; // second-darkest shade (lighter w/ higher contrast)
  light1: ColorVariationShade; // lightest shade
  light2: ColorVariationShade; // second-lightest shade (darker w/ higher contrast)
}

export type VariationValuesSetKey = keyof VariationValuesSetExtended;

export function getLabelForVariation(
  key: VariationValuesSetKey,
  isForNeutrals = false,
): {
  key: VariationValuesSetKey;
  label: string;
  labelForCode: string;
} {
  switch (key) {
    case 'main':
      return {
        key,
        label: isForNeutrals ? 'Mid' : 'Main',
        labelForCode: isForNeutrals ? 'mid' : 'main',
      };
    case 'light1':
      return { key, label: 'Light 1', labelForCode: 'light1' };
    case 'light2':
      return { key, label: 'Light 2', labelForCode: 'light2' };
    case 'lighter':
      return { key, label: 'Lighter', labelForCode: 'lighter' };
    case 'dark1':
      return { key, label: 'Dark 1', labelForCode: 'dark1' };
    case 'dark2':
      return { key, label: 'Dark 2', labelForCode: 'dark2' };
    case 'darker':
      return { key, label: 'Darker', labelForCode: 'darker' };
  }
}

export function getLabelMapForVariations(
  variationValues: VariationValuesSet | VariationValuesSetExtended,
) {
  const isNeutralsRow = 'dark1' in variationValues;
  return (Object.keys(variationValues) as VariationValuesSetKey[]).reduce(
    (acc, nextVariationKey) => {
      // Need to cast to `as VariationValuesSetExtended` since it can be any of those keys
      const nextVariation = (variationValues as VariationValuesSetExtended)[nextVariationKey];
      if (nextVariation) {
        acc.set(nextVariation, getLabelForVariation(nextVariationKey, isNeutralsRow));
      }
      return acc;
    },
    new Map<
      ColorVariationShade,
      {
        key: VariationValuesSetKey;
        label: string;
      }
    >(),
  );
}

export interface ColorPalette {
  // App colors + variations:
  accent: ColorWithVariations;
  secondary?: ColorWithVariations;
  otherHarmonyColors: ColorWithVariations[];
  text: ColorWithVariations; // Neutral text colors
  background: ColorWithVariations; // Neutral background colors
  disabled: ColorWithVariations; // Neutral (gray) disabled element colors
  divider: ColorWithVariations; // Neutral (gray) divider or border colors
  icon: ColorWithVariations; // Secondary (or possibly neutral) icon colors
  semantic: {
    info: ColorWithVariations; // Blue semantic "info" color tuned to the primary color scheme
    negative: ColorWithVariations; // Red semantic "negative" color tuned to the primary color scheme
    positive: ColorWithVariations; // Green semantic "positive" color tuned to the primary color scheme
    special: ColorWithVariations; // Purple semantic "special" color tuned to the primary color scheme
    warning: ColorWithVariations; // Orange or yellow semantic "warning" color tuned to the primary color scheme
  };
  // Pure colors, usually pure white or black, but can be somewhat lighter/darker versions of
  // neutrals[100] and neutrals[900], respectively:
  black: string;
  white: string;
  // Reference palette - to visualize the palettes used in part or in full above:
  referencePalette: {
    main: ColorWithVariations;
    harmony: ColorWithVariations[];
    neutrals: ColorWithVariations[];
  };
  variationValues: {
    mainColor: VariationValuesSet;
    neutrals: VariationValuesSetExtended;
    semantic: VariationValuesSet;
  };
}
