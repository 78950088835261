import { useCallback } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  COLOR_HARMONY_OPTIONS,
  ColorHarmonyOption,
  ColorHarmonyOptionKeysAndLabels,
} from '../../../utils/colors/colorSchemeState';
import useControlBoxDropdownSelectorWidth from './useControlBoxDropdownSelectorWidth';

export default function ColorHarmonyDropdown({
  isDialog,
  isMobileView,
  selection,
  onChange,
}: {
  isDialog?: boolean;
  isMobileView?: boolean;
  selection?: ColorHarmonyOption;
  onChange: (newSelection: ColorHarmonyOption) => void;
}) {
  const updateHarmonySelection = useCallback(
    (event: SelectChangeEvent) => onChange(event.target.value as ColorHarmonyOption),
    [onChange],
  );

  const { width } = useControlBoxDropdownSelectorWidth(isDialog, isMobileView);

  return (
    <FormControl sx={{ width }}>
      <InputLabel>Color harmony</InputLabel>
      <Select
        aria-label="Choose a color harmony rule for your palette"
        label="Color harmony"
        value={selection}
        onChange={updateHarmonySelection}
      >
        {COLOR_HARMONY_OPTIONS.map((harmonyOption) => (
          <MenuItem key={`harmony_selector_option_${harmonyOption}`} value={harmonyOption}>
            {ColorHarmonyOptionKeysAndLabels[harmonyOption]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
