import { Box, Typography } from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import { useContext, useMemo } from 'react';
import { getRandomUniqueKey } from '../../utils/utils';
import { AppUIContext } from '../../utils/appUIContext';
import { getSecondaryColorOrNextBestOption } from '../../utils/colors/colorUtils';

interface Props {
  features: {
    icon: keyof typeof MUIIcon;
    isHighlighted?: boolean;
    isSpecial?: boolean;
    label: string;
  }[];
}

export default function FeatureList(props: Props) {
  const { features } = props;
  const { uiColorPalette } = useContext(AppUIContext);

  const featureBoxes = useMemo(() => {
    const { mainColor: mainColorVariations } = uiColorPalette.variationValues;
    return features.map((feature) => {
      const Icon = MUIIcon[feature.icon];
      let iconColor: string | undefined = undefined; // default typography color
      if (feature.isSpecial) {
        iconColor = getSecondaryColorOrNextBestOption(uiColorPalette)[mainColorVariations.main];
      } else if (feature.isHighlighted) {
        iconColor = uiColorPalette.accent[mainColorVariations.main];
      }
      return (
        <Box
          alignItems="center"
          borderRadius={2}
          boxShadow={1}
          color={iconColor}
          display="flex"
          flexDirection="row"
          key={`feature_list_item_${getRandomUniqueKey()}`}
          padding={2}
        >
          <Icon color="inherit" fontSize="medium" />
          <Typography
            fontWeight={feature.isSpecial ? 'semibold' : 'light'}
            marginLeft={1.25}
            variant="body1"
          >
            {feature.label}
          </Typography>
        </Box>
      );
    });
  }, [features, uiColorPalette]);

  return (
    <Box display="flex" flexDirection="row" gap={3} flexWrap="wrap" justifyContent="center">
      {featureBoxes}
    </Box>
  );
}
