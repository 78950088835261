import { useCallback, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Link, Menu, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export interface RandomizeColorOptions {
  lockHue: boolean;
  suitableBaseColorsOnly: boolean;
}

interface Props {
  baseColorNameLowercase: string;
  options: RandomizeColorOptions;
  onOptionsChange: (newOptions: RandomizeColorOptions) => void;
}

export default function RandomizeColorOptionsMenuButton(props: Props) {
  const { baseColorNameLowercase, options, onOptionsChange } = props;

  const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!menuAnchorElement;

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    setMenuAnchorElement(event.currentTarget);
  }, []);

  return (
    <>
      <Menu
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={() => setMenuAnchorElement(null)}
      >
        <Box paddingX={2.5}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={options.lockHue}
                  onChange={(e) => onOptionsChange({ ...options, lockHue: e.target.checked })}
                />
              }
              label={`Lock base hue color (${baseColorNameLowercase})`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={options.suitableBaseColorsOnly}
                  onChange={(e) =>
                    onOptionsChange({ ...options, suitableBaseColorsOnly: e.target.checked })
                  }
                />
              }
              label="Optimal UI colors only"
            />
          </FormGroup>
        </Box>
      </Menu>
      <Link
        aria-label="Open the randomize options menu"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        onClick={handleOpenMenu}
      >
        <Typography
          alignItems="center"
          color="inherit"
          display="flex"
          flexDirection="row"
          fontWeight="bold"
          variant="caption"
        >
          Options
          {isMenuOpen ? <ArrowDropDownIcon color="inherit" /> : <ArrowRightIcon color="inherit" />}
        </Typography>
      </Link>
    </>
  );
}
