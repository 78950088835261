import { MutableRefObject, useCallback, useContext } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { DIALOG_ACTIONS_STYLE } from '../../ui/uiStyles';
import { getRandomColorSchemeState } from '../../utils/colors/colorSchemeState';
import { UserPaletteContext } from '../../utils/userPaletteContext';
import { AppUIContext } from '../../utils/appUIContext';

export type RandomizeFunctionRef = (() => void) | null | undefined;

interface Props {
  isOpen: boolean;
  randomizeFunctionRef?: MutableRefObject<RandomizeFunctionRef>;
  onClose: () => void;
}

export default function RandomizePaletteDialog(props: Props) {
  const { isOpen, randomizeFunctionRef, onClose } = props;

  const { settings, onSettingsChanged } = useContext(AppUIContext);
  const { colorSchemeState, onColorSchemeStateChanged } = useContext(UserPaletteContext);

  const handleRandomizeColorScheme = useCallback(() => {
    onColorSchemeStateChanged(
      getRandomColorSchemeState(colorSchemeState, {
        randomizeHarmonyColors: settings.randomizeHarmonyColors,
        randomizeOptions: settings.randomizeOptions,
      }),
    );
    onClose();
  }, [
    colorSchemeState,
    onClose,
    onColorSchemeStateChanged,
    settings.randomizeHarmonyColors,
    settings.randomizeOptions,
  ]);

  if (randomizeFunctionRef) {
    randomizeFunctionRef.current = handleRandomizeColorScheme;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Randomize palette?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Warning: This action will reset and randomize all of your palette color selections and
          options. You can download the palette or copy the URL to save it first.
        </DialogContentText>
        <DialogContentText display="flex" flexDirection="column" marginTop={1}>
          <FormControlLabel
            control={
              <Checkbox
                aria-label="Randomize the color harmony options when randomizing the color palette"
                checked={settings.randomizeHarmonyColors}
                onChange={(_e, checked) => onSettingsChanged({ randomizeHarmonyColors: checked })}
              />
            }
            label="Randomize main and harmony colors"
          />
          <FormControlLabel
            control={
              <Checkbox
                aria-label="Randomize the theme options when randomizing the color palette"
                checked={settings.randomizeOptions}
                onChange={(_e, checked) => onSettingsChanged({ randomizeOptions: checked })}
              />
            }
            label="Randomize theme options"
          />
          <FormControlLabel
            control={
              <Checkbox
                aria-label="Do not show this warning dialog again for subsequent randomize actions"
                checked={!settings.randomizeWarning}
                onChange={(_e, checked) => onSettingsChanged({ randomizeWarning: !checked })}
              />
            }
            label={
              !settings.randomizeWarning
                ? "Don't show this warning again (reset from Settings menu)"
                : "Don't show this warning again"
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={DIALOG_ACTIONS_STYLE}>
        <Button aria-label="Cancel randomize palette and close this dialog menu" onClick={onClose}>
          Cancel
        </Button>
        <Button
          aria-label="Accept warnings and reset and randomize your color palette"
          autoFocus
          color="error"
          variant="contained"
          onClick={handleRandomizeColorScheme}
        >
          Reset and randomize
        </Button>
      </DialogActions>
    </Dialog>
  );
}
