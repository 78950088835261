import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { AppUIContext, SCREENS, ScreenName } from '../../utils/appUIContext';

interface Props {
  screen: ScreenName;
}

export default function RouteLink(props: Props) {
  const { screen } = props;

  const { uiColorPalette } = useContext(AppUIContext);
  const linkColor = uiColorPalette.accent[uiColorPalette.variationValues.mainColor.main];

  return (
    <Link style={{ textDecorationColor: linkColor }} to={SCREENS[screen].route}>
      <Typography
        style={{
          color: linkColor,
        }}
        component="span"
        display="inline"
        fontWeight="bold"
      >
        {SCREENS[screen].title}
      </Typography>
    </Link>
  );
}
