import { useContext } from 'react';
import { ColorResult } from 'react-color';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ColorSampleBox from '../../shared/ColorSampleBox';
import {
  ColorHarmonyOption,
  ColorHarmonySettings,
  OPTION_NOT_SELECTED,
} from '../../../utils/colors/colorSchemeState';
import { ColorPalette, ColorWithVariations } from '../../../utils/colors/colorPalette';
import { DIALOG_ACTIONS_STYLE } from '../../../ui/uiStyles';
import { AppUIContext, SCREENS } from '../../../utils/appUIContext';
import ColorHarmonyDropdown from './ColorHarmonyDropdown';
import PrimaryColorBox from './PrimaryColorBox';
import AccentColorDropdown from './AccentColorDropdown';
import SecondaryColorDropdown from './SecondaryColorDropdown';
import NeutralsBaseColorDropdown from './NeutralsBaseColorDropdown';

export default function FullColorPaletteSettingsDialog({
  allColorsWithVariations,
  colorHarmony,
  colorPalette,
  isOpen,
  mainColorHex,
  onClose,
  onColorHarmonyChange,
  onHarmonySettingsUpdate,
  onMainColorChange,
}: {
  allColorsWithVariations: ColorWithVariations[];
  colorHarmony: ColorHarmonySettings;
  colorPalette: ColorPalette;
  isOpen: boolean;
  mainColorHex: string;
  onClose: () => void;
  onColorHarmonyChange: (newHarmony: ColorHarmonyOption) => void;
  onHarmonySettingsUpdate: (updates: Partial<ColorHarmonySettings>) => void;
  onMainColorChange: (newColor: ColorResult) => void;
}) {
  const { onGoToScreen } = useContext(AppUIContext);

  const ColorToolIcon = MUIIcon[SCREENS.Color.icon];
  const HarmonyToolIcon = MUIIcon[SCREENS.Harmony.icon];

  return (
    <Dialog open={isOpen} maxWidth={false} onClose={onClose}>
      <DialogTitle>Adjust your palette colors</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a main (brand) color and then use the color harmony rules to generate all other
          palette colors. Click the buttons for <i>{SCREENS.Color.shortTitle}</i> or{' '}
          <i>{SCREENS.Harmony.shortTitle}</i> below for a more comprehensive guided flow for
          choosing the best options.
        </DialogContentText>
        <Box marginTop={3}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            marginBottom={5}
            gap={2}
          >
            <PrimaryColorBox color={mainColorHex} onEdit={onMainColorChange} />
            <AddIcon fontSize="large" />
            <ColorHarmonyDropdown
              isDialog
              selection={colorHarmony.selectedHarmony}
              onChange={onColorHarmonyChange}
            />
            <AddIcon fontSize="large" />
            <AccentColorDropdown
              accentColorOptions={allColorsWithVariations}
              colorHarmony={colorHarmony}
              isDialog
              onColorHarmonyChange={onHarmonySettingsUpdate}
            />
            <SecondaryColorDropdown
              colorHarmony={colorHarmony}
              isDialog
              secondaryColorOptions={allColorsWithVariations}
              onColorHarmonyChange={onHarmonySettingsUpdate}
            />
            <NeutralsBaseColorDropdown
              colorHarmony={colorHarmony}
              colorOptions={allColorsWithVariations}
              isDialog
              onColorHarmonyChange={onHarmonySettingsUpdate}
            />
            <DoubleArrowIcon fontSize="large" />
            <ColorSampleBox
              bold
              color={colorPalette.referencePalette.main}
              label="Main"
              showAllVariations
            />
            <ColorSampleBox
              bold
              colors={colorPalette.referencePalette.harmony}
              doubleWidth
              label="Accent"
              selectedColorIndex={colorHarmony.accentColorIndex}
              showAllVariations
            />
            <ColorSampleBox
              bold
              colors={colorPalette.referencePalette.harmony}
              doubleWidth
              label="Secondary"
              selectedColorIndex={
                colorHarmony.secondaryColorIndex === OPTION_NOT_SELECTED
                  ? undefined
                  : colorHarmony.secondaryColorIndex
              }
              showAllVariations
            />
            <ColorSampleBox
              bold
              colors={colorPalette.referencePalette.neutrals}
              doubleWidth
              label="Neutrals"
              selectedColorIndex={
                colorHarmony.neutralColorIndex === OPTION_NOT_SELECTED
                  ? undefined
                  : colorHarmony.neutralColorIndex
              }
              showAllVariations
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={DIALOG_ACTIONS_STYLE}>
        <Tooltip placement="bottom" title="Go to guided flow to choose a new main (brand) color">
          <Button
            aria-label="Go to main color selection step"
            startIcon={<ColorToolIcon />}
            variant="outlined"
            onClick={() => onGoToScreen('Color')}
          >
            {SCREENS.Color.shortTitle}
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" title="Go to guided flow to choose the best color harmony rule">
          <Button
            aria-label="Go to color harmony selection step"
            startIcon={<HarmonyToolIcon />}
            variant="outlined"
            onClick={() => onGoToScreen('Harmony')}
          >
            {SCREENS.Harmony.shortTitle}
          </Button>
        </Tooltip>
        <Button aria-label="Close this dialog menu" autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
