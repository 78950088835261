import { useMemo, useState } from 'react';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { getBaseColorName, getEmotionDescriptionFromColorName } from '../../utils/colors/colorInfo';
import {
  getHSLFromColorHex,
  getHSVFromColorHex,
  getRGBFromColorHex,
  isColorSuitableAsBaseColor,
} from '../../utils/colors/colorUtils';
import BrandExampleList from '../shared/BrandExampleList';
import { addAlphaToColor } from '../../ui/uiColors';
import ColorSampleBox from '../shared/ColorSampleBox';
import { copyTextToClipboard } from '../../utils/utils';

const INFO_BOX_BACKGROUND_ALPHA = 0.1;

function ColorValuesRow({
  copyInstructions,
  copyValue,
  displayValue,
  title,
}: {
  copyInstructions: string;
  copyValue: string;
  displayValue: string;
  title: string;
}) {
  const [wasValueCopied, setWasValueCopied] = useState(false);

  return (
    <Link
      // color={isColorDark(colorCode) ? uiColorPalette.text[100] : uiColorPalette.text[900]}
      style={{ cursor: 'pointer', textDecoration: 'none' }}
      onClick={() => {
        copyTextToClipboard(copyValue);
        setWasValueCopied(true);
      }}
    >
      <Tooltip
        placement="right"
        title={wasValueCopied ? 'Copied' : copyInstructions}
        onMouseEnter={() => setWasValueCopied(false)}
      >
        <Typography variant="body2">
          <b>{title}:</b> {displayValue}
        </Typography>
      </Tooltip>
    </Link>
  );
}

interface Props {
  currentColorHex: string;
  onColorChange: (colorHex: string) => void;
}

export default function ColorInfoPanel(props: Props) {
  const { currentColorHex, onColorChange } = props;

  const { baseColorName, showColorWarning } = useMemo(() => {
    return {
      baseColorName: getBaseColorName(currentColorHex),
      showColorWarning: !isColorSuitableAsBaseColor(currentColorHex),
    };
  }, [currentColorHex]);

  const {
    boxBackgroundGradient,
    currentColorHSL,
    currentColorHSV,
    currentColorRGB,
    selectedColorDescription,
    selectedColorExampleBrands,
  } = useMemo(() => {
    const { description, exampleBrands } = getEmotionDescriptionFromColorName(baseColorName);
    let boxBackgroundGradient = addAlphaToColor(currentColorHex, INFO_BOX_BACKGROUND_ALPHA);
    if (exampleBrands.length > 1) {
      const percentageSteps = 100 / (exampleBrands.length - 1);
      const gradientStoppers = exampleBrands.map(
        (brandInfo, index) =>
          `${addAlphaToColor(brandInfo.mainColor, INFO_BOX_BACKGROUND_ALPHA)} ${Math.round(
            index * percentageSteps,
          )}%`,
      );
      boxBackgroundGradient = `linear-gradient(to right, ${gradientStoppers.join(', ')})`;
    }
    return {
      boxBackgroundGradient,
      currentColorHSL: getHSLFromColorHex(currentColorHex),
      currentColorHSV: getHSVFromColorHex(currentColorHex),
      currentColorRGB: getRGBFromColorHex(currentColorHex),
      selectedColorDescription: description,
      selectedColorExampleBrands: exampleBrands,
    };
  }, [baseColorName, currentColorHex]);

  return (
    <Box
      style={{ background: boxBackgroundGradient }}
      alignItems="center"
      borderRadius={2.5}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={3}
      justifyContent="center"
      padding={2}
    >
      <ColorSampleBox
        doubleWidth
        color={currentColorHex}
        label={`Base hue: ${baseColorName}`}
        showColorCode
      />
      <Box>
        <Typography fontWeight="bold" variant="body2">
          {selectedColorDescription}
        </Typography>
        <BrandExampleList
          brands={selectedColorExampleBrands}
          label="Example brands"
          onClick={onColorChange}
        />
        <Typography
          color="warning.main"
          marginTop={3}
          variant="body2"
          visibility={showColorWarning ? undefined : 'hidden'}
        >
          <b>Warning:</b> this color may be too bright, dark, or de-saturated and may not work
          perfectly with other colors.
        </Typography>
      </Box>
      <Box alignItems="flex-start" display="flex" flexDirection="column" gap={1} width={175}>
        <ColorValuesRow
          copyInstructions="Copy hex value to clipboard"
          copyValue={currentColorHex.toUpperCase()}
          displayValue={currentColorHex.toUpperCase()}
          title="Hex"
        />
        <ColorValuesRow
          copyInstructions="Copy HSL value to clipboard"
          copyValue={`hsl(${Math.round(currentColorHSL.hue)}, ${currentColorHSL.saturation.toFixed(
            2,
          )}, ${currentColorHSL.lightness.toFixed(2)})`}
          displayValue={`${Math.round(currentColorHSL.hue)}°, ${Math.round(
            currentColorHSL.saturation * 100,
          )}%, ${Math.round(currentColorHSL.lightness * 100)}%`}
          title="HSL"
        />
        <ColorValuesRow
          copyInstructions="Copy HSV value to clipboard"
          copyValue={`hsv(${Math.round(currentColorHSV.hue)}, ${currentColorHSV.saturation.toFixed(
            2,
          )}, ${currentColorHSV.value.toFixed(2)})`}
          displayValue={`${Math.round(currentColorHSV.hue)}°, ${Math.round(
            currentColorHSV.saturation * 100,
          )}%, ${Math.round(currentColorHSV.value * 100)}%`}
          title="HSV"
        />
        <ColorValuesRow
          copyInstructions="Copy RGB value to clipboard"
          copyValue={`rgb(${currentColorRGB.red}, ${currentColorRGB.green}, ${currentColorRGB.blue})`}
          displayValue={`${currentColorRGB.red}, ${currentColorRGB.green}, ${currentColorRGB.blue}`}
          title="RGB"
        />
      </Box>
    </Box>
  );
}
