import {
  COLOR_VARIATION_OPTIONS,
  ColorPalette,
  ColorVariationShade,
  ColorWithVariations,
  VariationValuesSet,
  VariationValuesSetExtended,
  VariationValuesSetKey,
  getLabelForVariation,
} from './colors/colorPalette';
import { ColorSchemeOptions } from './colors/colorSchemeState';
import { getMUIThemeOptions } from './muiTheme';

export type SUPPORTED_FILE_TYPE = 'CSV' | 'JSON' | 'CSS' | 'MUI';

function getCSVRow(
  rowLabel: string,
  color: ColorWithVariations,
  variationValues: VariationValuesSet | VariationValuesSetExtended,
): string {
  const allColorVariations = COLOR_VARIATION_OPTIONS.map((variation) => color[variation]).join(',');
  const appliedVariations = [
    variationValues.main,
    'light1' in variationValues ? variationValues.light1 : '',
    'light2' in variationValues ? variationValues.light2 : '',
    variationValues.lighter,
    variationValues.darker,
    'dark1' in variationValues ? variationValues.dark1 : '',
    'dark2' in variationValues ? variationValues.dark2 : '',
  ].join(',');
  return `${rowLabel},${allColorVariations},,${appliedVariations}`;
}

function getCSVFileContents(colorPalette: ColorPalette): string {
  const { mainColor: mainColorVariations, neutrals: neutralVariations } =
    colorPalette.variationValues;

  const headers = `,${COLOR_VARIATION_OPTIONS.join(
    ',',
  )},,Main variation,Light1,Light2,Lighter,Darker,Dark2,Dark1`;

  return [
    headers,
    getCSVRow('Accent', colorPalette.accent, mainColorVariations),
    ...(colorPalette.secondary
      ? [getCSVRow('Secondary', colorPalette.secondary, mainColorVariations)]
      : []),
    ...colorPalette.otherHarmonyColors.map((colorWithVariations, index) =>
      getCSVRow(`Harmony ${index + 1}`, colorWithVariations, mainColorVariations),
    ),
    getCSVRow('Text', colorPalette.text, neutralVariations),
    getCSVRow('Background', colorPalette.background, neutralVariations),
    getCSVRow('Disabled', colorPalette.disabled, neutralVariations),
    getCSVRow('Divider', colorPalette.divider, neutralVariations),
    getCSVRow('Icon', colorPalette.icon, mainColorVariations),
    getCSVRow('Semantic: info', colorPalette.semantic.info, mainColorVariations),
    getCSVRow('Semantic: negative', colorPalette.semantic.negative, mainColorVariations),
    getCSVRow('Semantic: positive', colorPalette.semantic.positive, mainColorVariations),
    getCSVRow('Semantic: special', colorPalette.semantic.special, mainColorVariations),
    getCSVRow('Semantic: warning', colorPalette.semantic.warning, mainColorVariations),
  ].join('\n');
}

function getJSONFileContents(colorPalette: ColorPalette): string {
  return JSON.stringify(colorPalette, null, 2);
}

function getCSSForColor(
  className: string,
  color: ColorWithVariations,
  variationValues: VariationValuesSet | VariationValuesSetExtended,
  property: 'color' | 'background-color' | 'both',
  isNeutralColor = false,
): string {
  const cssClasses: string[] = [];
  (Object.keys(variationValues) as VariationValuesSetKey[]).forEach((variationKey) => {
    // Need to cast to `as VariationValuesSetExtended` since it can be any of those keys
    const variationValue: ColorVariationShade = (variationValues as VariationValuesSetExtended)[
      variationKey
    ];
    const variationLabelInfo = getLabelForVariation(variationKey, isNeutralColor);
    const properties: string[] = [];
    if (property === 'color' || property === 'both') {
      properties.push(`color: ${color[variationValue]};`);
    }
    if (property === 'background-color' || property === 'both') {
      properties.push(`background-color: ${color[variationValue]};`);
    }
    cssClasses.push(
      `.${className}-${variationLabelInfo.labelForCode} {\n  ${properties.join('\n  ')}\n}`,
    );
  });

  return cssClasses.join('\n\n');
}

function getCSSFileContents(colorPalette: ColorPalette): string {
  const { mainColor: mainColorVariations, neutrals: neutralVariations } =
    colorPalette.variationValues;

  return [
    getCSSForColor('accent', colorPalette.accent, mainColorVariations, 'both'),
    ...(colorPalette.secondary
      ? [getCSSForColor('secondary', colorPalette.secondary, mainColorVariations, 'both')]
      : []),
    ...colorPalette.otherHarmonyColors.map((colorWithVariations, index) =>
      getCSSForColor(`harmony-${index + 1}`, colorWithVariations, mainColorVariations, 'both'),
    ),
    getCSSForColor(
      'text',
      colorPalette.text,
      neutralVariations,
      'color',
      /* isNeutralColor = */ true,
    ),
    getCSSForColor(
      'background',
      colorPalette.background,
      neutralVariations,
      'background-color',
      /* isNeutralColor = */ true,
    ),
    getCSSForColor(
      'disabled',
      colorPalette.disabled,
      neutralVariations,
      'both',
      /* isNeutralColor = */ true,
    ),
    getCSSForColor(
      'divider',
      colorPalette.divider,
      neutralVariations,
      'both',
      /* isNeutralColor = */ true,
    ),
    getCSSForColor('icon', colorPalette.icon, mainColorVariations, 'both'),
    getCSSForColor('semantic-info', colorPalette.semantic.info, mainColorVariations, 'both'),
    getCSSForColor(
      'semantic-negative',
      colorPalette.semantic.negative,
      mainColorVariations,
      'both',
    ),
    getCSSForColor(
      'semantic-positive',
      colorPalette.semantic.positive,
      mainColorVariations,
      'both',
    ),
    getCSSForColor('semantic-special', colorPalette.semantic.special, mainColorVariations, 'both'),
    getCSSForColor('semantic-warning', colorPalette.semantic.warning, mainColorVariations, 'both'),
  ].join('\n\n');
}

function getMUIFileContents(
  colorPalette: ColorPalette,
  colorSchemeOptions: ColorSchemeOptions,
): string {
  const muiThemeOptions = getMUIThemeOptions(colorPalette, colorSchemeOptions);
  return JSON.stringify(muiThemeOptions, null, 2);
}

export function saveFile(
  fileType: SUPPORTED_FILE_TYPE,
  colorPalette: ColorPalette,
  colorSchemeOptions: ColorSchemeOptions,
) {
  let fileData: string;
  switch (fileType) {
    case 'CSV':
      fileData = getCSVFileContents(colorPalette);
      break;
    case 'JSON':
      fileData = getJSONFileContents(colorPalette);
      break;
    case 'CSS':
      fileData = getCSSFileContents(colorPalette);
      break;
    case 'MUI':
      fileData = getMUIFileContents(colorPalette, colorSchemeOptions);
      break;
  }

  let type: string;
  let extension: string;
  switch (fileType) {
    case 'CSV':
      type = 'text/csv';
      extension = 'csv';
      break;
    case 'JSON':
      type = 'application/json';
      extension = 'json';
      break;
    case 'CSS':
      type = 'text/css';
      extension = 'css';
      break;
    case 'MUI':
      type = 'application/json';
      extension = 'json';
      break;
  }

  const fileBlob = new Blob([fileData], { type });
  const element = document.createElement('a');
  element.href = URL.createObjectURL(fileBlob);
  element.download = `AppSchemerPalette.${extension}`;
  document.body.appendChild(element);
  element.click();
}
