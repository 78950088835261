import { Box, Typography } from '@mui/material';
import { isColorDark } from '../../utils/colors/colorUtils';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WifiIcon from '@mui/icons-material/Wifi';
import { useContext } from 'react';
import { AppPreviewContext } from '../appPreviewContext';

interface Props {
  containerBackgroundColor?: string;
}

export default function PhoneStatusBar(props: Props) {
  const { containerBackgroundColor } = props;
  const { getScaledValue } = useContext(AppPreviewContext);

  const textAndIconColor =
    containerBackgroundColor && isColorDark(containerBackgroundColor) ? '#FFFFFF' : '#000000';

  const dateNow = new Date();
  const hours = dateNow.getHours() % 12;
  const minutes = dateNow.getMinutes();
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      marginTop={getScaledValue(1)}
      paddingX={getScaledValue(3)}
    >
      <Typography color={textAndIconColor} variant="body1">
        {hours === 0 ? 12 : hours}:{minutes.toString().padStart(2, '0')}
      </Typography>
      <Box
        alignItems="center"
        color={textAndIconColor}
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        width={getScaledValue(70)}
      >
        <SignalCellularAltIcon color="inherit" fontSize="small" />
        <WifiIcon color="inherit" fontSize="small" />
        <Battery5BarIcon
          color="inherit"
          fontSize="medium"
          style={{ marginRight: getScaledValue(-12), transform: 'rotate(90deg)' }}
        />
      </Box>
    </Box>
  );
}
