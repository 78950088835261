import { getRandomHexColor } from './colorUtils';
import { getRandomElementFromArray, getRandomTrueOrFalse } from '../utils';
import { ColorWithVariations } from './colorPalette';

const COLOR_SCHEME_OPTIONS_CONTRAST_OPTIONS = ['low', 'normal', 'high'] as const;
const COLOR_SCHEME_OPTIONS_CONTRAST_OPTIONS_WITHOUT_MEDIUM = ['low', 'high'] as const;
const COLOR_SCHEME_OPTIONS_SECONDARY_COLOR_INDEX_OPTIONS = [0, 1, 2, 3, 4] as const;
const COLOR_SCHEME_OPTIONS_PRIMARY_COLOR_BRIGHTNESS_OPTIONS = [3, 4, 5, 6, 7] as const; // 3 => 300, 4 => 400, 5 => 500 (default), 6 => 600, 7 => 700

export const ColorHarmonyOptionKeysAndLabels = {
  complementary: 'Complementary',
  splitComplementary: 'Split complementary',
  triadic: 'Triadic',
  square: 'Square',
  tetradic: 'Tetradic',
  analogous: 'Analogous',
  monochromatic: 'Monochromatic',
} as const;

export type ColorHarmonyOption = keyof typeof ColorHarmonyOptionKeysAndLabels;
export type AllColorHarmonyOptions = { [key in ColorHarmonyOption]: ColorWithVariations[] };

export const COLOR_HARMONY_OPTIONS = Object.keys(
  ColorHarmonyOptionKeysAndLabels,
) as ColorHarmonyOption[];

export type ContrastOption = (typeof COLOR_SCHEME_OPTIONS_CONTRAST_OPTIONS)[number];
export type ColorBrightnessOption =
  (typeof COLOR_SCHEME_OPTIONS_PRIMARY_COLOR_BRIGHTNESS_OPTIONS)[number];
export interface ColorSchemeOptions {
  mainBrightness: ColorBrightnessOption;
  neutralBrightness: ColorBrightnessOption;
  semanticBrightness: ColorBrightnessOption;
  mainContrast: ContrastOption;
  neutralContrast: ContrastOption;
  tintTextNeutrals: boolean;
  darkMode: boolean;
}

export const DEFAULT_COLOR_SCHEME_OPTIONS: ColorSchemeOptions = {
  mainBrightness: 5,
  neutralBrightness: 5,
  semanticBrightness: 5,
  mainContrast: 'normal',
  neutralContrast: 'low',
  tintTextNeutrals: true,
  darkMode: false,
};

export const OPTION_NOT_SELECTED = 'X';
type MainColorIndexOption = (typeof COLOR_SCHEME_OPTIONS_SECONDARY_COLOR_INDEX_OPTIONS)[number];
export interface ColorHarmonySettings {
  selectedHarmony: ColorHarmonyOption;
  accentColorIndex: MainColorIndexOption;
  secondaryColorIndex: MainColorIndexOption | typeof OPTION_NOT_SELECTED;
  neutralColorIndex: MainColorIndexOption | typeof OPTION_NOT_SELECTED;
}

export const DEFAULT_COLOR_HARMONY_SETTINGS: ColorHarmonySettings = {
  selectedHarmony: 'complementary',
  accentColorIndex: 0,
  secondaryColorIndex: OPTION_NOT_SELECTED,
  neutralColorIndex: OPTION_NOT_SELECTED,
};

export interface ColorSchemeState {
  mainColorHex: string;
  colorHarmony: ColorHarmonySettings;
  options: ColorSchemeOptions;
}

export const DEFAULT_COLOR_SCHEME_STATE: ColorSchemeState = {
  mainColorHex: '#9DB4E2', // not actually used for anything
  colorHarmony: DEFAULT_COLOR_HARMONY_SETTINGS,
  options: DEFAULT_COLOR_SCHEME_OPTIONS,
};

export function getValidatedColorSchemeState(
  maybeInvalidColorSchemeState: ColorSchemeState,
): ColorSchemeState {
  const validatedColorSchemeState = { ...maybeInvalidColorSchemeState };

  let maxHarmonyIndex = 1;
  switch (validatedColorSchemeState.colorHarmony.selectedHarmony) {
    case 'complementary':
      maxHarmonyIndex = 1;
      break;
    case 'splitComplementary':
      maxHarmonyIndex = 2;
      break;
    case 'triadic':
      maxHarmonyIndex = 2;
      break;
    case 'square':
      maxHarmonyIndex = 3;
      break;
    case 'tetradic':
    case 'analogous':
      maxHarmonyIndex = 4;
      break;
    case 'monochromatic':
      maxHarmonyIndex = 5;
      break;
  }

  // Make sure no selected harmony options are out of bounds:
  if (validatedColorSchemeState.colorHarmony.accentColorIndex > maxHarmonyIndex) {
    validatedColorSchemeState.colorHarmony.accentColorIndex = 0;
  }
  if (
    validatedColorSchemeState.colorHarmony.secondaryColorIndex !== OPTION_NOT_SELECTED &&
    validatedColorSchemeState.colorHarmony.secondaryColorIndex > maxHarmonyIndex
  ) {
    validatedColorSchemeState.colorHarmony.secondaryColorIndex = OPTION_NOT_SELECTED;
  }

  if (
    validatedColorSchemeState.colorHarmony.neutralColorIndex !== OPTION_NOT_SELECTED &&
    validatedColorSchemeState.colorHarmony.neutralColorIndex > maxHarmonyIndex
  ) {
    validatedColorSchemeState.colorHarmony.neutralColorIndex = OPTION_NOT_SELECTED;
  }

  // Make sure accent and secondary color aren't the same selection
  if (
    validatedColorSchemeState.colorHarmony.accentColorIndex ===
    validatedColorSchemeState.colorHarmony.secondaryColorIndex
  ) {
    validatedColorSchemeState.colorHarmony.secondaryColorIndex = OPTION_NOT_SELECTED;
  }

  return validatedColorSchemeState;
}

const COLOR_HARMONY_TO_INDEX_CHOICES: {
  [key in ColorHarmonyOption]: readonly MainColorIndexOption[];
} = {
  complementary: [0],
  splitComplementary: [0, 1],
  triadic: [0, 1],
  square: [0, 1, 2],
  tetradic: [0, 1, 2, 3],
  analogous: [0, 1, 2, 3],
  monochromatic: [0, 1, 2, 3, 4],
} as const;
export function getRandomColorSchemeState(
  existingState: ColorSchemeState,
  options: {
    randomizeHarmonyColors: boolean;
    randomizeOptions: boolean;
  },
): ColorSchemeState {
  let randomizedPalette = {
    ...existingState,
    mainColorHex: getRandomHexColor({ suitableBaseColorsOnly: true }),
  };

  if (options.randomizeHarmonyColors) {
    // Choose a color harmony and accent color:
    const colorHarmonyOption = getRandomElementFromArray(COLOR_HARMONY_OPTIONS);
    const harmonyColorIndexChoices = [...COLOR_HARMONY_TO_INDEX_CHOICES[colorHarmonyOption]];
    const accentColorIndex = getRandomElementFromArray(harmonyColorIndexChoices);
    // Choose secondary color if available:
    const remainingHarmonyColorIndexChoices = [...harmonyColorIndexChoices];
    remainingHarmonyColorIndexChoices.splice(accentColorIndex, 1);
    const secondaryColorIndex =
      remainingHarmonyColorIndexChoices.length > 0
        ? getRandomElementFromArray([
            ...remainingHarmonyColorIndexChoices,
            OPTION_NOT_SELECTED as typeof OPTION_NOT_SELECTED,
          ])
        : OPTION_NOT_SELECTED;
    // Choose neutrals color:
    const neutralColorIndex = getRandomElementFromArray([
      ...harmonyColorIndexChoices,
      OPTION_NOT_SELECTED as typeof OPTION_NOT_SELECTED,
    ]);
    randomizedPalette = {
      ...randomizedPalette,
      colorHarmony: {
        selectedHarmony: colorHarmonyOption,
        accentColorIndex,
        secondaryColorIndex,
        neutralColorIndex,
      },
    };
  }

  if (options.randomizeOptions) {
    randomizedPalette = {
      ...randomizedPalette,
      options: {
        mainBrightness: getRandomElementFromArray(
          COLOR_SCHEME_OPTIONS_PRIMARY_COLOR_BRIGHTNESS_OPTIONS,
        ),
        neutralBrightness: getRandomElementFromArray(
          COLOR_SCHEME_OPTIONS_PRIMARY_COLOR_BRIGHTNESS_OPTIONS,
        ),
        semanticBrightness: getRandomElementFromArray(
          COLOR_SCHEME_OPTIONS_PRIMARY_COLOR_BRIGHTNESS_OPTIONS,
        ),
        mainContrast: getRandomElementFromArray(COLOR_SCHEME_OPTIONS_CONTRAST_OPTIONS),
        neutralContrast: getRandomElementFromArray(
          COLOR_SCHEME_OPTIONS_CONTRAST_OPTIONS_WITHOUT_MEDIUM,
        ),
        tintTextNeutrals: getRandomTrueOrFalse(),
        darkMode: getRandomTrueOrFalse(),
      },
    };
  }

  return randomizedPalette;
}
