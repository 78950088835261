import { useContext, useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, Button, Typography } from '@mui/material';
import ScreenContainer from '../components/ScreenContainer';
import { getRandomHexColor } from '../utils/colors/colorUtils';
import {
  getBaseColorName,
  getBaseColorNameLowercase,
  getEmotionDescriptionFromColorName,
} from '../utils/colors/colorInfo';
import useDebounceUpdateMainColor from '../utils/useDebounceUpdateMainColor';
import { AppUIContext, SCREENS } from '../utils/appUIContext';
import ExampleImageCollection from '../components/shared/ExampleImageCollection';
import RandomizeColorOptionsMenuButton, {
  RandomizeColorOptions,
} from '../components/color/RandomizeColorOptionsActionMenu';
import ColorSliders from '../components/color/ColorSliders';
import ColorInfoPanel from '../components/color/ColorInfoPanel';
import EyeDropperPicker from '../components/color/EyeDropperPicker';
import UndoRedoActions from '../components/color/UndoRedoActions';
import useIsFlowMode from '../utils/useIsFlowMode';

export default function ColorTool() {
  const { onGoToScreen } = useContext(AppUIContext);
  const isFlowMode = useIsFlowMode();

  const [randomizeColorOptions, setRandomizeColorOptions] = useState<RandomizeColorOptions>({
    lockHue: false,
    suitableBaseColorsOnly: false,
  });

  const { debounceUpdateMainColor, mainColorHexRealTime, setMainColor } =
    useDebounceUpdateMainColor();

  const { baseColorName, baseColorNameLowercase, selectedColorExamplePictures } = useMemo(() => {
    const baseColorName = getBaseColorName(mainColorHexRealTime);
    const { examplePictures } = getEmotionDescriptionFromColorName(baseColorName);
    return {
      baseColorName,
      baseColorNameLowercase: getBaseColorNameLowercase(mainColorHexRealTime),
      selectedColorExamplePictures: examplePictures,
    };
  }, [mainColorHexRealTime]);

  return (
    <ScreenContainer
      enableContentPadding
      footerButtonProps={
        isFlowMode
          ? {
              ariaLabel: 'Save color and go to color harmony screen',
              label: 'Continue',
              onClick: () => onGoToScreen('Harmony', isFlowMode),
            }
          : [
              {
                ariaLabel: 'Save color and go to palette overview screen',
                label: 'Done',
                onClick: () => onGoToScreen('Palette'),
              },
              {
                ariaLabel: 'Save color and go to color harmony screen',
                label: SCREENS['Harmony'].shortTitle,
                onClick: () => onGoToScreen('Harmony'),
              },
            ]
      }
      fullyTransparentHeader
      showFeedbackButton
      title={SCREENS['Color'].title}
    >
      <Typography marginBottom={10} variant="body1">
        This tool can help guide you in choosing the best main color for your palette. The main
        palette color informs all other color options. It should be the <b>color of your brand</b>{' '}
        or the color people will associate with your app, and it should also be reflective of the{' '}
        <b>emotion</b> of your app or brand.
      </Typography>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={10}
        justifyContent="center"
        marginBottom={10}
      >
        <Box paddingX={2}>
          <ChromePicker
            color={mainColorHexRealTime}
            disableAlpha
            onChange={debounceUpdateMainColor}
          />
        </Box>
        <ColorSliders
          currentColorHex={mainColorHexRealTime}
          onColorChange={debounceUpdateMainColor}
        />
        <Box alignItems="center" display="flex" flexDirection="row" gap={5}>
          <Box>
            <EyeDropperPicker onColorPicked={setMainColor} />
            <UndoRedoActions currentColorHex={mainColorHexRealTime} onColorChange={setMainColor} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Button
              aria-label="Randomize the main color (random hex value)"
              variant="outlined"
              onClick={() =>
                setMainColor(
                  getRandomHexColor({
                    hueBaseColor: randomizeColorOptions.lockHue ? baseColorName : undefined,
                    suitableBaseColorsOnly: randomizeColorOptions.suitableBaseColorsOnly,
                  }),
                )
              }
            >
              Randomize
            </Button>
            <RandomizeColorOptionsMenuButton
              baseColorNameLowercase={baseColorNameLowercase}
              options={randomizeColorOptions}
              onOptionsChange={setRandomizeColorOptions}
            />
          </Box>
        </Box>
      </Box>
      <ColorInfoPanel currentColorHex={mainColorHexRealTime} onColorChange={setMainColor} />
      <ExampleImageCollection
        containerCentralColor={mainColorHexRealTime}
        exampleImages={selectedColorExamplePictures}
        marginTop={10}
        previewTooltip="Preview this brand color"
        title={`Example UIs where ${baseColorNameLowercase} is the dominant color:`}
        width="100%"
        onPreviewClicked={debounceUpdateMainColor}
      />
    </ScreenContainer>
  );
}
