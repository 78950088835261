import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const BLUR_BACKGROUND_STYLE = {
  backdropFilter: 'blur(10px)',
};

export const CONTAINER_DEFAULT_THEME: BoxProps = {
  border: 1,
  borderRadius: 2,
  boxShadow: 2,
  padding: 3,
};
export const CONTAINER_USER_THEME: BoxProps = { borderRadius: 2, boxShadow: 2, padding: 3 };

export const DIALOG_ACTIONS_STYLE: CSSProperties = {
  paddingBottom: 12,
  paddingRight: 12,
};
