import { Box, Link, Typography } from '@mui/material';
import { getSecondaryColorOrNextBestOption, isColorDark } from '../../utils/colors/colorUtils';
import { useContext, useMemo } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PhoneStatusBar from '../phoneAppComponents/PhoneStatusBar';
import { AppPreviewContext } from '../appPreviewContext';

function MarketPriceRow({
  amountDollars,
  amountSymbol,
  changePercentage,
  getScaledValue,
  symbol,
  symbolColor,
  uxColors,
}: {
  amountDollars: string;
  amountSymbol: string;
  changePercentage: number;
  getScaledValue: (value: number) => number;
  symbol: string;
  symbolColor: string;
  uxColors: {
    positive: string;
    negative: string;
    grayText: string;
  };
}) {
  const isPositive = changePercentage >= 0;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      padding={getScaledValue(1)}
    >
      <Box alignItems="center" display="flex" flexDirection="row">
        <Box
          bgcolor={symbolColor}
          border={getScaledValue(1)}
          borderColor={uxColors.grayText}
          borderRadius="50%"
          height={getScaledValue(35)}
          width={getScaledValue(35)}
        />
        <Box
          display="flex"
          flexDirection="column"
          paddingLeft={getScaledValue(1)}
          justifyContent="center"
          textAlign="left"
          width={getScaledValue(55)}
          style={{ cursor: 'pointer' }}
        >
          <Typography fontWeight="bold" variant="body1">
            {symbol}
          </Typography>
          <Typography color={isPositive ? uxColors.positive : uxColors.negative}>
            {isPositive ? '+' : ''}
            {changePercentage}%
          </Typography>
        </Box>
        <Box
          color={isPositive ? uxColors.positive : uxColors.negative}
          display="flex"
          paddingLeft={getScaledValue(0.5)}
        >
          {isPositive ? (
            <TrendingUpIcon color="inherit" fontSize="large" />
          ) : (
            <TrendingDownIcon color="inherit" fontSize="large" />
          )}
        </Box>
      </Box>
      <Box justifyContent="center" paddingLeft={getScaledValue(0.5)} textAlign="right">
        <Typography fontWeight="bold" variant="subtitle1">
          ${amountDollars}
        </Typography>
        <Typography color={uxColors.grayText} variant="body2">
          {amountSymbol} {symbol}
        </Typography>
      </Box>
    </Box>
  );
}

export default function PhoneCrypto() {
  const { colorPalette, isDarkMode, getScaledValue } = useContext(AppPreviewContext);

  const {
    mainColor: mainColorVariations,
    neutrals: neutralsVariations,
    semantic: semanticVariations,
  } = colorPalette.variationValues;

  // TODO: implement colors here
  const colors = useMemo(() => {
    const balanceBoxBackground =
      colorPalette.accent[isDarkMode ? mainColorVariations.main : mainColorVariations.lighter];
    return {
      background:
        colorPalette.background[isDarkMode ? neutralsVariations.dark1 : neutralsVariations.light1],
      secondaryBackground:
        colorPalette.background[isDarkMode ? neutralsVariations.darker : neutralsVariations.light2],
      backChevron:
        colorPalette.text[isDarkMode ? neutralsVariations.light1 : neutralsVariations.dark1],
      balanceBoxBackground,
      balanceBoxText:
        colorPalette.text[
          isColorDark(balanceBoxBackground) ? neutralsVariations.light1 : neutralsVariations.dark1
        ],
      balanceTextPositive:
        colorPalette.semantic.positive[
          isColorDark(balanceBoxBackground) ? semanticVariations.main : semanticVariations.lighter
        ],
      positive:
        colorPalette.semantic.positive[
          isDarkMode ? semanticVariations.lighter : semanticVariations.main
        ],
      negative:
        colorPalette.semantic.negative[
          isDarkMode ? semanticVariations.lighter : semanticVariations.main
        ],
      bottomNavBar:
        colorPalette.background[isDarkMode ? neutralsVariations.dark2 : neutralsVariations.lighter],
      bottomNavBarIcon: colorPalette.accent[neutralsVariations.main],
      bottomNavBarIconSelected:
        getSecondaryColorOrNextBestOption(colorPalette)[
          isDarkMode ? mainColorVariations.main : mainColorVariations.lighter
        ],
      marketPriceRowColors: {
        positive:
          colorPalette.semantic.positive[
            isDarkMode ? semanticVariations.lighter : semanticVariations.main
          ],
        negative:
          colorPalette.semantic.negative[
            isDarkMode ? semanticVariations.lighter : semanticVariations.main
          ],
        grayText:
          colorPalette.text[isDarkMode ? neutralsVariations.lighter : neutralsVariations.darker],
      },
    };
  }, [colorPalette, isDarkMode, mainColorVariations, neutralsVariations, semanticVariations]);

  return (
    <Box
      bgcolor={colors.background}
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      position="relative"
    >
      <Box>
        <PhoneStatusBar containerBackgroundColor={colors.background} />
        <Box
          display="flex"
          flexDirection="column"
          marginTop={getScaledValue(1)}
          paddingX={getScaledValue(3)}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            height={getScaledValue(25)}
            justifyContent="center"
            position="relative"
          >
            <Box color={colors.backChevron} height="100%" left={0} position="absolute" top={0}>
              <ChevronLeftIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
            </Box>
            <Typography fontWeight="bold" variant="subtitle1">
              Account
            </Typography>
          </Box>
          <Box
            bgcolor={colors.balanceBoxBackground}
            borderRadius={getScaledValue(2)}
            boxShadow={`0 0 ${getScaledValue(10)}px ${colors.balanceBoxBackground}`}
            color={colors.balanceBoxText}
            marginTop={getScaledValue(2)}
            padding={getScaledValue(1)}
          >
            <Typography color="inherit" paddingY={getScaledValue(0.5)} variant="subtitle1">
              Total balance
            </Typography>
            <Typography color="inherit" fontWeight="bold" variant="h4">
              $1,234,567.89
            </Typography>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              paddingY={getScaledValue(0.5)}
            >
              <Typography color="inherit" variant="subtitle1">
                $15,185.19 +1.23%
              </Typography>
              <Box color={colors.balanceTextPositive}>
                <TrendingUpIcon
                  color="inherit"
                  fontSize="medium"
                  style={{ marginLeft: getScaledValue(5) }}
                />
              </Box>
            </Box>
          </Box>
          <Box marginTop={getScaledValue(3)}>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography fontWeight="bold" variant="subtitle1">
                Portfolio
              </Typography>
              <Typography variant="subtitle2">
                <Link aria-label="Example link with your theme" style={{ cursor: 'pointer' }}>
                  View all assets
                </Link>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" paddingY={getScaledValue(1.5)}>
              <Box width="50%">
                <Box display="flex" flexDirection="row" justifyContent="flex-start">
                  <Box
                    bgcolor="gold"
                    borderRadius="50%"
                    height={getScaledValue(35)}
                    width={getScaledValue(35)}
                  />
                  <Box paddingLeft={getScaledValue(1.5)} style={{ cursor: 'pointer' }}>
                    <Typography align="left" fontWeight="bold" variant="body1">
                      BTC
                    </Typography>
                    <Typography align="left" variant="subtitle1">
                      Crypto asset
                    </Typography>
                  </Box>
                </Box>
                <Box marginTop={getScaledValue(0.5)} textAlign="center" width="100%">
                  <Typography fontWeight="bold" variant="h6">
                    $321,324.32
                  </Typography>
                  <Typography color={colors.positive} variant="body1">
                    +2.9%
                  </Typography>
                </Box>
              </Box>
              <Box width="50%">
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                  <Box
                    bgcolor="blue"
                    borderRadius="50%"
                    height={getScaledValue(35)}
                    width={getScaledValue(35)}
                  />
                  <Box paddingLeft={getScaledValue(1.5)} style={{ cursor: 'pointer' }}>
                    <Typography align="left" fontWeight="bold" variant="body1">
                      ETH
                    </Typography>
                    <Typography align="left" variant="subtitle1">
                      Crypto asset
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft={2} marginTop={getScaledValue(0.5)} textAlign="center" width="100%">
                  <Typography fontWeight="bold" variant="h6">
                    $892.43
                  </Typography>
                  <Typography color={colors.negative} variant="body1">
                    -8.32%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box marginTop={2}>
            <Typography
              fontWeight="bold"
              marginBottom={getScaledValue(1)}
              textAlign="left"
              variant="subtitle1"
            >
              Market prices
            </Typography>
            <Box
              bgcolor={colors.secondaryBackground}
              borderRadius={getScaledValue(2)}
              boxShadow={getScaledValue(4)}
            >
              <MarketPriceRow
                amountDollars="2,299.20"
                amountSymbol="12.35"
                changePercentage={-0.64}
                getScaledValue={getScaledValue}
                symbol="BCH"
                symbolColor="#7DBF20"
                uxColors={colors.marketPriceRowColors}
              />
              <MarketPriceRow
                amountDollars="1,466.71"
                amountSymbol="23.00"
                changePercentage={0.3}
                getScaledValue={getScaledValue}
                symbol="LTC"
                symbolColor="#1171B7"
                uxColors={colors.marketPriceRowColors}
              />
              <MarketPriceRow
                amountDollars="16,035.32"
                amountSymbol="9.624"
                changePercentage={0.27}
                getScaledValue={getScaledValue}
                symbol="ETH"
                symbolColor="#2F2F2F"
                uxColors={colors.marketPriceRowColors}
              />
              <MarketPriceRow
                amountDollars="0.06129"
                amountSymbol="1000"
                changePercentage={-1.42}
                getScaledValue={getScaledValue}
                symbol="DOGE"
                symbolColor="#B59A31"
                uxColors={colors.marketPriceRowColors}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        bgcolor={colors.bottomNavBar}
        borderRadius={`${getScaledValue(5)}px ${getScaledValue(5)}px 0 0`}
        bottom={0}
        boxShadow={`0 0 ${getScaledValue(10)}px ${colors.bottomNavBar}`}
        color={colors.bottomNavBarIcon}
        display="flex"
        flexDirection="row"
        height={getScaledValue(45)}
        justifyContent="space-evenly"
        position="absolute"
        width="100%"
      >
        <HomeOutlinedIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
        <Box color={colors.bottomNavBarIconSelected}>
          <WaterfallChartIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
        </Box>
        <PaymentsOutlinedIcon color="inherit" fontSize="large" style={{ cursor: 'pointer' }} />
        <AccountBalanceOutlinedIcon
          color="inherit"
          fontSize="large"
          style={{ cursor: 'pointer' }}
        />
      </Box>
    </Box>
  );
}
