import { useCallback, useContext, useState } from 'react';
import { Alert, Box, Button, IconButton, Snackbar, Tooltip } from '@mui/material';
import { getCurrentURL } from '../../../utils/url';
import { SUPPORTED_FILE_TYPE, saveFile } from '../../../utils/export';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { UserPaletteContext } from '../../../utils/userPaletteContext';
import {
  getShareToEmailLink,
  getShareToFacebookLink,
  getShareToLinkedInLink,
  getShareToTwitterLink,
} from '../../../ui/uiUrls';
import MoreInformationDialog from '../MoreInformationDialog';
import { copyTextToClipboard } from '../../../utils/utils';
import { AppUIContext } from '../../../utils/appUIContext';
import { ColorPalette } from '../../../utils/colors/colorPalette';

const SUCCESS_SNACKBAR_DURATION_MS = 5000;

function OtherLinks({
  uiColorPalette,
  onHelpButtonClicked,
}: {
  uiColorPalette: ColorPalette;
  onHelpButtonClicked: () => void;
}) {
  const colorVariation = uiColorPalette.variationValues.mainColor.main;

  return (
    <Box
      color={uiColorPalette.accent[colorVariation]}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Tooltip placement="bottom" title="Share to Facebook">
        <IconButton
          aria-label="Share your palette on Facebook"
          color="inherit"
          href={getShareToFacebookLink()}
          target="_blank"
        >
          <FacebookIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title="Share to Twitter (X)">
        <IconButton
          aria-label="Share your palette on Twitter (X)"
          color="inherit"
          href={getShareToTwitterLink()}
          target="_blank"
        >
          <TwitterIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title="Share to LinkedIn">
        <IconButton
          aria-label="Share your palette on LinkedIn"
          color="inherit"
          href={getShareToLinkedInLink()}
          target="_blank"
        >
          <LinkedInIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title="Share in email">
        <IconButton
          aria-label="Share your palette over email"
          color="inherit"
          href={getShareToEmailLink()}
          target="_blank"
        >
          <EmailIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Box color={uiColorPalette.icon[colorVariation]} marginLeft={1}>
        <Tooltip placement="bottom" title="Get help">
          <IconButton
            aria-label="Open the help dialog for more information"
            color="inherit"
            onClick={onHelpButtonClicked}
          >
            <HelpOutlineIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default function ExportPalette() {
  const { colorPalette, colorSchemeState } = useContext(UserPaletteContext);
  const { uiColorPalette } = useContext(AppUIContext);

  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [wasURLCopiedToClipboard, setWasURLCopiedToClipboard] = useState(false);

  const copyURLToClipboard = useCallback(() => {
    copyTextToClipboard(getCurrentURL());
    setWasURLCopiedToClipboard(true);
  }, []);

  const exportTo = useCallback(
    (exportType: SUPPORTED_FILE_TYPE) =>
      saveFile(exportType, colorPalette, colorSchemeState.options),
    [colorPalette, colorSchemeState.options],
  );

  return (
    <>
      <MoreInformationDialog
        defaultExpandedSection="EXPORT"
        isOpen={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={wasURLCopiedToClipboard}
        autoHideDuration={SUCCESS_SNACKBAR_DURATION_MS}
        onClose={() => setWasURLCopiedToClipboard(false)}
      >
        <Alert severity="success" onClose={() => setWasURLCopiedToClipboard(false)}>
          URL was copied to your clipboard.
        </Alert>
      </Snackbar>
      <Box display="flex" flexDirection="column" gap={0.5} padding={1}>
        <Button
          aria-label="Copy a sharable URL to your clipboard"
          startIcon={<ContentCopyOutlinedIcon />}
          onClick={copyURLToClipboard}
        >
          Copy URL
        </Button>
        <Button
          aria-label="Download your palette as a .JSON object file"
          endIcon={<CloudDownloadOutlinedIcon />}
          onClick={() => exportTo('JSON')}
        >
          Save as .JSON
        </Button>
        <Button
          aria-label="Download your palette as a .CSV spreadsheet file"
          endIcon={<CloudDownloadOutlinedIcon />}
          onClick={() => exportTo('CSV')}
        >
          Save as .CSV
        </Button>
        <Button
          aria-label="Download your palette as a .CSS stylesheet file"
          endIcon={<CloudDownloadOutlinedIcon />}
          onClick={() => exportTo('CSS')}
        >
          Save as .CSS
        </Button>
        <Button
          aria-label="Download your palette as a .JSON MUI theme options file"
          endIcon={<CloudDownloadOutlinedIcon />}
          onClick={() => exportTo('MUI')}
        >
          Save MUI Theme (.JSON)
        </Button>
        <OtherLinks
          uiColorPalette={uiColorPalette}
          onHelpButtonClicked={() => setShowHelpDialog(true)}
        />
      </Box>
    </>
  );
}
