import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ColorResult } from 'react-color';
import { UserPaletteContext } from './userPaletteContext';
import { AppUIContext } from './appUIContext';

const UPDATE_PRIMARY_COLOR_DEBOUNCE_TIME_MS = 250;
const UPDATE_PRIMARY_COLOR_DEBOUNCE_TIME_MOBILE_MS = 500;

export default function useDebounceUpdateMainColor() {
  const { colorSchemeState, onColorSchemeStateChanged } = useContext(UserPaletteContext);
  const {
    responsiveBreakpoints: { isMobileDevice },
  } = useContext(AppUIContext);

  // Keep a local state of the primary color that updates immediately for rendering the editable
  // main color box, and debounce all other updates to avoid re-rendering the entire app every
  // time the user moves their mouse when picking a new color:
  const [mainColorHexRealTime, setMainColorHexRealTime] = useState<string>(
    colorSchemeState.mainColorHex,
  );

  const debounceUpdatePrimaryColorTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Update with debounce:
  const debounceUpdateMainColor = useCallback(
    (color: ColorResult | string) => {
      if (debounceUpdatePrimaryColorTimerRef.current) {
        clearTimeout(debounceUpdatePrimaryColorTimerRef.current);
        debounceUpdatePrimaryColorTimerRef.current = null;
      }
      const mainColorHex = typeof color === 'string' ? color : color.hex;
      debounceUpdatePrimaryColorTimerRef.current = setTimeout(
        () => onColorSchemeStateChanged({ mainColorHex }),
        isMobileDevice
          ? UPDATE_PRIMARY_COLOR_DEBOUNCE_TIME_MOBILE_MS
          : UPDATE_PRIMARY_COLOR_DEBOUNCE_TIME_MS,
      );
      setMainColorHexRealTime(mainColorHex);
    },
    [isMobileDevice, onColorSchemeStateChanged],
  );

  // Update instantly (bypass the debounce):
  const setMainColorOverride = useCallback(
    (newColorHex: string) => {
      if (debounceUpdatePrimaryColorTimerRef.current) {
        clearTimeout(debounceUpdatePrimaryColorTimerRef.current);
        debounceUpdatePrimaryColorTimerRef.current = null;
      }
      onColorSchemeStateChanged({ mainColorHex: newColorHex });
      setMainColorHexRealTime(newColorHex);
    },
    [onColorSchemeStateChanged],
  );

  // Make sure to sync the real-time state if the main color is changed from somewhere else:
  useEffect(() => {
    if (debounceUpdatePrimaryColorTimerRef.current) {
      clearTimeout(debounceUpdatePrimaryColorTimerRef.current);
      debounceUpdatePrimaryColorTimerRef.current = null;
    }
    setMainColorHexRealTime(colorSchemeState.mainColorHex);
  }, [colorSchemeState.mainColorHex]);

  return { debounceUpdateMainColor, mainColorHexRealTime, setMainColor: setMainColorOverride };
}
