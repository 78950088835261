import { createContext } from 'react';
import { getColorPalette } from './colors/colorUtils';
import { ColorSchemeState, DEFAULT_COLOR_SCHEME_STATE } from './colors/colorSchemeState';
import { ColorPalette } from './colors/colorPalette';

interface UserPaletteContextType {
  colorPalette: ColorPalette;
  colorSchemeState: ColorSchemeState;
  onColorSchemeStateChanged: (updates: Partial<ColorSchemeState>) => void;
}

export const UserPaletteContext = createContext<UserPaletteContextType>({
  colorPalette: getColorPalette(DEFAULT_COLOR_SCHEME_STATE),
  colorSchemeState: DEFAULT_COLOR_SCHEME_STATE,
  onColorSchemeStateChanged: () => {
    throw new Error('[onColorSchemeStateChanged] This function has not yet been defined');
  },
});
