import { Box, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MAX_HUE_VALUE,
  adjustColorHSLValues,
  getColorHarmonyOptions,
  getHSLFromColorHex,
} from '../../utils/colors/colorUtils';
import ColorSampleBox from '../shared/ColorSampleBox';
import { ColorHarmonySettings } from '../../utils/colors/colorSchemeState';

const COLOR_WHEEL_SIZE_PX = 275;
const COLOR_WHEEL_INDICATOR_SIZE_PX = Math.round(COLOR_WHEEL_SIZE_PX / 8);
const COLOR_WHEEL_ROTATION_OFFSET_RADIANS = -(2 * Math.PI) / 12;

const CANVAS_CORRECTION_X_OFFSET_PX = 1.5;
const CANVAS_CORRECTION_Y_OFFSET_PX = 1.5;

interface Point2D {
  x: number;
  y: number;
}

interface ColorWheelPositionSetting {
  color: string;
  isPrimaryColor?: boolean;
  x: number;
  y: number;
}

function ConnectedLinesOverlay({ positions }: { positions: ColorWheelPositionSetting[] }) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawConnectedLines = useCallback(
    (context: CanvasRenderingContext2D) => {
      const { width, height } = context.canvas;
      context.clearRect(0, 0, width, height);
      context.lineWidth = 2;
      const indexStop = positions.length === 2 ? 1 : positions.length;
      for (let i = 0; i < indexStop; i++) {
        const nextIndex = i < positions.length - 1 ? i + 1 : 0;
        const startPos = positions[i];
        const startX = Math.round(startPos.x + COLOR_WHEEL_INDICATOR_SIZE_PX / 2);
        const startY = Math.round(startPos.y + COLOR_WHEEL_INDICATOR_SIZE_PX / 2);
        const endPos = positions[nextIndex];
        const endX = Math.round(endPos.x + COLOR_WHEEL_INDICATOR_SIZE_PX / 2);
        const endY = Math.round(endPos.y + COLOR_WHEEL_INDICATOR_SIZE_PX / 2);
        context.beginPath();
        context.moveTo(
          startX + CANVAS_CORRECTION_X_OFFSET_PX,
          startY + CANVAS_CORRECTION_Y_OFFSET_PX,
        );
        context.lineTo(endX + CANVAS_CORRECTION_X_OFFSET_PX, endY + CANVAS_CORRECTION_Y_OFFSET_PX);
        context.stroke();
      }
    },
    [positions],
  );

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (!context) {
      return;
    }
    drawConnectedLines(context);
  }, [drawConnectedLines]);

  return (
    <Box
      height={COLOR_WHEEL_SIZE_PX}
      left={0}
      position="absolute"
      top={0}
      width={COLOR_WHEEL_SIZE_PX}
    >
      <canvas ref={canvasRef} height={COLOR_WHEEL_SIZE_PX} width={COLOR_WHEEL_SIZE_PX} />
    </Box>
  );
}

function getColorWheelPositionForColor(hexColor: string): ColorWheelPositionSetting {
  const { hue: colorHue } = getHSLFromColorHex(hexColor);
  const trigInputValueRadians =
    -2 * Math.PI * (colorHue / MAX_HUE_VALUE) + COLOR_WHEEL_ROTATION_OFFSET_RADIANS;
  const scaleMultiplier = COLOR_WHEEL_SIZE_PX - COLOR_WHEEL_INDICATOR_SIZE_PX * 2;
  const offset = COLOR_WHEEL_SIZE_PX / 2 - (COLOR_WHEEL_INDICATOR_SIZE_PX + 4) / 2;
  return {
    x: (Math.cos(trigInputValueRadians) * scaleMultiplier) / 2 + offset,
    y: (Math.sin(trigInputValueRadians) * scaleMultiplier) / 2 + offset,
    color: hexColor,
  };
}

function getHueFromColorWheelPosition(position: Point2D): number {
  const normalizedPosition: Point2D = {
    x: 2 * (position.x / COLOR_WHEEL_SIZE_PX) - 1,
    y: 2 * (position.y / COLOR_WHEEL_SIZE_PX) - 1,
  };
  normalizedPosition.x = Math.min(Math.max(-1, normalizedPosition.x), 1);
  normalizedPosition.y = Math.min(Math.max(-1, normalizedPosition.y), 1);
  const radians = Math.PI + Math.atan2(normalizedPosition.y, -normalizedPosition.x); // 0 to 2 * PI
  let radiansWithOffset = radians + COLOR_WHEEL_ROTATION_OFFSET_RADIANS;
  if (radiansWithOffset < 0) {
    radiansWithOffset += 2 * Math.PI;
  }
  const normalizedHue = radiansWithOffset / (2 * Math.PI);
  const hue = Math.round(normalizedHue * MAX_HUE_VALUE);
  return hue;
}

const ColorWheelColorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF00', // transparent
    margin: 0,
    padding: 0,
  },
}));

interface Props {
  primaryColor: string;
  otherColors?: string[];
  selectedHarmony: ColorHarmonySettings['selectedHarmony'];
  showHarmonyLines?: boolean;
  onPrimaryColorChange: (colorHex: string) => void;
}

export default function ColorWheel(props: Props) {
  const { primaryColor, otherColors, selectedHarmony, showHarmonyLines, onPrimaryColorChange } =
    props;

  const [isDraggingMainColor, setIsDraggingMainColor] = useState(false);
  const [dragMousePosition, setDragMousePosition] = useState<Point2D>({ x: 0, y: 0 });

  const { realTimePrimaryColor, realTimeOtherColors } = useMemo(() => {
    if (!isDraggingMainColor) {
      return { realTimePrimaryColor: primaryColor, realTimeOtherColors: otherColors };
    }
    const hue = getHueFromColorWheelPosition(dragMousePosition);
    const realTimePrimaryColor = adjustColorHSLValues(primaryColor, { hue });
    // TODO: comparing `getColorWheelPositionForColor(realTimePrimaryColor);` with `dragMousePosition` isn't the same
    // need to fix this behavior so that and `getHueFromColorWheelPosition` are perfectly inverted.
    const tempHarmonyOptions = getColorHarmonyOptions(realTimePrimaryColor);
    const realTimeOtherColors = tempHarmonyOptions[selectedHarmony].map(
      (colorWithVariations) => colorWithVariations[500],
    );
    return { realTimePrimaryColor, realTimeOtherColors };
  }, [dragMousePosition, isDraggingMainColor, otherColors, primaryColor, selectedHarmony]);

  const colorWheelPositions = useMemo(() => {
    const positions: ColorWheelPositionSetting[] = [];
    if (realTimeOtherColors) {
      realTimeOtherColors.forEach((color) => {
        positions.push(getColorWheelPositionForColor(color));
      });
    }
    positions.push({
      ...getColorWheelPositionForColor(realTimePrimaryColor),
      isPrimaryColor: true,
    });
    return positions;
  }, [realTimeOtherColors, realTimePrimaryColor]);

  const handleMaybeStartDraggingMainColor = useCallback((position: ColorWheelPositionSetting) => {
    if (!position.isPrimaryColor) {
      return;
    }
    setDragMousePosition(position);
    setIsDraggingMainColor(true);
  }, []);

  const handleMouseMoved = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isDraggingMainColor) {
        const { movementX, movementY } = event;
        setDragMousePosition((prev) => ({ x: prev.x + movementX, y: prev.y + movementY }));
      }
    },
    [isDraggingMainColor],
  );

  const handleFinishDraggingMainColor = useCallback(() => {
    setIsDraggingMainColor(false);
    if (realTimePrimaryColor !== primaryColor) {
      onPrimaryColorChange(realTimePrimaryColor);
    }
  }, [onPrimaryColorChange, primaryColor, realTimePrimaryColor]);

  return (
    <Box
      component="div"
      position="relative"
      onMouseLeave={() => setIsDraggingMainColor(false)}
      onMouseUp={handleFinishDraggingMainColor}
      onMouseMove={handleMouseMoved}
    >
      <Box
        alt="Color wheel"
        borderRadius="50%"
        boxShadow={5}
        component="img"
        height={COLOR_WHEEL_SIZE_PX}
        src="./images/color_wheel.png"
        width={COLOR_WHEEL_SIZE_PX}
      />
      {showHarmonyLines && <ConnectedLinesOverlay positions={colorWheelPositions} />}
      {colorWheelPositions.map((pos, index) => (
        <ColorWheelColorTooltip
          key={`color_wheel_position_${index}`}
          placement="top"
          title={<ColorSampleBox color={pos.color} compact showColorCode />}
        >
          <Box
            style={{ cursor: isDraggingMainColor && pos.isPrimaryColor ? 'grabbing' : 'pointer' }}
            bgcolor={pos.color}
            border={1.5}
            borderColor={pos.isPrimaryColor ? 'white' : 'black'}
            borderRadius="50%"
            boxShadow={4}
            component="div"
            height={COLOR_WHEEL_INDICATOR_SIZE_PX}
            left={pos.x}
            position="absolute"
            top={pos.y}
            width={COLOR_WHEEL_INDICATOR_SIZE_PX}
            onMouseDown={() => handleMaybeStartDraggingMainColor(pos)}
          />
        </ColorWheelColorTooltip>
      ))}
    </Box>
  );
}
