import { ColorWithVariations } from '../../../utils/colors/colorPalette';
import { ColorHarmonySettings } from '../../../utils/colors/colorSchemeState';
import ColorSelectorDropdown from './ColorSelectorDropdown';

interface Props {
  accentColorOptions: ColorWithVariations[];
  colorHarmony: ColorHarmonySettings;
  isDialog?: boolean;
  isMobileView?: boolean;
  onColorHarmonyChange: (updates: Partial<ColorHarmonySettings>) => void;
}

export default function AccentColorDropdown(props: Props) {
  const { accentColorOptions, colorHarmony, isDialog, isMobileView, onColorHarmonyChange } = props;

  return (
    <ColorSelectorDropdown
      ariaLabel="Choose which harmony color will be used as your accent color"
      isDialog={isDialog}
      isMobileView={isMobileView}
      label="Accent (action) color"
      options={accentColorOptions}
      selectedIndex={colorHarmony.accentColorIndex}
      tip={
        'The accent (action) color is typically used for call-to-action components such as important buttons, or to draw attention to key points of interest.'
      }
      onChange={(newIndex: ColorHarmonySettings['accentColorIndex']) =>
        onColorHarmonyChange({
          accentColorIndex: newIndex,
        })
      }
    />
  );
}
