import { useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ColorizeIcon from '@mui/icons-material/Colorize';

const ICON_SIZE_PX = 45;

interface Props {
  onColorPicked: (colorHex: string) => void;
}

export default function EyeDropperPicker(props: Props) {
  const { onColorPicked } = props;

  const openEyedropper = useCallback(async () => {
    if ('EyeDropper' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const eyeDropper = new (window as any).EyeDropper();
      try {
        const colorInfo = await eyeDropper.open();
        const { sRGBHex } = colorInfo;
        onColorPicked(sRGBHex);
      } catch (e) {
        // This API throws when the user cancels the action, so just swallow the error
      }
    }
  }, [onColorPicked]);

  return (
    <Tooltip placement="top" title="Color sampler">
      <IconButton
        aria-label="Open eye dropper to sample a color on your screen"
        style={{ height: ICON_SIZE_PX, width: ICON_SIZE_PX }}
        onClick={openEyedropper}
      >
        <ColorizeIcon />
      </IconButton>
    </Tooltip>
  );
}
