import { BrandInfo } from '../../components/shared/BrandExampleList';
import { DEFAULT_UI_MAIN_COLOR_HEX } from '../../ui/uiColors';
import { NumberRange, isBetween } from '../utils';
import { ColorHarmonyOption } from './colorSchemeState';
import { getHSLFromColorHex } from './colorUtils';

// TODO: remove export and move this all to colorUtils.ts
// TODO: this is a whole giant mess, clean it up
const MAX_HUE_VALUE = 360;
export type BaseColorName =
  | 'Red'
  | 'Orange'
  | 'Yellow'
  | 'Green'
  | 'Blue'
  | 'Purple'
  | 'Pink'
  | 'Black'
  | 'Gray'
  | 'White';

const BASE_COLOR_TO_HUE_RANGE_MAP: Record<BaseColorName, NumberRange> = {
  Red: { min: -25, max: 20 }, // -25 is the same as 335 (since red loops around)
  Orange: { min: 20, max: 40 },
  Yellow: { min: 40, max: 70 },
  Green: { min: 70, max: 170 },
  Blue: { min: 170, max: 260 },
  Purple: { min: 260, max: 295 },
  Pink: { min: 295, max: 335 },
  Black: { min: 0, max: MAX_HUE_VALUE },
  Gray: { min: 0, max: MAX_HUE_VALUE },
  White: { min: 0, max: MAX_HUE_VALUE },
};

const BASE_COLOR_TO_LIGHTNESS_RANGE_MAP: Record<BaseColorName, NumberRange> = {
  Red: { min: 0, max: 1 },
  Orange: { min: 0, max: 1 },
  Yellow: { min: 0, max: 1 },
  Green: { min: 0, max: 1 },
  Blue: { min: 0, max: 1 },
  Purple: { min: 0, max: 1 },
  Pink: { min: 0, max: 1 },
  Black: { min: 0, max: 0.15 },
  Gray: { min: 0, max: 1 },
  White: { min: 0.95, max: 1 },
};

type SaturationRange = NumberRange & {
  minLightnessDeltaIfInRange?: number;
  maxLightnessDeltaIfInRange?: number;
};
const BASE_COLOR_TO_SATURATION_RANGE_MAP: Record<BaseColorName, SaturationRange> = {
  Red: { min: 0.1, max: 1 },
  Orange: { min: 0.1, max: 1 },
  Yellow: { min: 0.1, max: 1 },
  Green: { min: 0.1, max: 1 },
  Blue: { min: 0.1, max: 1 },
  Purple: { min: 0.1, max: 1 },
  Pink: { min: 0.1, max: 1 },
  Black: { min: 0, max: 0.1, maxLightnessDeltaIfInRange: 0.05 }, // if below max saturation, max lightness +0.05 (from 0.15 to 0.2)
  Gray: { min: 0, max: 0.1 },
  White: { min: 0, max: 0.1, minLightnessDeltaIfInRange: -0.05 }, // if below max saturation, min lightness -0.05 (from 0.95 to 0.9)
};

export function getHueRangeForBaseColorName(baseColorName?: BaseColorName): NumberRange {
  if (!baseColorName) {
    return { min: 0, max: MAX_HUE_VALUE };
  }
  return BASE_COLOR_TO_HUE_RANGE_MAP[baseColorName];
}

export function getLightnessRangeForBaseColorName(baseColorName?: BaseColorName): NumberRange {
  if (!baseColorName) {
    return { min: 0, max: 1 };
  }
  return BASE_COLOR_TO_LIGHTNESS_RANGE_MAP[baseColorName];
}

export function getSaturationRangeForBaseColorName(baseColorName?: BaseColorName): SaturationRange {
  if (!baseColorName) {
    return { min: 0, max: 1 };
  }
  return BASE_COLOR_TO_SATURATION_RANGE_MAP[baseColorName];
}

export function getBaseColorName(colorHex: string): BaseColorName {
  const { hue, saturation, lightness } = getHSLFromColorHex(colorHex);
  // Black/white/gray cases:
  if (lightness < BASE_COLOR_TO_LIGHTNESS_RANGE_MAP.Black.max) {
    return 'Black';
  }
  if (lightness > BASE_COLOR_TO_LIGHTNESS_RANGE_MAP.White.min) {
    return 'White';
  }
  if (saturation < BASE_COLOR_TO_SATURATION_RANGE_MAP.Gray.max) {
    if (
      lightness <
      BASE_COLOR_TO_LIGHTNESS_RANGE_MAP.Black.max + // 0.15
        (BASE_COLOR_TO_SATURATION_RANGE_MAP.Black.maxLightnessDeltaIfInRange ?? 0) // +0.05 => 0.2
    ) {
      return 'Black';
    } else if (
      lightness >
      BASE_COLOR_TO_LIGHTNESS_RANGE_MAP.White.min + // 0.95
        (BASE_COLOR_TO_SATURATION_RANGE_MAP.White.minLightnessDeltaIfInRange ?? 0) // -0.05 => 0.9
    ) {
      return 'White';
    }
    return 'Gray';
  }

  // All other colors:
  if (isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Red.min, BASE_COLOR_TO_HUE_RANGE_MAP.Red.max)) {
    return 'Red';
  }
  if (
    isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Orange.min, BASE_COLOR_TO_HUE_RANGE_MAP.Orange.max)
  ) {
    return 'Orange';
  }
  if (
    isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Yellow.min, BASE_COLOR_TO_HUE_RANGE_MAP.Yellow.max)
  ) {
    return 'Yellow';
  }
  if (
    isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Green.min, BASE_COLOR_TO_HUE_RANGE_MAP.Green.max)
  ) {
    return 'Green';
  }
  if (isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Blue.min, BASE_COLOR_TO_HUE_RANGE_MAP.Blue.max)) {
    return 'Blue';
  }
  if (
    isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Purple.min, BASE_COLOR_TO_HUE_RANGE_MAP.Purple.max)
  ) {
    return 'Purple';
  }
  if (isBetween(hue, BASE_COLOR_TO_HUE_RANGE_MAP.Pink.min, BASE_COLOR_TO_HUE_RANGE_MAP.Pink.max)) {
    return 'Pink';
  }
  return 'Red';
}

// Creating a separate function here for i18n purposes (eventually)
export function getBaseColorNameLowercase(colorHex: string): string {
  return getBaseColorName(colorHex).toLowerCase();
}

export interface ExamplePictureInfo {
  alt: string;
  aspectRatio: number;
  baseColor: string;
  caption: string;
  url: string;
}

interface ColorDescriptionInfo {
  description: string;
  exampleBrands: BrandInfo[];
  examplePictures: ExamplePictureInfo[];
}

export function getEmotionDescriptionFromColorName(colorName: BaseColorName): ColorDescriptionInfo {
  switch (colorName) {
    case 'Red':
      return {
        description: 'Red can represent excitement, passion, power, energy, danger, and action.',
        exampleBrands: [
          { brandName: 'Netflix', mainColor: '#E50914' },
          { brandName: 'Coca-Cola', mainColor: '#FE001A' },
          { brandName: 'Bank of America', mainColor: '#E61030' },
          { brandName: 'Target', mainColor: '#CC0000' },
          { brandName: 'Marvel', mainColor: '#EC1D24' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the AMC website',
            aspectRatio: 1.8,
            baseColor: '#E41938',
            caption: 'AMC Theatres has a red logo and uses red throughout the UI.',
            url: './images/brand_examples/amc.png',
          },
          {
            alt: 'Screenshot of the Netflix website',
            aspectRatio: 1.8,
            baseColor: '#E50914',
            caption: 'Netflix uses red for their logo and actions.',
            url: './images/brand_examples/netflix.png',
          },
          {
            alt: 'Screenshot of the YouTube website',
            aspectRatio: 1.8,
            baseColor: '#FF0000',
            caption: 'YouTube uses red for its brand and other icons.',
            url: './images/brand_examples/youtube.png',
          },
        ],
      };
    case 'Orange':
      return {
        description: 'Orange can represent enthusiasm, creativity, freedom, youth, and balance.',
        exampleBrands: [
          { brandName: 'Amazon', mainColor: '#FF9900' },
          { brandName: 'Home Depot', mainColor: '#EE7125' },
          { brandName: 'Nickelodeon', mainColor: '#F57D0D' },
          { brandName: 'Fanta', mainColor: '#FC8102' },
          { brandName: 'Soundcloud', mainColor: '#FF8800' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the Amazon website',
            aspectRatio: 1.8,
            baseColor: '#FF9900',
            caption: 'Amazon uses orange for their logo and key actions.',
            url: './images/brand_examples/amazon.png',
          },
          {
            alt: 'Screenshot of the Home Depot website',
            aspectRatio: 1.8,
            baseColor: '#EE7125',
            caption: 'Home Depot uses orange as their sole dominant color.',
            url: './images/brand_examples/home_depot.png',
          },
        ],
      };
    case 'Yellow':
      return {
        description: 'Yellow can represent happiness, positivity, optimism, and fun.',
        exampleBrands: [
          { brandName: "McDonald's", mainColor: '#FFC72C' },
          { brandName: 'Snapchat', mainColor: '#FFFC00' },
          { brandName: 'National Geographic', mainColor: '#FFCE00' },
          { brandName: 'Best Buy', mainColor: '#FFF100' },
          { brandName: 'Post-it', mainColor: '#FFFF99' },
        ],
        examplePictures: [
          {
            alt: "Screenshot of the McDonald's website",
            aspectRatio: 1.8,
            baseColor: '#FFC72C',
            caption: "McDonald's uses yellow for their logo and key actions.",
            url: './images/brand_examples/mcdonalds.png',
          },
          {
            alt: 'Screenshot of the Snapchat website',
            aspectRatio: 1.8,
            baseColor: '#FFFC00',
            caption: 'SnapChat uses yellow throughout the UI.',
            url: './images/brand_examples/snapchat.png',
          },
        ],
      };
    case 'Green':
      return {
        description:
          'Green can represent growth, nature, health, fertility, harmony, and prosperity.',
        exampleBrands: [
          { brandName: 'Spotify', mainColor: '#1DB954' },
          { brandName: 'Whole Foods', mainColor: '#006F46' },
          { brandName: 'Starbucks', mainColor: '#00704A' },
          { brandName: 'Holiday Inn', mainColor: '#4F9E33' },
          { brandName: 'John Deere', mainColor: '#367C2A' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the Whole Foods website',
            aspectRatio: 1.8,
            baseColor: '#006F46',
            caption: 'Whole Foods uses green as their main dominant color.',
            url: './images/brand_examples/whole_foods.png',
          },
          {
            alt: 'Screenshot of the John Deere website',
            aspectRatio: 1.8,
            baseColor: '#367C2A',
            caption: 'John Deere mixes yellow with their dominant green color.',
            url: './images/brand_examples/john_deere.png',
          },
        ],
      };
    case 'Blue':
      return {
        description: 'Blue can represent trust, confidence, calmness, stability, and loyalty.',
        exampleBrands: [
          { brandName: 'Facebook', mainColor: '#1877F2' },
          { brandName: 'IBM', mainColor: '#1662FE' },
          { brandName: 'American Express', mainColor: '#016FD0' },
          { brandName: 'Chase', mainColor: '#005EB8' },
          { brandName: 'Tiffany & Co.', mainColor: '#81D8D0' },
          { brandName: 'Ford', mainColor: '#133A7C' },
          { brandName: 'Intel', mainColor: '#0071C5' },
          { brandName: 'General Electric', mainColor: '#026CB6' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the American Express website',
            aspectRatio: 1.8,
            baseColor: '#016FD0',
            caption: 'American Express uses their brand/logo color for UI actions.',
            url: './images/brand_examples/amex.png',
          },
          {
            alt: 'Screenshot of the IBM website',
            aspectRatio: 1.8,
            baseColor: '#1662FE',
            caption: 'IBM uses blue as their attention-grabbing action color.',
            url: './images/brand_examples/ibm.png',
          },
        ],
      };
    case 'Purple':
      return {
        description:
          'Purple can represent royalty or nobility, spirituality, luxury, imagination, and sophistication.',
        exampleBrands: [
          { brandName: 'Yahoo!', mainColor: '#410093' },
          { brandName: 'Twitch', mainColor: '#6441A5' },
          { brandName: 'Taco Bell', mainColor: '#682A8F' },
          { brandName: 'Roku', mainColor: '#6F1AB1' },
          { brandName: 'Hallmark', mainColor: '#452C63' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the Yahoo! website',
            aspectRatio: 1.8,
            baseColor: '#410093',
            caption: 'Yahoo! uses purple for their logo and icons throughout.',
            url: './images/brand_examples/yahoo.png',
          },
          {
            alt: 'Screenshot of the Twitch website',
            aspectRatio: 1.8,
            baseColor: '#6441A5',
            caption: 'Twitch uses purple for their logo and actions.',
            url: './images/brand_examples/twitch.png',
          },
        ],
      };
    case 'Pink':
      return {
        description: 'Pink can represent femininity, softness, calmness, creativity, and love.',
        exampleBrands: [
          { brandName: 'Barbie', mainColor: '#E0218A' },
          { brandName: 'Lyft', mainColor: '#FF00BF' },
          { brandName: 'T-Mobile', mainColor: '#E20074' },
          { brandName: 'Lemonade', mainColor: '#FF0083' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the Lyft website',
            aspectRatio: 1.8,
            baseColor: '#FF00BF',
            caption: 'Lyft uses pink for their logo and purple for actions.',
            url: './images/brand_examples/lyft.png',
          },
          {
            alt: 'Screenshot of the T-Mobile website',
            aspectRatio: 1.8,
            baseColor: '#E20074',
            caption: 'T-Mobile uses pink for their logo and actions.',
            url: './images/brand_examples/tmobile.png',
          },
        ],
      };
    case 'Black':
      return {
        description: 'Black can represent sophistication, elegance, luxury, and power.',
        exampleBrands: [
          { brandName: 'Apple', mainColor: '#000000' },
          { brandName: 'Uber', mainColor: '#09091A' },
          { brandName: 'Prada', mainColor: '#000000' },
          { brandName: 'Gucci', mainColor: '#000000' },
          { brandName: 'The New York Times', mainColor: '#000000' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the Apple website',
            aspectRatio: 1.8,
            baseColor: '#000000',
            caption: "Apple's UI is often dominated by black, white, and gray.",
            url: './images/brand_examples/apple.png',
          },
          {
            alt: 'Screenshot of the Uber website',
            aspectRatio: 1.8,
            baseColor: '#09091A',
            caption: 'Uber uses black with white actions and text.',
            url: './images/brand_examples/uber.png',
          },
        ],
      };
    case 'White':
      return {
        description:
          'White can represent purity, cleanliness, goodness, innocence, and simplicity.',
        exampleBrands: [
          { brandName: 'Nike', mainColor: '#FAF9F6' },
          { brandName: 'The North Face', mainColor: '#FFFFF0' },
          { brandName: 'Apple', mainColor: '#FFFFFF' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the North Face website',
            aspectRatio: 1.8,
            baseColor: '#FFFFF0',
            caption: 'White dominates The North Face UI with black text and actions.',
            url: './images/brand_examples/north_face.png',
          },
        ],
      };
    case 'Gray':
      return {
        description: 'Gray is very neutral and can also represent balance.',
        exampleBrands: [
          { brandName: 'Wikipedia', mainColor: '#939598' },
          { brandName: 'Mercedes-Benz', mainColor: '#C8CCCE' },
          { brandName: 'Apple', mainColor: '#A2AAAD' },
        ],
        examplePictures: [
          {
            alt: 'Screenshot of the Wikipedia website',
            aspectRatio: 1.8,
            baseColor: '#939598',
            caption: 'Wikipedia uses only neutral gray colors throughout.',
            url: './images/brand_examples/wikipedia.png',
          },
        ],
      };
  }
}

export interface HarmonyDescriptionInfo {
  description: string;
  exampleBrands: BrandInfo[];
  exampleLogos: BrandInfo[];
  examplePictures: ExamplePictureInfo[];
}

export function getHarmonyDescription(
  colorHarmonyOption: ColorHarmonyOption,
): HarmonyDescriptionInfo {
  switch (colorHarmonyOption) {
    case 'complementary':
      return {
        description:
          'A complementary harmony consists of two hues on the opposite sides of the color wheel that are visual opposites. These can clash a bit, creating visual friction and excitement.',
        exampleBrands: [
          { brandName: 'PayPal', mainColor: '#003087' },
          { brandName: 'Miami Dolphins', mainColor: '#008C95' },
        ],
        exampleLogos: [
          { brandName: 'Firefox', mainColor: '#E66000' },
          { brandName: 'Ikea', mainColor: '#FFDA1A' },
          { brandName: 'Fanta', mainColor: '#FC8102' },
        ],
        examplePictures: [
          {
            alt: '"The Starry Night" by Van Gogh',
            aspectRatio: 1.25,
            baseColor: '#18216B',
            caption: 'Blue and yellow are complementary.',
            url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/606px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg',
          },
          {
            alt: '"The Ninth Wave" by Hovhannes Aivazovsky',
            aspectRatio: 1.5,
            baseColor: '#F1B0A8',
            caption: 'The warn sunset colors complement the cold ocean.',
            url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Hovhannes_Aivazovsky_-_The_Ninth_Wave_-_Google_Art_Project.jpg/640px-Hovhannes_Aivazovsky_-_The_Ninth_Wave_-_Google_Art_Project.jpg',
          },
        ],
      };
    case 'splitComplementary':
      return {
        description:
          "A split complementary harmony consists of two secondary colors opposite of the base color. There is plenty of contrast, but it's not as jarring as the pure complementary harmony.",
        exampleBrands: [
          { brandName: 'Taco Bell', mainColor: '#682A8F' },
          { brandName: 'Pepsi', mainColor: '#1B52A2' },
          { brandName: 'Tide', mainColor: '#0068AC' },
          { brandName: 'HubSpot', mainColor: '#F57722' },
          { brandName: 'Delta Air Lines', mainColor: '#003268' },
        ],
        exampleLogos: [
          { brandName: 'Visa', mainColor: '#15195A' },
          { brandName: 'Burger King (old logo)', mainColor: '#01538A' },
        ],
        examplePictures: [
          {
            alt: 'Autumn colors',
            aspectRatio: 1.5,
            baseColor: '#5DDEFC',
            caption: 'Orange and red are split-complementary to the cyan bag.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/red-orange-blue-split-complementary-flatlay-autumn-fall-1536x1024.jpg.webp',
          },
          {
            alt: 'Blue, orange, and yellow boat on the ocean',
            aspectRatio: 1.25,
            baseColor: '#EF6B16',
            caption: 'Orange/blue with some green and yellow.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/blue-orange-yellow-boat-ocean-painting-art-1536x1222.jpg.webp',
          },
          {
            alt: 'Yellow and purple ice cream on a blue plate',
            aspectRatio: 1.5,
            baseColor: '#E2DF40',
            caption: 'Yellow is split-complementary to purple and blue.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/purple-blue-yellow-ice-cream-1536x1024.jpg.webp',
          },
        ],
      };
    case 'triadic':
      return {
        description:
          "A triadic harmony uses three equidistant colors, like a wider split complementary. This is a vibrant harmony and works best when there's a single dominant color.",
        exampleBrands: [
          { brandName: 'AppSchemer', mainColor: DEFAULT_UI_MAIN_COLOR_HEX },
          { brandName: 'FedEx', mainColor: '#660099' },
          { brandName: 'Buzzfeed', mainColor: '#EE3322' },
          { brandName: '7-Eleven', mainColor: '#F4811F' },
        ],
        exampleLogos: [{ brandName: 'Los Angeles Lakers', mainColor: '#552583' }],
        examplePictures: [
          {
            alt: '"Shot Marilyn" by Andy Warhol',
            aspectRatio: 1,
            baseColor: '#D1B937',
            caption: 'Yellow, cyan, and pink are triadic colors.',
            url: 'https://revolverwarholgallery.com/wp-content/uploads/2022/04/shotmarilynstock-1024x1024.jpg.webp',
          },
          {
            alt: 'Red, green, and blue powders in bowls',
            aspectRatio: 1.5,
            baseColor: '#088515',
            caption: 'Red, green, and blue are triadic colors.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/red-green-blue-powder-primary-colors-triadic-1536x1024.jpg.webp',
          },
          {
            alt: 'A grassy and rocky plain with a small pond and mountains in the background',
            aspectRatio: 1.55,
            baseColor: '#505E78',
            caption: 'Blue and green form a triadic harmony.',
            url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Alpine_Landscape_at_Sunset_%28SM_1559%29.png/320px-Alpine_Landscape_at_Sunset_%28SM_1559%29.png',
          },
        ],
      };
    case 'square':
      return {
        description:
          'A square harmony uses four colors that form a square on the color wheel, like two sets of complementary colors together. It works best if one color is dominant.',
        exampleBrands: [
          { brandName: 'MTV', mainColor: '#F8F906' },
          { brandName: 'Lego', mainColor: '#E3000B' },
        ],
        exampleLogos: [
          { brandName: 'NBC', mainColor: '#FCB711' },
          { brandName: 'Olympics', mainColor: '#0085C7' },
        ],
        examplePictures: [
          {
            alt: 'A couch with red, cyan, green, and purple pillows next a modern vase',
            aspectRatio: 1.35,
            baseColor: '#E90001',
            caption: 'The pillow colors are in a square harmony.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/purple-green-blue-red-pillows-gray-couch-1536x1167.jpg.webp',
          },
          {
            alt: 'An orange butterfly sitting on purple and green flowers',
            aspectRatio: 0.65,
            baseColor: '#FB6C2A',
            caption: 'The orange dominates.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/orange-monarch-butterfly-purple-flowers.jpg.webp',
          },
          {
            alt: 'Orange, purple, and green tulips in a field',
            aspectRatio: 1.35,
            baseColor: '#8E2889',
            caption: 'Violet, green, and orange form a square harmony.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/orange-purple-green-tulips-triadic-colors-1536x1152.jpg.webp',
          },
        ],
      };
    case 'tetradic':
      return {
        description:
          'A tetradic (or double split complementary) harmony uses four colors that form a rectangle on the color wheel, like two sets of split complementary colors. It works best if one color is dominant.',
        exampleBrands: [
          { brandName: 'Google', mainColor: '#3E83F3' },
          { brandName: 'Slack', mainColor: '#E01D5A' },
        ],
        exampleLogos: [
          { brandName: 'Microsoft', mainColor: '#00A4F0' },
          { brandName: 'Ebay', mainColor: '#1062C9' },
        ],
        examplePictures: [
          {
            alt: 'Man in a Superman costume walking down a street',
            aspectRatio: 0.6,
            baseColor: '#0055A1',
            caption: 'Blue, red, and yellow.',
            url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Jonathan_Carroll_as_Superman.jpg/144px-Jonathan_Carroll_as_Superman.jpg',
          },
          {
            alt: 'Four paper planes (green, red, cyan, and orange) against a cyan background',
            aspectRatio: 1,
            baseColor: '#F95A58',
            caption: 'Green, red, cyan, and orange are tetradic.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/colorful-paper-airplanes-blue-background-tetradic-colors-1536x1519.jpg.webp',
          },
          {
            alt: 'A bunch of colorful papers bunched in a circle to form a flower-like shape',
            aspectRatio: 0.75,
            baseColor: '#FD9660',
            caption: 'Dynamic set of tetradic colors.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/colorful-sheets-paper-flower-orange-background-tetradic-colors.jpg.webp',
          },
        ],
      };
    case 'analogous':
      return {
        description:
          'An analogous harmony consists of the base color and the colors right next to it. These colors match really well, and yield more serene designs.',
        exampleBrands: [
          { brandName: "McDonald's", mainColor: '#FFC72C' },
          { brandName: 'Instagram', mainColor: '#D62976' },
          { brandName: 'Lyft', mainColor: '#FF00BF' },
          { brandName: 'Tostitos', mainColor: '#F4B819' },
          { brandName: 'Shell', mainColor: '#FBCE07' },
        ],
        exampleLogos: [{ brandName: 'MasterCard', mainColor: '#EB001B' }],
        examplePictures: [
          {
            alt: 'Red, orange, and yellow fall leaves',
            aspectRatio: 1.5,
            baseColor: '#F1A635',
            caption: 'Natural scenes often create a sense of balance.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/red-orange-yellow-fall-leaves-1536x1025.jpg.webp',
          },
          {
            alt: 'A living room decorated red and purple',
            aspectRatio: 1.5,
            baseColor: '#B14D58',
            caption: 'A blend of warm analogous hues.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/purple-red-living-room-1536x1024.jpg.webp',
          },
        ],
      };
    case 'monochromatic':
      return {
        description:
          'A monochromatic harmony is a set of similar variations of the base color. The resulting palette can often be more diverse than expected.',
        exampleBrands: [
          { brandName: 'American Express', mainColor: '#016FD0' },
          { brandName: 'Starbucks', mainColor: '#00704A' },
          { brandName: 'Animal Planet', mainColor: '#0074FF' },
        ],
        exampleLogos: [
          { brandName: 'PayPal', mainColor: '#253B80' },
          { brandName: 'Chase', mainColor: '#005EB8' },
        ],
        examplePictures: [
          {
            alt: 'A red monochrome living room',
            aspectRatio: 1.5,
            baseColor: '#CE202D',
            caption: 'All shades of red.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/monochrome-red-living-room-1536x1024.jpg.webp',
          },
          {
            alt: 'A pink monochrome room with a chair and lamp',
            aspectRatio: 1.5,
            baseColor: '#F0BABC',
            caption: 'All shades of pink.',
            url: 'https://www.colorsexplained.com/wp-content/uploads/2021/08/monochrome-pink-living-room-1536x1024.jpg.webp',
          },
        ],
      };
  }
}
