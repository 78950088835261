import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import * as MUIIcon from '@mui/icons-material';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserPaletteContext } from '../utils/userPaletteContext';
import { AppTheme, isDarkModeEnabled } from '../utils/settings';
import { BETA_FEEDBACK_FORM_URL } from '../ui/uiUrls';
import { AppUIContext, SCREENS } from '../utils/appUIContext';

const ACTION_BUTTON_SIZE_PX = 35;

export type SettingsPopoverActionButtonType =
  | 'RANDOMIZE'
  | 'VIEW_FULL_PALETTE'
  | 'DOWNLOAD_AND_SHARE'
  | 'INFO_AND_HELP';

interface Props {
  isBackgroundDark: boolean;
  isDarkMode: boolean;
  isMobileView: boolean;
  onActionButtonClicked?: (actionButtonType: SettingsPopoverActionButtonType) => void;
}

export default function SettingsButtonAndPopover(props: Props) {
  const { isBackgroundDark, isDarkMode, isMobileView, onActionButtonClicked } = props;
  const { currentScreen, settings, onGoToScreen, onSettingsChanged } = useContext(AppUIContext);
  const { colorSchemeState: userColorSchemeState, onColorSchemeStateChanged } =
    useContext(UserPaletteContext);

  const [showSettings, setShowSettings] = useState(false);
  const settingsButtonRef = useRef<Element | null>(null);

  const paletteScreenOnlySettings = useMemo(() => {
    if (currentScreen !== 'Palette') {
      return null;
    }
    return (
      <>
        {isMobileView && (
          <>
            <Typography fontWeight="bold" marginBottom={1} marginTop={3} variant="subtitle2">
              Preview app settings
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  aria-label="Scales down the UI of the preview apps to fit better on mobile screens when on a mobile device"
                  checked={settings.scaleDownPreviewsOnMobile}
                  onChange={(_e, checked) =>
                    onSettingsChanged({ scaleDownPreviewsOnMobile: checked })
                  }
                />
              }
              label="Scale down example app previews to fit your screen"
            />
          </>
        )}
        <Typography fontWeight="bold" marginBottom={1} marginTop={3} variant="subtitle2">
          Randomize palette{' '}
          <CasinoOutlinedIcon color="primary" style={{ fontSize: 20, marginBottom: -5 }} /> settings
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              aria-label="Randomize the color harmony options when randomizing the color palette"
              checked={settings.randomizeHarmonyColors}
              onChange={(_e, checked) => onSettingsChanged({ randomizeHarmonyColors: checked })}
            />
          }
          label="Randomize main and harmony colors"
        />
        <FormControlLabel
          control={
            <Checkbox
              aria-label="Randomize the theme options when randomizing the color palette"
              checked={settings.randomizeOptions}
              onChange={(_e, checked) => onSettingsChanged({ randomizeOptions: checked })}
            />
          }
          label="Randomize theme options"
        />
        <FormControlLabel
          control={
            <Checkbox
              aria-label="Show a confirmation warning when the randomize button is clicked"
              checked={settings.randomizeWarning}
              onChange={(_e, checked) => onSettingsChanged({ randomizeWarning: checked })}
            />
          }
          label="Show warning before randomizing"
        />
      </>
    );
  }, [currentScreen, isMobileView, onSettingsChanged, settings]);

  const handleDarkModeToggle = useCallback(
    (isChecked: boolean) => {
      if (settings.theme === 'USER') {
        onColorSchemeStateChanged({
          options: { ...userColorSchemeState.options, darkMode: isChecked },
        });
      } else {
        onSettingsChanged({ darkMode: isChecked });
      }
    },
    [onColorSchemeStateChanged, onSettingsChanged, settings.theme, userColorSchemeState.options],
  );

  if (currentScreen === 'Home') {
    return null;
  }

  const ColorScreenIcon = MUIIcon[SCREENS['Color'].icon];
  const HarmonyScreenIcon = MUIIcon[SCREENS['Harmony'].icon];

  let settingsButtonColorVariant: string;
  if (isDarkMode) {
    settingsButtonColorVariant = isBackgroundDark ? 'primary.main' : 'primary.dark';
  } else {
    settingsButtonColorVariant = isBackgroundDark ? 'primary.light' : 'primary.main';
  }

  return (
    <>
      <Popover
        anchorEl={settingsButtonRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={showSettings}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setShowSettings(false)}
      >
        <Box alignItems="left" display="flex" flexDirection="column" padding={3}>
          {isMobileView && currentScreen === 'Palette' && onActionButtonClicked && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginBottom={2}
              overflow="scroll"
            >
              <IconButton
                aria-label="Reset and randomize the entire color palette"
                color="primary"
                onClick={() => onActionButtonClicked('RANDOMIZE')}
              >
                <CasinoOutlinedIcon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
              </IconButton>
              <IconButton
                aria-label="Open the full palette dialog"
                color="primary"
                onClick={() => onActionButtonClicked('VIEW_FULL_PALETTE')}
              >
                <PaletteOutlinedIcon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
              </IconButton>
              <IconButton
                aria-label="Open menu to share or download your color palette"
                color="primary"
                onClick={() => onActionButtonClicked('DOWNLOAD_AND_SHARE')}
              >
                <CloudDownloadOutlinedIcon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
              </IconButton>
              <IconButton
                aria-label="Open help / info menu to view instructions"
                color="primary"
                onClick={() => onActionButtonClicked('INFO_AND_HELP')}
              >
                <InfoOutlinedIcon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
              </IconButton>
              <IconButton
                aria-label={`Go to ${SCREENS['Color'].title}`}
                color="secondary"
                onClick={() => onGoToScreen('Color')}
              >
                <ColorScreenIcon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
              </IconButton>
              <IconButton
                aria-label={`Go to ${SCREENS['Harmony'].title}`}
                color="secondary"
                onClick={() => onGoToScreen('Harmony')}
              >
                <HarmonyScreenIcon style={{ fontSize: ACTION_BUTTON_SIZE_PX }} />
              </IconButton>
            </Box>
          )}
          <Typography variant="h6">AppSchemer settings</Typography>
          <Typography fontWeight="bold" marginBottom={2} marginTop={2} variant="subtitle2">
            UI settings
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel>UI theme</InputLabel>
            <Select
              aria-label="Select the visual color theme for AppSchemer"
              fullWidth
              label="UI theme"
              value={settings.theme}
              onChange={(e) => onSettingsChanged({ theme: e.target.value as AppTheme })}
            >
              <MenuItem aria-label="Use default color scheme for AppSchemer" value="DEFAULT">
                Default (AppSchemer)
              </MenuItem>
              <MenuItem
                aria-label="Preview your personalized theme on AppSchemer in real time"
                value="USER"
              >
                Your theme
              </MenuItem>
              <MenuItem aria-label="Don't use any theme and use the default MUI colors" value="MUI">
                Standard MUI theme
              </MenuItem>
            </Select>
          </FormControl>
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  aria-label="Turn on dark mode for AppSchemer"
                  checked={isDarkModeEnabled(settings, userColorSchemeState.options)}
                  onChange={(_e, checked) => handleDarkModeToggle(checked)}
                />
              }
              label="Dark mode"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                aria-label="Show an alert warning if you're about to leave or reload the page"
                checked={settings.warnBeforeLeavingApp}
                onChange={(_e, checked) => onSettingsChanged({ warnBeforeLeavingApp: checked })}
              />
            }
            label="Warn (alert) before leaving app"
          />
          {paletteScreenOnlySettings}
          <Typography fontWeight="bold" marginBottom={1} marginTop={3} variant="subtitle2">
            Share feedback
          </Typography>
          <Link
            aria-label="Open a Google Forms link to share feedback about AppSchemer"
            href={BETA_FEEDBACK_FORM_URL}
            target="_blank"
          >
            <Typography color="inherit" textAlign="center" variant="body2">
              Share feedback (via Google Forms)
            </Typography>
          </Link>
        </Box>
      </Popover>
      <Box color={settingsButtonColorVariant} ref={settingsButtonRef}>
        {isMobileView ? (
          <IconButton
            aria-label="Settings and feedback options menu"
            color="inherit"
            onClick={() => setShowSettings(true)}
          >
            <MoreVertIcon color="inherit" fontSize="large" />
          </IconButton>
        ) : (
          <Button
            aria-label="Open AppSchemer settings menu"
            color="inherit"
            variant="text"
            onClick={() => setShowSettings(true)}
          >
            Settings
          </Button>
        )}
      </Box>
    </>
  );
}
