import tinycolor from 'tinycolor2';
import { getCappedValue, getRandomValueInRange, removeIndicesFromArray } from '../utils';
import {
  AllColorHarmonyOptions,
  ColorHarmonyOption,
  ColorSchemeState,
  OPTION_NOT_SELECTED,
} from './colorSchemeState';
import { ColorPalette, ColorVariationShade, ColorWithVariations } from './colorPalette';
import {
  BaseColorName,
  getBaseColorName,
  getHueRangeForBaseColorName,
  getLightnessRangeForBaseColorName,
  getSaturationRangeForBaseColorName,
} from './colorInfo';

export const MAX_HUE_VALUE = 360;

const BASE_SEMANTIC_COLORS = {
  info: '#0000AA',
  negative: '#AA0000',
  positive: '#00AA00',
  special: '#AA00AA',
  warning: '#FFAA00',
} as const;

export function isColorValid(color: string): boolean {
  return tinycolor(color).getFormat() === 'hex';
}

export function isColorDark(color: string, secondColorToAverageWith?: string): boolean {
  const { h: hue, l: firstLightness } = tinycolor(color).toHsl();
  let lightness = firstLightness;
  if (secondColorToAverageWith) {
    const { l: secondLightness } = tinycolor(secondColorToAverageWith).toHsl();
    lightness = (firstLightness + secondLightness) / 2;
  }
  let maxLightness = 0.5;
  if (hue >= 215 && hue <= 280) {
    // blueish hues tend to feel a bit darker
    maxLightness = 0.65;
  } else if (hue >= 40 && hue <= 190) {
    // yellowish hues tend to feel a bit brighter
    maxLightness = 0.4;
  }
  if (lightness <= maxLightness) {
    return true;
  }
  return false;
}

const SUITABLE_COLOR_MIN_LIGHTNESS = 0.25;
const SUITABLE_COLOR_MAX_LIGHTNESS = 0.75;
const SUITABLE_COLOR_MIN_SATURATION = 0.4;
export function isColorSuitableAsBaseColor(color: string): boolean {
  const { l: lightness, s: saturation } = tinycolor(color).toHsl();
  return (
    lightness <= SUITABLE_COLOR_MAX_LIGHTNESS &&
    lightness >= SUITABLE_COLOR_MIN_LIGHTNESS &&
    saturation >= SUITABLE_COLOR_MIN_SATURATION
  );
}

interface RandomizeColorOptions {
  hueBaseColor?: BaseColorName;
  suitableBaseColorsOnly?: boolean;
}

export function getRandomHexColor(options?: RandomizeColorOptions): string {
  const { hueBaseColor, suitableBaseColorsOnly } = options ?? {};

  const hueRange = getHueRangeForBaseColorName(hueBaseColor);
  let hue = getRandomValueInRange(hueRange.min, hueRange.max);
  if (hue < 0) {
    hue += MAX_HUE_VALUE;
  } else if (hue > MAX_HUE_VALUE) {
    hue -= MAX_HUE_VALUE;
  }

  // TODO: this doesn't account for the extra lightness delta for black and white colors
  const saturationRange = getSaturationRangeForBaseColorName(hueBaseColor);
  const saturation = suitableBaseColorsOnly
    ? // TODO: handle edge case where saturationRange.max < SUITABLE_COLOR_MIN_SATURATION
      getRandomValueInRange(SUITABLE_COLOR_MIN_SATURATION, saturationRange.max)
    : getRandomValueInRange(saturationRange.min, saturationRange.max);

  const lightnessRange = getLightnessRangeForBaseColorName(hueBaseColor);
  // TODO: if not black/white/gray, guard against those values:
  const lightness = suitableBaseColorsOnly
    ? getRandomValueInRange(SUITABLE_COLOR_MIN_LIGHTNESS, SUITABLE_COLOR_MAX_LIGHTNESS)
    : getRandomValueInRange(lightnessRange.min, lightnessRange.max);

  // TODO: this is a ~~~REALLY GNARLY~~~ hack to "fix" the lightness bug w/ black and white colors:
  const result = tinycolor({
    h: hue,
    s: saturation,
    l: lightness,
  }).toHexString();
  if (hueBaseColor && getBaseColorName(result) !== hueBaseColor) {
    return getRandomHexColor(options);
  }
  return result;
}

interface HSL {
  hue: number;
  saturation: number;
  lightness: number;
}

export function getHSLFromColorHex(colorHex: string): HSL {
  const { h: hue, s: saturation, l: lightness } = tinycolor(colorHex).toHsl();
  return {
    hue,
    saturation,
    lightness,
  };
}

interface HSV {
  hue: number;
  saturation: number;
  value: number;
}

export function getHSVFromColorHex(colorHex: string): HSV {
  const { h: hue, s: saturation, v: value } = tinycolor(colorHex).toHsv();
  return {
    hue,
    saturation,
    value,
  };
}

interface RGB {
  red: number;
  green: number;
  blue: number;
}

export function getRGBFromColorHex(colorHex: string): RGB {
  const { r: red, g: green, b: blue } = tinycolor(colorHex).toRgb();
  return {
    red,
    green,
    blue,
  };
}

export function adjustColorHSLValues(colorHex: string, newHSLValues: Partial<HSL>): string {
  const {
    h: originalHue,
    s: originalSaturation,
    l: originalLightness,
  } = tinycolor(colorHex).toHsl();
  return tinycolor({
    h: newHSLValues.hue ?? originalHue,
    s: newHSLValues.saturation ?? originalSaturation,
    l: newHSLValues.lightness ?? originalLightness,
  }).toHexString();
}

export function getColorBrightnessLevel(
  color: tinycolor.Instance | string,
): 'LIGHT' | 'DARK' | 'LIGHTER' | 'DARKER' | 'NORMAL' {
  const { l: lightness } = tinycolor(color).toHsl();
  if (lightness >= 0.99) {
    return 'LIGHT';
  }
  if (lightness >= 0.85) {
    return 'LIGHTER';
  }
  if (lightness <= 0.1) {
    return 'DARK';
  }
  if (lightness < 0.2) {
    return 'DARKER';
  }
  return 'NORMAL';
}

export function isPaletteTooLightOrDark(colorPalette: ColorPalette): 'LIGHT' | 'DARK' | 'OK' {
  const mainVariation = colorPalette.variationValues.mainColor.main;
  const lighterVariation = colorPalette.variationValues.mainColor.lighter;
  const lightColorsToCheck = [
    colorPalette.referencePalette.main[mainVariation], // check the main color at its main variation too
    ...colorPalette.referencePalette.harmony.map(
      (secondaryColorWithVariations) => secondaryColorWithVariations[lighterVariation],
    ),
  ];
  if (lightColorsToCheck.find((color) => getColorBrightnessLevel(color) === 'LIGHT')) {
    return 'LIGHT';
  }

  const darkerVariation = colorPalette.variationValues.mainColor.darker;
  const darkColorsToCheck = [
    colorPalette.referencePalette.main[mainVariation], // check the main color at its main variation too
    ...colorPalette.referencePalette.harmony.map(
      (secondaryColorWithVariations) => secondaryColorWithVariations[darkerVariation],
    ),
  ];
  if (darkColorsToCheck.find((color) => getColorBrightnessLevel(color) === 'DARK')) {
    return 'DARK';
  }
  return 'OK';
}

export function getColorWithVariations(
  baseColor: tinycolor.Instance | string,
): ColorWithVariations {
  const baseColorObject = tinycolor(baseColor);
  const { h: baseH, s: baseS, l: baseL } = baseColorObject.toHsl();
  return {
    100: tinycolor({
      h: baseH,
      s: getCappedValue(baseS + 0.2, 0, 1),
      l: getCappedValue(baseL + 0.4, 0, 1),
    }).toHexString(),
    200: tinycolor({
      h: baseH,
      s: getCappedValue(baseS + 0.15, 0, 1),
      l: getCappedValue(baseL + 0.3, 0, 1),
    }).toHexString(),
    300: tinycolor({
      h: baseH,
      s: getCappedValue(baseS + 0.1, 0, 1),
      l: getCappedValue(baseL + 0.2, 0, 1),
    }).toHexString(),
    400: tinycolor({
      h: baseH,
      s: getCappedValue(baseS + 0.05, 0, 1),
      l: getCappedValue(baseL + 0.1, 0, 1),
    }).toHexString(),
    500: baseColorObject.toHexString(),
    600: tinycolor({
      h: baseH,
      s: getCappedValue(baseS - 0.05, 0, 1),
      l: getCappedValue(baseL - 0.1, 0, 1),
    }).toHexString(),
    700: tinycolor({
      h: baseH,
      s: getCappedValue(baseS - 0.1, 0, 1),
      l: getCappedValue(baseL - 0.2, 0, 1),
    }).toHexString(),
    800: tinycolor({
      h: baseH,
      s: getCappedValue(baseS - 0.15, 0, 1),
      l: getCappedValue(baseL - 0.3, 0, 1),
    }).toHexString(),
    900: tinycolor({
      h: baseH,
      s: getCappedValue(baseS - 0.2, 0, 1),
      l: getCappedValue(baseL - 0.4, 0, 1),
    }).toHexString(),
  };
}

export function getSecondaryColorOrNextBestOption(colorPalette: ColorPalette): ColorWithVariations {
  if (colorPalette.secondary) {
    return colorPalette.secondary;
  } else if (colorPalette.otherHarmonyColors.length > 0) {
    return colorPalette.otherHarmonyColors[0];
  } else {
    return colorPalette.accent;
  }
}

export function getTertiaryColorOrNextBestOption(colorPalette: ColorPalette): ColorWithVariations {
  if (colorPalette.otherHarmonyColors.length > 0) {
    return colorPalette.otherHarmonyColors[0];
  } else if (colorPalette.secondary) {
    return colorPalette.secondary;
  } else {
    return colorPalette.accent;
  }
}

function getNeutralShadesForHue(color?: tinycolor.Instance | string): ColorWithVariations {
  if (!color) {
    return {
      100: tinycolor({ h: 0, s: 0, l: 0.99 }).toHexString(),
      200: tinycolor({ h: 0, s: 0, l: 0.92 }).toHexString(),
      300: tinycolor({ h: 0, s: 0, l: 0.8 }).toHexString(),
      400: tinycolor({ h: 0, s: 0, l: 0.65 }).toHexString(),
      500: tinycolor({ h: 0, s: 0, l: 0.5 }).toHexString(),
      600: tinycolor({ h: 0, s: 0, l: 0.4 }).toHexString(),
      700: tinycolor({ h: 0, s: 0, l: 0.3 }).toHexString(),
      800: tinycolor({ h: 0, s: 0, l: 0.2 }).toHexString(),
      900: tinycolor({ h: 0, s: 0, l: 0.1 }).toHexString(),
    };
  }
  const { h: baseHue } = tinycolor(color).toHsl();
  return {
    100: tinycolor({ h: baseHue, s: 0.3, l: 0.99 }).toHexString(),
    200: tinycolor({ h: baseHue, s: 0.15, l: 0.92 }).toHexString(),
    300: tinycolor({ h: baseHue, s: 0.05, l: 0.8 }).toHexString(),
    400: tinycolor({ h: baseHue, s: 0.025, l: 0.65 }).toHexString(),
    500: tinycolor({ h: baseHue, s: 0.025, l: 0.5 }).toHexString(),
    600: tinycolor({ h: baseHue, s: 0.05, l: 0.4 }).toHexString(),
    700: tinycolor({ h: baseHue, s: 0.1, l: 0.3 }).toHexString(),
    800: tinycolor({ h: baseHue, s: 0.15, l: 0.2 }).toHexString(),
    900: tinycolor({ h: baseHue, s: 0.2, l: 0.1 }).toHexString(),
  };
}

function addHueValues(hue1: number, hue2: number): number {
  const sum = (hue1 + hue2) % MAX_HUE_VALUE;
  return sum > 0 ? sum : sum + MAX_HUE_VALUE;
}

function addHueToColor(color: tinycolor.Instance | string, hueOffset: number): string {
  const { h, s, l } = tinycolor(color).toHsl();
  return tinycolor({ h: addHueValues(h, hueOffset), s, l }).toHexString();
}

const ANALOGOUS_COLORS_HUE_DELTA = Math.round(MAX_HUE_VALUE / 15);
const SPLIT_COMPLEMENTARY_COLORS_HUE_DELTA = Math.round(MAX_HUE_VALUE / 2.5);
export function getColorHarmonyOptions(baseColorHex: string): AllColorHarmonyOptions {
  const baseColor = tinycolor(baseColorHex);

  // TODO: these don't work with too-white or too-black colors, handle that for harmonies!
  // -- maybe make the only available option in that case 'mono'

  const triadColors = baseColor.triad();
  const triad1 = triadColors[1];
  const triad2 = triadColors[2];

  const complementary = baseColor.complement();

  // Analogous is broken in tinycolor's implementation, so it manually:
  const splitComplementary1 = addHueToColor(baseColor, SPLIT_COMPLEMENTARY_COLORS_HUE_DELTA);
  const splitComplementary2 = addHueToColor(baseColor, -SPLIT_COMPLEMENTARY_COLORS_HUE_DELTA);

  // Tetradic harmony is not available in tinycolor:
  const tetradic1 = addHueToColor(baseColor, 30);
  const tetradic2 = addHueToColor(baseColor, 30 + 120);
  const tetradic3 = addHueToColor(baseColor, 30 + 120 + 60);
  const tetradic4 = addHueToColor(baseColor, 30 + 120 + 60 + 120);

  const squareColors = baseColor.tetrad();
  const square1 = squareColors[1];
  const square2 = squareColors[2];
  const square3 = squareColors[3];

  // Analogous is broken in tinycolor's implementation, so it manually:
  const analogous1 = addHueToColor(baseColor, 2 * ANALOGOUS_COLORS_HUE_DELTA);
  const analogous2 = addHueToColor(baseColor, 1 * ANALOGOUS_COLORS_HUE_DELTA);
  const analogous3 = addHueToColor(baseColor, -1 * ANALOGOUS_COLORS_HUE_DELTA);
  const analogous4 = addHueToColor(baseColor, -2 * ANALOGOUS_COLORS_HUE_DELTA);

  const monochromaticColors = baseColor.monochromatic();
  const monochromaticColorsHarmonyOnly = monochromaticColors
    .slice(1)
    .sort((color1, color2) => (color1.getBrightness() > color2.getBrightness() ? -1 : 1));
  const monochromatic1 = monochromaticColorsHarmonyOnly[0];
  const monochromatic2 = monochromaticColorsHarmonyOnly[1];
  const monochromatic3 = monochromaticColorsHarmonyOnly[2];
  const monochromatic4 = monochromaticColorsHarmonyOnly[3];
  const monochromatic5 = monochromaticColorsHarmonyOnly[4];

  return {
    complementary: [getColorWithVariations(baseColorHex), getColorWithVariations(complementary)],
    splitComplementary: [baseColorHex, splitComplementary1, splitComplementary2].map(
      getColorWithVariations,
    ),
    triadic: [baseColorHex, triad1, triad2].map(getColorWithVariations),
    tetradic: [baseColorHex, tetradic1, tetradic2, tetradic3, tetradic4].map(
      getColorWithVariations,
    ),
    square: [baseColorHex, square1, square2, square3].map(getColorWithVariations),
    analogous: [baseColorHex, analogous1, analogous2, analogous3, analogous4].map(
      getColorWithVariations,
    ),
    monochromatic: [
      baseColorHex,
      monochromatic1,
      monochromatic2,
      monochromatic3,
      monochromatic4,
      monochromatic5,
    ].map(getColorWithVariations),
  };
}

export function getSelectedColorHarmonyColors(
  mainColorHex: string,
  colorHarmonyOption?: ColorHarmonyOption,
): ColorWithVariations[] {
  const colorHarmonyOptions = getColorHarmonyOptions(mainColorHex);
  return colorHarmonyOptions[colorHarmonyOption ?? 'complementary'];
}

function getOffsetColorShade(
  baseShade: ColorVariationShade,
  diff: ColorVariationShade,
  subtract?: boolean,
): ColorVariationShade {
  const result = subtract ? baseShade - diff : baseShade + diff;
  if (result >= 100 && result <= 900) {
    return result as ColorVariationShade;
  }
  return subtract ? 100 : 900;
}

function getMatchedValue(
  inputVal: number,
  targetVal: number,
  minDistanceFromTarget: number,
  maxDistanceFromTarget: number,
): number {
  const delta = inputVal - targetVal;
  const offset = getCappedValue(Math.abs(delta), minDistanceFromTarget, maxDistanceFromTarget);
  const signedOffset = delta >= 0 ? offset : -offset;
  const matchedValue = targetVal + signedOffset;
  if (matchedValue >= 0 && matchedValue <= 1) {
    return matchedValue;
  }
  return targetVal - signedOffset;
}

const MINIMUM_MATCHED_SATURATION_OFFSET = 0.05;
const MAXIMUM_MATCHED_SATURATION_OFFSET = 0.1;
const MINIMUM_MATCHED_BRIGHTNESS_OFFSET = 0.05;
const MAXIMUM_MATCHED_BRIGHTNESS_OFFSET = 0.1;
const MINIMUM_HUE_DIFFERENCE = 20;
function matchSaturationBrightnessAndOffsetHue(
  inputColor: tinycolor.Instance | string,
  colorToMatch: tinycolor.Instance | string,
): string {
  // TODO: do a sanity check to make sure the hue and saturation never fall below (and above?) a certain amount
  const { h: inputH, s: inputS, l: inputL } = tinycolor(inputColor).toHsl();
  const { h: targetH, s: targetS, l: targetL } = tinycolor(colorToMatch).toHsl();

  // Match saturation to within 5-10%
  const matchedSaturation = getMatchedValue(
    inputS,
    targetS,
    MINIMUM_MATCHED_SATURATION_OFFSET,
    MAXIMUM_MATCHED_SATURATION_OFFSET,
  );
  // Match brightness to within 5-10%
  const matchedBrightness = getMatchedValue(
    inputL,
    targetL,
    MINIMUM_MATCHED_BRIGHTNESS_OFFSET,
    MAXIMUM_MATCHED_BRIGHTNESS_OFFSET,
  );

  // If offsetting hue, we need to make sure the hue isn't too close to the target color, and
  // if they are too close, offset the hue a bit to prevent the colors being overly similar
  let adjustedHue = inputH;
  const hueDelta = targetH - inputH;
  if (Math.abs(hueDelta) < MINIMUM_HUE_DIFFERENCE) {
    adjustedHue = addHueValues(inputH, MINIMUM_HUE_DIFFERENCE - hueDelta);
  }

  return tinycolor({ h: adjustedHue, s: matchedSaturation, l: matchedBrightness }).toHexString();
}

export function getColorPalette(colorSchemeState: ColorSchemeState): ColorPalette {
  const { mainColorHex, colorHarmony, options } = colorSchemeState;

  // Get the main color and neutrals based off of it:
  const mainColor = getColorWithVariations(mainColorHex);

  // Get all the possible (secondary) harmony color options:
  const harmonyColors = getSelectedColorHarmonyColors(mainColorHex, colorHarmony.selectedHarmony);
  const harmonyNeutrals = harmonyColors.map((secondaryColorVariations) =>
    getNeutralShadesForHue(secondaryColorVariations[500]),
  );

  // Pick the accent color from the selected harmony:
  const accentColorIndex =
    colorHarmony.accentColorIndex < harmonyColors.length ? colorHarmony.accentColorIndex : 0;
  const accentColor = harmonyColors[accentColorIndex];

  const secondaryColorIndex =
    colorHarmony.secondaryColorIndex !== OPTION_NOT_SELECTED &&
    colorHarmony.secondaryColorIndex < harmonyColors.length
      ? colorHarmony.secondaryColorIndex
      : OPTION_NOT_SELECTED;
  const secondaryColor =
    secondaryColorIndex === OPTION_NOT_SELECTED ? undefined : harmonyColors[secondaryColorIndex];

  let mainColorVariationValues: ColorPalette['variationValues']['mainColor'];
  const primaryColorMainValue = (options.mainBrightness * 100) as ColorVariationShade;
  switch (options.mainContrast) {
    case 'normal':
      mainColorVariationValues = {
        main: primaryColorMainValue,
        lighter: getOffsetColorShade(primaryColorMainValue, 200, /* subtract = */ true),
        darker: getOffsetColorShade(primaryColorMainValue, 200),
      };
      break;
    case 'high':
      mainColorVariationValues = {
        main: primaryColorMainValue,
        lighter: getOffsetColorShade(primaryColorMainValue, 300, /* subtract = */ true),
        darker: getOffsetColorShade(primaryColorMainValue, 300),
      };
      break;
    case 'low':
      mainColorVariationValues = {
        main: primaryColorMainValue,
        lighter: getOffsetColorShade(primaryColorMainValue, 100, /* subtract = */ true),
        darker: getOffsetColorShade(primaryColorMainValue, 100),
      };
      break;
  }

  let neutralsVariationValues: ColorPalette['variationValues']['neutrals'];
  switch (options.neutralContrast) {
    case 'high':
      neutralsVariationValues = {
        light1: 100,
        light2: 200,
        lighter: 300,
        main: 500,
        darker: 700,
        dark2: 800,
        dark1: 900,
      };
      break;
    case 'low':
    default:
      neutralsVariationValues = {
        light1: 100,
        light2: 200,
        lighter: 400,
        main: 500,
        darker: 600,
        dark2: 800,
        dark1: 900,
      };
      break;
  }

  const semanticColorMainValue = (options.semanticBrightness * 100) as ColorVariationShade;
  const semanticVariationValues: ColorPalette['variationValues']['semantic'] = {
    main: semanticColorMainValue,
    lighter: getOffsetColorShade(semanticColorMainValue, 200, /* subtract = */ true),
    darker: getOffsetColorShade(semanticColorMainValue, 200),
  };

  const backgroundNeutralsBaseColorIndex =
    colorHarmony.neutralColorIndex !== OPTION_NOT_SELECTED &&
    colorHarmony.neutralColorIndex < harmonyNeutrals.length
      ? colorHarmony.neutralColorIndex
      : OPTION_NOT_SELECTED;
  const backgroundNeutrals =
    backgroundNeutralsBaseColorIndex === OPTION_NOT_SELECTED
      ? getNeutralShadesForHue() // pure neutrals with no color at all
      : harmonyNeutrals[backgroundNeutralsBaseColorIndex];
  const pureNeutrals = getNeutralShadesForHue(); // pure neutrals with no color at all
  const mainNeutrals = getNeutralShadesForHue(mainColorHex);

  return {
    accent: accentColor,
    secondary: secondaryColor,
    otherHarmonyColors: removeIndicesFromArray(harmonyColors, [
      accentColorIndex,
      secondaryColorIndex,
    ]),
    text: options.tintTextNeutrals ? mainNeutrals : pureNeutrals,
    background: backgroundNeutrals,
    disabled: pureNeutrals,
    divider: options.tintTextNeutrals ? mainNeutrals : pureNeutrals,
    icon: secondaryColor ?? accentColor,
    semantic: {
      info: getColorWithVariations(
        matchSaturationBrightnessAndOffsetHue(BASE_SEMANTIC_COLORS.info, mainColorHex),
      ),
      negative: getColorWithVariations(
        matchSaturationBrightnessAndOffsetHue(BASE_SEMANTIC_COLORS.negative, mainColorHex),
      ),
      positive: getColorWithVariations(
        matchSaturationBrightnessAndOffsetHue(BASE_SEMANTIC_COLORS.positive, mainColorHex),
      ),
      special: getColorWithVariations(
        matchSaturationBrightnessAndOffsetHue(BASE_SEMANTIC_COLORS.special, mainColorHex),
      ),
      warning: getColorWithVariations(
        matchSaturationBrightnessAndOffsetHue(BASE_SEMANTIC_COLORS.warning, mainColorHex),
      ),
    },
    black: mainNeutrals[900], // Can also try this if too "bright": `tinycolor(mainNeutrals[900]).darken().toHexString()`
    white: tinycolor(mainNeutrals[100]).brighten().toHexString(),
    referencePalette: {
      main: mainColor,
      harmony: harmonyColors,
      neutrals: harmonyNeutrals,
    },
    variationValues: {
      mainColor: mainColorVariationValues,
      neutrals: neutralsVariationValues,
      semantic: semanticVariationValues,
    },
  };
}
